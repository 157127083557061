import axios from 'axios';
import { prepareResponseData } from './../helpers/prepareResponseData';
import { setSession } from '../../services/jwtService';
import { FILE_UPLOADS_URL, VIMEO_UPLOADS_URL } from './../file-upload';

export const registerResponseInterceptor = () => {
  axios.interceptors.response.use(
    function (response) {
      // exeptions - presign url to AwsS3 upload
      if (
        response.config.url === FILE_UPLOADS_URL ||
        response.config.url === VIMEO_UPLOADS_URL
      ) {
        return response;
      }
      return { ...response, data: prepareResponseData(response.data) };
    },

    (err) => {
      return new Promise((resolve, reject) => {
        if (err.response && err.config && !err.config.__isRetryRequest) {
          if (err.response.status === 401) {
            //if you ever get an unauthorized response, logout the user
            setSession(null);
          } else if (
            err.response.status === 403 &&
            err.response.data.errors === 'Account closed'
          ) {
            //if you ever get an forbidden response (account closed), logout the user & redirect
            setSession(null);
            window.location.href = `/app/unauthorized`;
          }
        }

        throw err;
      });
    }
  );
};
