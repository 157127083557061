import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { AppState } from 'app/store';

interface HasToPassOnboardingProps {
  children: React.ReactNode;
}

export const HasToPassOnboarding = (props: HasToPassOnboardingProps) => {
  const { children } = props;

  const user = useSelector(({ auth }: AppState) => auth.user);

  if (user) {
    if (user.onboardingStep === 'claim_account') {
      return <Redirect to={'/hero/onboarding/claim-account'}></Redirect>;
    } else if (user.onboardingStep === 'screening_interview') {
      return <Redirect to={'/hero/onboarding/schedule-interview'}></Redirect>;
    } else if (user.onboardingStep === 'qualifications') {
      return <Redirect to={'/hero/onboarding/qualifications'}></Redirect>;
    } else {
      return <>{children}</>;
    }
  } else {
    return <Redirect to={'/auth/login'}></Redirect>;
  }
};

export default HasToPassOnboarding;
