import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const NEEDS_REDUCERS = {
  setNeedsStart(state: AuthState) {
    state.isChangingNeeds = true;
    state.isChangingNeedsError = false;
  },
  setNeedsFailed(state: AuthState) {
    state.isChangingNeedsError = true;
  },
  setNeedsSuccess(state: AuthState, action: PayloadAction<Array<string>>) {
    state.isChangingNeeds = false;
    state.isChangingNeedsError = false;
    if (state.user) {
      state.user.otherNeeds = action.payload;
    }
  },
};
