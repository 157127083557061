import { createSlice } from '@reduxjs/toolkit';
import { AppNS } from 'app';

import { signOut } from '../services/jwtService';

import { AVAILABILITY_REDUCERS } from './auth/reducers/availability';
import { AVATAR_REDUCERS } from './auth/reducers/avatar';
import { LOCATIONS_REDUCERS } from './auth/reducers/locations';
import { INTERESTS_REDUCERS } from './auth/reducers/interests';
import { LANGUAGES_REDUCERS } from './auth/reducers/languages';
import { NEEDS_REDUCERS } from './auth/reducers/needs';
import { ONBOARDING_STEP_REDUCERS } from './auth/reducers/onboardingStep';
import { PERSONAL_DETAILS_REDUCERS } from './auth/reducers/personalDetails';
import { UNAVAILABLE_PERIODS_REDUCERS } from './auth/reducers/unavailablePeriods';
import { VIDEO_REDUCERS } from './auth/reducers/video';
import { ABOUT_REDUCERS } from './auth/reducers/about';
import { CREDIT_CARDS_REDUCERS } from './auth/reducers/creditCards';
import { GIFT_CARDS_REDUCERS } from './auth/reducers/giftCards';
import { CARE_CREDIT_REDUCERS } from './auth/reducers/careCredits';
import { SETTINGS_REDUCERS } from './auth/reducers/settings';
import { LAST_SEARCH_REDUCERS } from './auth/reducers/lastSearch';
import { SAVINGS_REDUCERS } from './auth/reducers/savings';

const cleanLocalStorage = () => {
  localStorage.removeItem('ondecare_step1_success');
  localStorage.removeItem('ondecare_client_register_success');
};

export interface AuthState {
  user: AppNS.User | null;
  userCreditCards: Array<AppNS.CreditCard>;
  userGiftCards: Array<AppNS.GiftCard>;
  userCareCredits: Array<AppNS.CareCredit>;
  userLastSearch: AppNS.Params;
  userSavings: AppNS.Params;
  isChangingAddress: boolean;
  isChangingAddressError: boolean;
  isChangingAvailability: boolean;
  isChangingAvailabilityError: boolean;
  isChangingAvatar: boolean;
  isChangingAvatarError: boolean;
  isChangingVideo: boolean;
  isChangingVideoError: boolean;
  isChangingCurrentDistance: boolean;
  isChangingCurrentDistanceError: boolean;
  isChangingCurrentServiceAddressId: boolean;
  isChangingCurrentServiceAddressIdError: boolean;
  isChangingInterests: boolean;
  isChangingInterestsError: boolean;
  isChangingLanguages: boolean;
  isChangingLanguagesError: boolean;
  isChangingNeeds: boolean;
  isChangingNeedsError: boolean;
  isChangingPeriods: boolean;
  isChangingPeriodsError: boolean;
  isChangingPersonalData: boolean;
  isChangingPersonalDataError: boolean;
  isChangingStep: boolean;
  isChangingStepError: boolean;
  isLoggingOut: boolean;
  isChangingAbout: boolean;
  isChangingAboutError: boolean;
  isLoadingCreditCard: boolean;
  isLoadingCreditCardError: boolean;
  isAddingCreditCard: boolean;
  isAddingGiftCard: boolean;
  isLoadingGiftCards: boolean;
  isLoadingGiftCardError: boolean;
  isAddingGiftCardError: boolean;
  isLoadingCareCredits: boolean;
  isLoadingCareCreditError: boolean;
  isLoadingLastSearch: boolean;
  isLoadingLastSearchError: boolean;
  isLoadingSavings: boolean;
  isLoadingSavingsError: boolean;
  isAddingCreditCardError: boolean;
  addingCreditCardError: String;
  isRemovingCreditCard: boolean;
  isRemovingCreditCardError: boolean;
  isChangingEmail: boolean;
  isChangingEmailError: boolean;
  isChangingEmailSuccess: boolean;
  isChangingMobile: boolean;
  isChangingMobileError: boolean;
  isChangingMobileSuccess: boolean;
}

const initialState: AuthState = {
  user: null,
  userCreditCards: [],
  userGiftCards: [],
  userCareCredits: [],
  userLastSearch: {},
  userSavings: {},
  isChangingAddress: false,
  isChangingAddressError: false,
  isChangingAvailability: false,
  isChangingAvailabilityError: false,
  isChangingAvatar: false,
  isChangingAvatarError: false,
  isChangingVideo: false,
  isChangingVideoError: false,
  isChangingCurrentDistance: false,
  isChangingCurrentDistanceError: false,
  isChangingCurrentServiceAddressId: false,
  isChangingCurrentServiceAddressIdError: false,
  isChangingInterests: false,
  isChangingInterestsError: false,
  isChangingLanguages: false,
  isChangingLanguagesError: false,
  isChangingNeeds: false,
  isChangingNeedsError: false,
  isChangingPeriods: false,
  isChangingPeriodsError: false,
  isChangingPersonalData: false,
  isChangingPersonalDataError: false,
  isChangingStep: false,
  isChangingStepError: false,
  isLoggingOut: false,
  isChangingAbout: false,
  isChangingAboutError: false,
  isLoadingCreditCard: false,
  isLoadingGiftCards: false,
  isLoadingCareCredits: false,
  isLoadingCreditCardError: false,
  isLoadingLastSearch: false,
  isLoadingLastSearchError: false,
  isLoadingSavings: false,
  isLoadingSavingsError: false,
  isAddingCreditCard: false,
  isAddingGiftCard: false,
  isLoadingGiftCardError: false,
  isAddingGiftCardError: false,
  isAddingCreditCardError: false,
  addingCreditCardError: '',
  isRemovingCreditCard: false,
  isRemovingCreditCardError: false,
  isLoadingCareCreditError: false,
  isChangingEmail: false,
  isChangingEmailError: false,
  isChangingEmailSuccess: false,
  isChangingMobile: false,
  isChangingMobileError: false,
  isChangingMobileSuccess: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    ...AVAILABILITY_REDUCERS,
    ...AVATAR_REDUCERS,
    ...VIDEO_REDUCERS,
    ...LOCATIONS_REDUCERS,
    ...INTERESTS_REDUCERS,
    ...LANGUAGES_REDUCERS,
    ...NEEDS_REDUCERS,
    ...ONBOARDING_STEP_REDUCERS,
    ...UNAVAILABLE_PERIODS_REDUCERS,
    ...PERSONAL_DETAILS_REDUCERS,
    ...ABOUT_REDUCERS,
    ...CREDIT_CARDS_REDUCERS,
    ...GIFT_CARDS_REDUCERS,
    ...CARE_CREDIT_REDUCERS,
    ...SETTINGS_REDUCERS,
    ...LAST_SEARCH_REDUCERS,
    ...SAVINGS_REDUCERS,
    setAuthData(state: AuthState, action: { payload: AppNS.User }) {
      state.user = action.payload;

      if (state.user.onboardingStep) {
        cleanLocalStorage();
      }
    },
    updateUserData(state: AuthState, action: { payload: AppNS.Params }) {
      state.user = { ...state.user, ...action.payload } as AppNS.User;
    },
    logoutStart(state: AuthState) {
      state.isLoggingOut = true;
    },
    logout(state: AuthState, action: { payload: boolean | undefined }) {
      cleanLocalStorage();
      state.user = null;
      signOut(action?.payload);
    },
  },
});

export const {
  changeOnboardingStepFailed,
  changeOnboardingStepStart,
  changeOnboardingStepSuccess,
  logout,
  logoutStart,
  setAddressFailed,
  setAddressStart,
  setAddressSuccess,
  setAuthData,
  setAvailabilityFailed,
  setAvailabilityStart,
  setAvailabilitySuccess,
  setAvatarFailed,
  setAvatarStart,
  setAvatarSuccess,
  setVideoFailed,
  setVideoStart,
  setVideoSuccess,
  setCurrentServiceAddressIdFailed,
  setCurrentServiceAddressIdStart,
  setCurrentServiceAddressIdSuccess,
  setCurrentDistanceFailed,
  setCurrentDistanceStart,
  setCurrentDistanceSuccess,
  setInterestsFailed,
  setInterestsStart,
  setInterestsSuccess,
  setLanguagesFailed,
  setLanguagesStart,
  setLanguagesSuccess,
  setNeedsFailed,
  setNeedsStart,
  setNeedsSuccess,
  setUnavailablePeriodsFailed,
  setUnavailablePeriodsStart,
  setUnavailablePeriodsSuccess,
  setPersonalDataFailed,
  setPersonalDataStart,
  setPersonalDataSuccess,
  updateUserData,
  setAboutFailed,
  setAboutStart,
  setAboutSuccess,
  getCreditCardsStart,
  getCreditCardsSuccess,
  getCreditCardsFailed,
  addCreditCardStart,
  addCreditCardFailed,
  addCreditCardSuccess,
  getGiftCardsStart,
  getGiftCardsSuccess,
  getGiftCardsFailed,
  getCareCreditsStart,
  getCareCreditsSuccess,
  getCareCreditsFailed,
  addGiftCardStart,
  addGiftCardFailed,
  addGiftCardSuccess,
  removeCreditCardStart,
  removeCreditCardFailed,
  removeCreditCardSuccess,
  changeEmailStart,
  changeEmailFailed,
  changeEmailSuccess,
  changeMobileStart,
  changeMobileFailed,
  changeMobileSuccess,
  getLastSearchStart,
  getLastSearchSuccess,
  getLastSearchFailed,
  getSavingsStart,
  getSavingsSuccess,
  getSavingsFailed,
} = authSlice.actions;

export default authSlice.reducer;
