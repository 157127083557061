import React from 'react';
import { Toolbar, Button, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ToolbarLogo from './ToolbarLogo';
import ToolbarMenu from './toolbar-menu/ToolbarMenu';
import { useStyles } from './toolbar-menu/ToolbarMenuStyles';
import { AppNS } from 'app';

const loginItems = [
  { id: 'login', href: '/auth/login', name: 'Log In' },
] as Array<AppNS.ToolbarMenuItem>;

const ToolbarNotLogged: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Toolbar disableGutters>
      <ToolbarLogo logged={false} />
      {!(
        window.location.href.includes('client-enroll') ||
        window.location.href.includes('register')
      ) && (
        <>
          <Box flexGrow="1" />
          <ToolbarMenu
            menuItems={loginItems}
            noFloatingMenu={true}
          ></ToolbarMenu>

          {!(
            window.location.href.includes('email=') ||
            window.location.href.includes('token=')
          ) && (
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
              disableElevation
              component={Link}
              to="/register"
              className={classes.registerButton}
            >
              Get Started
            </Button>
          )}
        </>
      )}
    </Toolbar>
  );
};

export default ToolbarNotLogged;
