import moment from 'moment';

export const UI_DATE_FORMAT_STRING = 'MM/DD/YYYY'; // used for showing dates for users
export const DATE_FORMAT_STRING = 'DD/MM/YYYY';
export const DB_DATE_FORMAT_STRING = 'YYYY-MM-DD';
export const DB_TIME_FORMAT_STRING = "HH:mm";
export const DB_DATETIME_FORMAT_STRING = 'YYYY-MM-DD HH:mm';

export const parseDate = (
  date: string,
  format: string = `${DATE_FORMAT_STRING}, HH:mm A`
): string => {
  return moment(date).format(format);
};

export const parseDateNoTime = (date: string): string => {
  return parseDate(date, DATE_FORMAT_STRING);
};

export const parseUiDateNoTime = (date: string): string => {
  return parseDate(date, UI_DATE_FORMAT_STRING);
}

export const parseTime = (date: string): string => {
  return moment(date).format('dddd, MMMM Do YYYY, h:mm a');
};

export const parseTimeShort = (date: string): string => {
  return moment(date).format('MMMM Do YYYY, h:mm a');
};

export const militaryToStandardTime = (time: string) => {
  return moment(time, 'HH:mm').format('h:mm a')
}
