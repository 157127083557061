import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getCookie, setCookie } from 'app/helpers/cookies';

export type ToursState = {
  error: string | null;
  isLoading: boolean;
  isUpdating: boolean;
  tourInProgress: boolean;
  tourPosition: number;
};

const initialState: ToursState = {
  error: null,
  isLoading: false,
  isUpdating: false,
  tourInProgress: false,
  tourPosition: 0,
};

const getTourCookie = (page: string) => {
  return +getCookie(page);
};

const setTourCookie = (page: string, position: number) => {
  const position_str = position.toString();
  setCookie(page, position_str);
};

const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setTourInProgressSuccess(state: ToursState, action: PayloadAction<boolean>) {
      state.tourInProgress = action.payload;
    },
    getTourPositionSuccess(state: ToursState, action: PayloadAction<number>) {
      state.tourPosition = action.payload;
      state.isLoading = false;
    },
    setTourPositionSuccess(state: ToursState, action: PayloadAction<number>) {
      state.tourPosition = action.payload;
      state.isUpdating = false;
      if (action.payload < 100) {
        state.tourInProgress = true
      } else {
        state.tourInProgress = false
      }
    },
    setTourPositionFailed(state: ToursState, action: PayloadAction<number>) {
      state.tourPosition = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  setTourInProgressSuccess,
  getTourPositionSuccess,
  setTourPositionSuccess,
  setTourPositionFailed,
} = toursSlice.actions;

export default toursSlice.reducer;

export const setTourInProgress = (isInProgress: boolean): AppThunk => (dispatch) => {
  dispatch(setTourInProgressSuccess(isInProgress));
};

export const fetchTourPosition = (page: string): AppThunk => (dispatch) => {
  let posn = getTourCookie(page);
  dispatch(getTourPositionSuccess(posn));
};

export const setTourPosition = (page: string, position: number): AppThunk => (dispatch) => {
  if (navigator.cookieEnabled) {
    setTourCookie(page, position);
    dispatch(setTourPositionSuccess(position));
  } else {
    dispatch(setTourPositionFailed(404));
  }
};
