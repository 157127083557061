import { createSlice } from '@reduxjs/toolkit';
import { AppNS } from '..';
import { AppThunk } from './index';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  apiSetClaimAccountDetails,
  registerUser,
  apiGetHeroOnboardingInfo,
  apiGetHeroReapprovalInfo,
  apiGetHeroMatchedJobs,
} from '../api/users';
import { updateUserData } from './auth';

export interface HeroOnboardingState {
  isRegisterHeroLoading: boolean;
  isRegisterHeroRequested: boolean;
  isRegisterHeroSuccess: boolean;
  heroOnboardingInfo: AppNS.Params;
  isLoadingHeroOnboardingInfo: boolean;
  isLoadingHeroOnboardingInfoError: boolean;
  heroReapprovalInfo: AppNS.Params;
  isLoadingHeroReapprovalInfo: boolean;
  isLoadingHeroReapprovalInfoError: boolean;

  page: number;
  perPage: number;
  search: string;
  totalEntries: number;
  heroMatchedJobs: Array<AppNS.Params>;
  isLoadingHeroMatchedJobs: boolean;
  isLoadingHeroMatchedJobsError: boolean;
}

const initialState: HeroOnboardingState = {
  isRegisterHeroLoading: false,
  isRegisterHeroRequested: false,
  isRegisterHeroSuccess: false,
  heroOnboardingInfo: {},
  isLoadingHeroOnboardingInfo: false,
  isLoadingHeroOnboardingInfoError: false,
  heroReapprovalInfo: {},
  isLoadingHeroReapprovalInfo: false,
  isLoadingHeroReapprovalInfoError: false,
  page: 1,
  perPage: 10,
  search: '',
  totalEntries: 0,
  heroMatchedJobs: [],
  isLoadingHeroMatchedJobs: false,
  isLoadingHeroMatchedJobsError: false,
};

const heroOnboardingSlice = createSlice({
  name: 'heroOnboarding',
  initialState,
  reducers: {
    registerHeroReset(state: HeroOnboardingState) {
      state.isRegisterHeroRequested = false;
      state.isRegisterHeroLoading = false;
      state.isRegisterHeroSuccess = false;
    },
    registerHeroStart(state: HeroOnboardingState) {
      state.isRegisterHeroRequested = false;
      state.isRegisterHeroLoading = true;
      state.isRegisterHeroSuccess = false;
    },
    registerHeroSuccess(state: HeroOnboardingState) {
      state.isRegisterHeroRequested = true;
      state.isRegisterHeroLoading = false;
      state.isRegisterHeroSuccess = true;
    },
    registerHeroFailed(state: HeroOnboardingState) {
      state.isRegisterHeroRequested = true;
      state.isRegisterHeroSuccess = false;
      state.isRegisterHeroLoading = false;
    },
    getHeroOnboardingInfoStart(state: HeroOnboardingState) {
      state.isLoadingHeroOnboardingInfo = true;
      state.isLoadingHeroOnboardingInfoError = false;
    },
    getHeroOnboardingInfoFailed(state: HeroOnboardingState) {
      state.isLoadingHeroOnboardingInfo = false;
      state.isLoadingHeroOnboardingInfoError = true;
    },
    getHeroOnboardingInfoSuccess(
      state: HeroOnboardingState,
      action: PayloadAction<AppNS.Params>
    ) {
      state.isLoadingHeroOnboardingInfo = false;
      state.isLoadingHeroOnboardingInfoError = false;
      state.heroOnboardingInfo = action.payload;
    },
    getHeroReapprovalInfoStart(state: HeroOnboardingState) {
      state.isLoadingHeroReapprovalInfo = true;
      state.isLoadingHeroReapprovalInfoError = false;
    },
    getHeroReapprovalInfoFailed(state: HeroOnboardingState) {
      state.isLoadingHeroReapprovalInfo = false;
      state.isLoadingHeroReapprovalInfoError = true;
    },
    getHeroReapprovalInfoSuccess(
      state: HeroOnboardingState,
      action: PayloadAction<AppNS.Params>
    ) {
      state.isLoadingHeroReapprovalInfo = false;
      state.isLoadingHeroReapprovalInfoError = false;
      state.heroReapprovalInfo = action.payload;
    },
    resetCollection(state) {
      state.heroMatchedJobs = [];
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCurrentParams(state, action: PayloadAction<AppNS.Params>) {
      state.page = action.payload.page;
      state.search = action.payload.search;
    },
    getHeroMatchedJobsStart(state: HeroOnboardingState) {
      state.isLoadingHeroMatchedJobs = true;
      state.isLoadingHeroMatchedJobsError = false;
    },
    getHeroMatchedJobsFailed(state: HeroOnboardingState) {
      state.isLoadingHeroMatchedJobs = false;
      state.isLoadingHeroMatchedJobsError = true;
    },
    getHeroMatchedJobsSuccess(
      state: HeroOnboardingState,
      action: PayloadAction<AppNS.Params>
    ) {
      state.isLoadingHeroMatchedJobs = false;
      state.isLoadingHeroMatchedJobsError = false;
      state.heroMatchedJobs = action.payload.results;
      state.totalEntries = action.payload.totalEntries;
    },
  },
});

export const {
  registerHeroFailed,
  registerHeroStart,
  registerHeroSuccess,
  registerHeroReset,
  getHeroOnboardingInfoStart,
  getHeroOnboardingInfoFailed,
  getHeroOnboardingInfoSuccess,
  getHeroReapprovalInfoStart,
  getHeroReapprovalInfoFailed,
  getHeroReapprovalInfoSuccess,
  setCurrentParams,
  getHeroMatchedJobsStart,
  getHeroMatchedJobsFailed,
  getHeroMatchedJobsSuccess,
} = heroOnboardingSlice.actions;

export default heroOnboardingSlice.reducer;

export const registerHero =
  (params: AppNS.Params): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(registerHeroStart());
      await registerUser(params);
      dispatch(registerHeroSuccess());
    } catch (err) {
      console.error(err.toString());
      dispatch(registerHeroFailed());
    }
  };

export const setClaimAccountDetails =
  (params: AppNS.Params, currentAddressId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(registerHeroStart());
      const response = await apiSetClaimAccountDetails(params);
      dispatch(
        updateUserData({
          id: response.id,
          ...response.properties,
          addresses: [
            { ...params.models.address[0].properties, id: currentAddressId },
          ],
          serviceDistanceMi: '15',
          serviceAddressId: currentAddressId,
        })
      );
      dispatch(registerHeroSuccess());
    } catch (err) {
      console.error(err.toString());
      dispatch(registerHeroFailed());
    }
  };

export const fetchHeroOnboardingInfo = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getHeroOnboardingInfoStart());
    const response = await apiGetHeroOnboardingInfo();
    dispatch(getHeroOnboardingInfoSuccess(response));
  } catch (err) {
    console.error(err.toString());
    dispatch(getHeroOnboardingInfoFailed());
  }
};

export const fetchHeroReapprovalInfo = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getHeroReapprovalInfoStart());
    const response = await apiGetHeroReapprovalInfo();
    dispatch(getHeroReapprovalInfoSuccess(response));
  } catch (err) {
    console.error(err.toString());
    dispatch(getHeroReapprovalInfoFailed());
  }
};

export const fetchMatchedJobs =
  (searchQuery: string, page: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getHeroMatchedJobsStart());
      const response = await apiGetHeroMatchedJobs(searchQuery, page);
      dispatch(getHeroMatchedJobsSuccess(response));
    } catch (err) {
      console.error(err.toString());
      dispatch(getHeroMatchedJobsFailed());
    }
  };
