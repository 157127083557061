import { createSlice } from '@reduxjs/toolkit';
import { AppNS } from '..';
import { AppThunk } from './index';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  apiGetReferences,
  apiCreateReference,
  apiDeleteReference,
} from '../api/hero-references/hero-references';

export interface HeroReferencesState {
  isLoading: boolean;
  isAdding: boolean;
  isUpdating: boolean;
  isUpdatingId: string;
  items: Array<AppNS.HeroReferenceFull>;
  result: string;
  isLoadingError: boolean;
  error: boolean
}

const initialState: HeroReferencesState = {
  isLoading: false,
  isAdding: false,
  isUpdating: false,
  isUpdatingId: '',
  items: [],
  result: '',
  isLoadingError: false,
  error: false,
};

const heroReferencesSlice = createSlice({
  name: 'heroReferences',
  initialState,
  reducers: {
    resetCollection(state) {
      state.items = [];
    },
    getHeroReferencesStart(state: HeroReferencesState) {
      state.isLoading = true;
    },
    getHeroReferencesSuccess(
      state: HeroReferencesState,
      action: PayloadAction<Array<AppNS.HeroReferenceFull>>
    ) {
      state.isLoading = false;
      state.isLoadingError = false;
      state.items = action.payload;
    },
    getHeroReferencesFailed(state: HeroReferencesState) {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    addHeroReferenceStart(state: HeroReferencesState, action: PayloadAction<string>) {
      state.isUpdating = true;
      state.isUpdatingId = action.payload
    },
    addHeroReferenceSuccess(
      state: HeroReferencesState,
      action: PayloadAction<string>
    ) {
      state.isUpdating = false;
      state.isUpdatingId = '';
      state.error = false;
      state.result = action.payload;

    },
    addHeroReferenceFailed(state: HeroReferencesState) {
      state.isUpdating = false;
      state.isUpdatingId = '';
      state.error = true;
    },
    deleteHeroReferenceStart(state: HeroReferencesState, action: PayloadAction<string>) {
      state.isUpdating = true;
      state.isUpdatingId = action.payload
    },
    deleteHeroReferenceSuccess(
      state: HeroReferencesState,
      action: PayloadAction<string>
    ) {
      state.isUpdating = false;
      state.isUpdatingId = '';
      state.error = false;
      state.result = action.payload;

    },
    deleteHeroReferenceFailed(state: HeroReferencesState) {
      state.isUpdating = false;
      state.isUpdatingId = '';
      state.error = true;
    },
  },
});

export const {
  resetCollection,
  getHeroReferencesStart,
  getHeroReferencesFailed,
  getHeroReferencesSuccess,
  addHeroReferenceStart,
  addHeroReferenceFailed,
  addHeroReferenceSuccess,
  deleteHeroReferenceStart,
  deleteHeroReferenceFailed,
  deleteHeroReferenceSuccess,
} = heroReferencesSlice.actions;

export default heroReferencesSlice.reducer;

export const fetchHeroReferences =
  (): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getHeroReferencesStart());
      const data = await apiGetReferences();
      dispatch(getHeroReferencesSuccess(data));
    } catch (err) {
      console.error(err.toString());
      dispatch(getHeroReferencesFailed());
    }
  };

export const addHeroReference =
  (params: AppNS.Params): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addHeroReferenceStart(params.email));
      const response = await apiCreateReference(params);
      dispatch(addHeroReferenceSuccess(response));
    } catch (err) {
      console.error(err.toString());
      dispatch(addHeroReferenceFailed());
    }
  };

export const deleteHeroReference =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteHeroReferenceStart(id));
      const response = await apiDeleteReference({ id });
      dispatch(deleteHeroReferenceSuccess(response));
    } catch (err) {
      console.error(err.toString());
      dispatch(deleteHeroReferenceFailed());
    }
  };
