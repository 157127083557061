import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { AppState } from '../../store';
import { ifClientApproved } from './rules/clientRules';

interface HasToBeApprovedClientGuardProps {
  children: React.ReactNode;
}

export const HasToBeApprovedClientGuard = (
  props: HasToBeApprovedClientGuardProps
) => {
  const { children } = props;

  const user = useSelector(({ auth }: AppState) => auth.user);

  if (!ifClientApproved(user)) {
    return <Redirect to={'/client/dashboard'}></Redirect>;
  }

  return <>{children}</>;
};

export default HasToBeApprovedClientGuard;
