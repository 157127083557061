import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'app/store';
import { SplashScreen } from 'app/ui/components/SplashScreen';
import useLoggedStatus from '../hooks/useLoggedStatus';

interface CheckIsLoggedInGuardProps {
  children: React.ReactNode;
}

export const CheckIsLoggedInGuard = (props: CheckIsLoggedInGuardProps) => {
  const { children } = props;
  const user = useSelector(({ auth }: AppState) => auth.user);
  const waitAuthCheck = useLoggedStatus(user);

  if (waitAuthCheck) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default CheckIsLoggedInGuard;
