import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const INTERESTS_REDUCERS = {
  setInterestsStart(state: AuthState) {
    state.isChangingInterests = true;
    state.isChangingInterestsError = false;
  },
  setInterestsFailed(state: AuthState) {
    state.isChangingInterestsError = true;
  },
  setInterestsSuccess(
    state: AuthState,
    action: PayloadAction<{
      interests: Array<string>;
      extraInterests: string;
    }>
  ) {
    state.isChangingInterests = false;
    state.isChangingInterestsError = false;
    if (state.user) {
      state.user.interests = action.payload.interests;
      state.user.extraInterests = action.payload.extraInterests || '';
    }
  },
};
