import { AppNS } from 'app';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthData } from 'app/store/auth';
import { logoutUser } from './../../store/auth/thunks/logout';
import { signInWithToken } from 'app/services/jwtService';

function useLoggedStatus(user: AppNS.User | null) {
  const [waitAuthCheck, setWaitAuthCheck] = useState<boolean>(
    user ? false : true
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (waitAuthCheck) {
      signInWithToken()
        .then((signedUser) => {
          dispatch(setAuthData(signedUser));
          setWaitAuthCheck(false);
        })
        .catch(() => {
          if (user) {
            dispatch(logoutUser());
          }
          setWaitAuthCheck(false);
        });
    }
  }, [dispatch, waitAuthCheck]);
  return waitAuthCheck;
}

export default useLoggedStatus;
