import { PayloadAction } from '@reduxjs/toolkit';
import { AppNS } from 'app';
import { AuthState } from './../../auth';

export const ONBOARDING_STEP_REDUCERS = {
  changeOnboardingStepStart(state: AuthState) {
    state.isChangingStep = true;
    state.isChangingStepError = false;
  },
  changeOnboardingStepFailed(state: AuthState) {
    state.isChangingStepError = true;
  },
  changeOnboardingStepSuccess(
    state: AuthState,
    action: PayloadAction<AppNS.UserOnboardingStep>
  ) {
    state.isChangingStep = false;
    state.isChangingStepError = false;
    if (state.user) {
      state.user.onboardingStep = action.payload;
    }
  },
};
