import React from 'react';
import { matchRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import routes from '../config/routes';

interface RouteGuard {
  children: React.ReactNode;
}
interface RouteWrapper {
  content: React.ReactNode;
  guards: Array<React.FunctionComponent>;
}

export const RouteGuard = (props: RouteGuard) => {
  const { children } = props;
  const { pathname } = useLocation();
  const branch = matchRoutes(routes, pathname);
  const guards = branch[0].route.canActivate;

  if (guards && guards.length) {
    return <Wrapper content={children} guards={[...guards]} />;
  }

  return <>{children}</>;
};

const Wrapper = (props: RouteWrapper) => {
  const { content, guards } = props;

  if (guards.length) {
    const Guard = guards.shift() as React.FunctionComponent;
    return (
      <Guard>
        <Wrapper content={content} guards={guards} />
      </Guard>
    );
  }
  return <>{content}</>;
};

export default RouteGuard;
