import { PayloadAction } from '@reduxjs/toolkit';
import { AppNS } from 'app';
import { AuthState } from './../../auth';

export const AVAILABILITY_REDUCERS = {
  setAvailabilityStart(state: AuthState) {
    state.isChangingAvailability = true;
    state.isChangingAvailabilityError = false;
  },
  setAvailabilityFailed(state: AuthState) {
    state.isChangingAvailabilityError = true;
  },
  setAvailabilitySuccess(
    state: AuthState,
    action: PayloadAction<AppNS.Params>
  ) {
    state.isChangingAvailability = false;
    state.isChangingAvailabilityError = false;
    if (state.user) {
      state.user = { ...state.user, ...action.payload };
    }
  },
};
