import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { API_URL } from 'app/core/config';
import { AppNS } from 'app';

const TOKEN_KEY = 'jwt_access_token_ondecare_app';
const LOGIN_AS_TOKEN_KEY = 'jwt_login_as_access_token_ondecare_app';
const ADMIN_TOKEN_KEY = 'jwt_admin_token_ondecare_app';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Save access token and automatically add auth to all requests done with axios
export function setSession(token: string | null) {
  if (token) {
    localStorage.setItem(TOKEN_KEY, token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    localStorage.removeItem(TOKEN_KEY);
    delete axios.defaults.headers.common['Authorization'];
  }
}

export function setLoginAsSession(
  token: string | null,
  adminToken: string | null
) {
  if (token) {
    localStorage.setItem(LOGIN_AS_TOKEN_KEY, token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    if (adminToken) {
      localStorage.setItem(ADMIN_TOKEN_KEY, adminToken);
      axios.defaults.headers.common['Authorization-Admin'] =
        'Bearer ' + adminToken;
    }
  } else {
    localStorage.removeItem(LOGIN_AS_TOKEN_KEY);
    localStorage.removeItem(ADMIN_TOKEN_KEY);
    delete axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['Authorization-Admin'];
  }
}

export const getLoginAsAccessToken = (): string | null => {
  return localStorage.getItem(LOGIN_AS_TOKEN_KEY);
};

export const getAdminAccessToken = (): string | null => {
  return localStorage.getItem(ADMIN_TOKEN_KEY);
};

function getAccessToken() {
  // if LOGIN_AS_TOKEN_KEY exists it has priority
  const loginAsToken = getLoginAsAccessToken();
  if (loginAsToken) {
    return loginAsToken;
  }
  return localStorage.getItem(TOKEN_KEY);
}

function isAuthTokenValid(token: string): boolean {
  if (!token) {
    return false;
  }
  const decoded: { user_id: number } = jwtDecode(token);
  if (!decoded.user_id) {
    console.warn('invalid user token');
    return false;
  }
  return true;
}

export const signInWithEmailAndPassword = (
  email: string,
  password: string,
  additionalParams?: AppNS.Params
): Promise<AppNS.User> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth`, {
        email,
        password,
        ...(additionalParams || {}),
      })
      .then(({ data }) => {
        if (data.data) {
          setSession(data.data.accessToken);
          resolve(data.data.user);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
};

export const signInWithToken = (): Promise<AppNS.User> => {
  const token = getAccessToken();
  if (!token) {
    return Promise.reject('Token not found');
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/token`, {
        access_token: token,
      })
      .then(({ data }) => {
        if (data.data.user) {
          if (getLoginAsAccessToken()) {
            setLoginAsSession(data.data.accessToken, getAdminAccessToken());
          } else {
            setSession(data.data.accessToken);
          }
          resolve(data.data.user);
        } else {
          setSession(null);
          reject('Failed to login with token.');
        }
      })
      .catch((error) => {
        setSession(null);
        reject('Failed to login with token.');
      });
  });
};

export const signInWithAdminToken = (
  adminId: string,
  adminToken: string,
  userId: string
): Promise<AppNS.User> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/login-as`, {
        adminId,
        adminToken,
        userId,
      })
      .then(({ data }) => {
        if (data.data.user) {
          setLoginAsSession(
            data.data.accessToken,
            `${adminToken}###${adminId}`
          );
          resolve(data.data.user);
        } else {
          setSession(null);
          reject('Failed to login with admin token.');
        }
      })
      .catch((error) => {
        setSession(null);
        reject('Failed to login with admin token.');
      });
  });
};

export const signOut = (forceNoRedirect?: boolean): void => {
  if (getLoginAsAccessToken()) {
    setLoginAsSession(null, null);
    // back to admin panel
    window.location.href = `/admin/users`;
  } else {
    setSession(null);
    if (!forceNoRedirect) {
      window.location.href = `/app/auth/login`;
    }
  }
};

export const handleAuthentication = (): boolean => {
  let token = getAccessToken();

  if (token && isAuthTokenValid(token)) {
    setSession(token);
    return true;
  }
  setSession(null);
  return false;
};
