import axios, { Canceler } from 'axios';
import { AppNS } from '..';
import { API_URL } from '../core/config';

const MA_URL = 'users/merchant_accounts.json';
const MA_SUCCESS_URL = 'merchant_account/success.json';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetMerchantAccount(): Promise<any> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${MA_URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.hasOwnProperty('model')) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiForwardSuccessCode(code: string): Promise<any> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${MA_SUCCESS_URL}?code=${code}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.result) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiDeleteMerchantAccount(
  reqData: AppNS.Params
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/${MA_URL}`, { params: reqData })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
