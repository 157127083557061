import { CARE_TYPES } from 'app/consts/careTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { AppNS } from '..';
import { AppThunk } from './index';
import { FormValues } from 'app/ui/modules/hero-care-types/components/care-type-form/validation-schema';

import {
  apiCreateCareType,
  apiUpdateCareType,
  apiDeleteCareType,
  apiGetCareTypes,
  apiUpdateCareTypesRates,
} from '../api/care-types';

export interface HeroCareTypesConfig {
  careTypesDeleteDisabled: boolean;
}

export interface StateCareTypeForm {
  id: string;
  careTypeId?: string;
  isError: string;
  mode: AppNS.CareTypeFormMode;
  defaultValues?: FormValues;
}

export interface HeroCareTypesState {
  config: HeroCareTypesConfig;
  careTypes: Array<AppNS.CareType>;
  careTypeInLoadingState: string;
  forms: Array<StateCareTypeForm>;
  getCareTypesError: boolean;
  isCollectionLoaded: boolean;
  isValid: boolean;
  canAddNew: boolean;
}

const initialState: HeroCareTypesState = {
  config: {
    careTypesDeleteDisabled: false,
  },
  careTypes: [],
  careTypeInLoadingState: '',
  forms: [],
  getCareTypesError: false,
  isCollectionLoaded: false,
  isValid: false,
  canAddNew: true,
};

function checkStateForms(state: HeroCareTypesState) {
  // if there's no certifications show empty form
  const careTypes = state.careTypes;

  // check if there is already some empty form
  const emptyForms = state.forms.filter((form: StateCareTypeForm) => {
    return form.mode === 'add';
  });
  if (careTypes.length < 1 && emptyForms.length < 1) {
    const certificationForm = createEmptyForm();
    state.forms = [...state.forms, certificationForm];
  }
}

function checkStateValid(state: HeroCareTypesState) {
  const careTypes = state.careTypes;
  state.isValid = careTypes.length > 0;
}

function checkCanAdd(state: HeroCareTypesState) {
  const careTypes = state.careTypes;
  const emptyForms = state.forms.filter((form: StateCareTypeForm) => {
    return form.mode === 'add';
  });

  state.canAddNew = true;
  if (careTypes.length + emptyForms.length >= CARE_TYPES.length) {
    state.canAddNew = false;
  }
}

const heroCareTypesSlice = createSlice({
  name: 'heroCareTypes',
  initialState,
  reducers: {
    setModuleConfig(
      state: HeroCareTypesState,
      action: PayloadAction<HeroCareTypesConfig>
    ) {
      state.config = action.payload;
    },
    checkState(state: HeroCareTypesState) {
      checkStateForms(state);
      checkStateValid(state);
      checkCanAdd(state);
    },
    getCareTypesStart(state: HeroCareTypesState) {
      state.isCollectionLoaded = false;
    },
    getCareTypesSuccess(
      state: HeroCareTypesState,
      action: PayloadAction<Array<AppNS.CareType>>
    ) {
      state.isCollectionLoaded = true;
      state.getCareTypesError = false;
      state.careTypes = action.payload;
    },
    getCareTypesFailed(state: HeroCareTypesState) {
      state.getCareTypesError = true;
    },
    addFormStart(
      state: HeroCareTypesState,
      action: PayloadAction<StateCareTypeForm>
    ) {
      state.forms = [...state.forms, action.payload];
    },
    addCareTypeStart(state: HeroCareTypesState, action: PayloadAction<string>) {
      state.careTypeInLoadingState = action.payload;
    },
    addCareTypeSuccess(
      state: HeroCareTypesState,
      action: PayloadAction<{ formId: string; data: AppNS.CareType }>
    ) {
      // remove form
      state.forms = state.forms.filter((form: StateCareTypeForm) => {
        return form.id !== action.payload.formId;
      });

      // and add careType
      state.careTypes = [...state.careTypes, action.payload.data];

      state.careTypeInLoadingState = '';
    },
    addCareTypeFailed(
      state: HeroCareTypesState,
      action: PayloadAction<string>
    ) {
      state.careTypeInLoadingState = '';
      state.forms = state.forms.map((form: StateCareTypeForm) => {
        if (form.id === action.payload) {
          return { ...form, isError: action.payload };
        }
        return form;
      });
    },

    removeCareTypeStart(
      state: HeroCareTypesState,
      action: PayloadAction<string>
    ) {
      state.careTypeInLoadingState = action.payload;
    },

    removeCareTypeSuccess(
      state: HeroCareTypesState,
      action: PayloadAction<string>
    ) {
      state.careTypeInLoadingState = '';

      // remove careType
      state.careTypes = state.careTypes.filter((item: AppNS.CareType) => {
        return item.id !== action.payload;
      });
    },

    removeCareTypeFailed(state: HeroCareTypesState) {
      state.careTypeInLoadingState = '';
    },

    editCareType(state: HeroCareTypesState, action: PayloadAction<string>) {
      // get careType data
      const careType = state.careTypes.filter((item: AppNS.CareType) => {
        return item.id === action.payload;
      })[0];

      const form = createEmptyForm(action.payload);

      // add form with edit mode
      form.mode = 'edit';
      form.careTypeId = action.payload;
      form.defaultValues = careType;
      state.forms = [...state.forms, form];
    },

    updateCareTypeStart(
      state: HeroCareTypesState,
      action: PayloadAction<string>
    ) {
      state.careTypeInLoadingState = action.payload;
    },
    updateCareTypeSuccess(
      state: HeroCareTypesState,
      action: PayloadAction<{ formId: string; data: AppNS.CareType }>
    ) {
      state.careTypeInLoadingState = '';

      // remove form
      state.forms = state.forms.filter((form: StateCareTypeForm) => {
        return form.id !== action.payload.formId;
      });

      // update careType
      state.careTypes = state.careTypes.map((careType: AppNS.CareType) => {
        if (careType.id === action.payload.data.id) {
          return { ...careType, ...action.payload.data };
        }
        return careType;
      });
    },
    updateCareTypeFailed(state: HeroCareTypesState) {
      state.careTypeInLoadingState = '';
    },

    updateCareTypesRatesStart(state: HeroCareTypesState) {
      state.careTypeInLoadingState = '1';
    },
    updateCareTypesRatesSuccess(
      state: HeroCareTypesState,
      action: PayloadAction<Array<{ id: string; properties: AppNS.CareType }>>
    ) {
      state.careTypeInLoadingState = '';

      // update careType hourlyRate
      state.careTypes = state.careTypes.map((careType: AppNS.CareType) => {
        const payloadItem = action.payload.filter((item) => {
          return item.id === careType.id;
        })[0];
        if (payloadItem) {
          return { ...careType, hourlyRate: payloadItem.properties.hourlyRate };
        }
        return careType;
      });
    },
    updateCareTypesRatesFailed(state: HeroCareTypesState) {
      state.careTypeInLoadingState = '';
    },

    removeForm(state: HeroCareTypesState, action: PayloadAction<string>) {
      // remove form
      state.forms = state.forms.filter((form: StateCareTypeForm) => {
        return form.id !== action.payload;
      });
    },
  },
});

export const {
  addCareTypeFailed,
  addCareTypeStart,
  addCareTypeSuccess,
  addFormStart,
  checkState,
  editCareType,
  getCareTypesFailed,
  getCareTypesStart,
  getCareTypesSuccess,
  removeCareTypeFailed,
  removeCareTypeStart,
  removeCareTypeSuccess,
  removeForm,
  setModuleConfig,
  updateCareTypeFailed,
  updateCareTypeStart,
  updateCareTypeSuccess,
  updateCareTypesRatesFailed,
  updateCareTypesRatesStart,
  updateCareTypesRatesSuccess,
} = heroCareTypesSlice.actions;

export default heroCareTypesSlice.reducer;

export const fetchCareTypes = (params: AppNS.Params): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(getCareTypesStart());
    // const careTypesResponse = [] as Array<AppNS.CareType>;
    const careTypesResponse = await apiGetCareTypes(params);
    dispatch(getCareTypesSuccess(careTypesResponse));
    dispatch(checkState());
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getCareTypesFailed());
  }
};

export const addCareType = (
  params: AppNS.Params,
  formId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(addCareTypeStart(formId));
    const careTypeData = await apiCreateCareType({ properties: params });

    dispatch(
      addCareTypeSuccess({
        formId,
        data: createDocumentData(careTypeData.id, params),
      })
    );

    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(addCareTypeFailed(err.toString()));
  }
};

export const updateCareType = (
  params: AppNS.Params,
  formId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateCareTypeStart(formId));

    const properties = { ...params };
    delete properties.id;
    const careTypeData = await apiUpdateCareType({
      id: params.id,
      properties,
    });

    dispatch(
      updateCareTypeSuccess({
        formId,
        data: createDocumentData(careTypeData.id, params),
      })
    );

    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(updateCareTypeFailed());
  }
};

export const updateCareTypesRates = (
  rates: Array<AppNS.Params>
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateCareTypesRatesStart());

    const careTypeRatesData = await apiUpdateCareTypesRates(rates);

    dispatch(
      updateCareTypesRatesSuccess(
        careTypeRatesData as Array<{ id: string; properties: AppNS.CareType }>
      )
    );

    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(updateCareTypesRatesFailed());
  }
};

export const removeCareType = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(removeCareTypeStart(id));
    await apiDeleteCareType({ id });
    dispatch(removeCareTypeSuccess(id));
    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(removeCareTypeFailed());
  }
};

export const addForm = (): AppThunk => (dispatch) => {
  const formData = createEmptyForm();
  dispatch(addFormStart(formData));
  dispatch(checkState());
};

export const editCareTypeAction = (id: string): AppThunk => (dispatch) => {
  dispatch(editCareType(id));
};

export const removeFormAction = (id: string): AppThunk => (dispatch) => {
  dispatch(removeForm(id));
  dispatch(checkState());
};

export const createEmptyForm = (
  id: string = String(new Date().getTime() + Math.round(Math.random() * 1000))
): StateCareTypeForm => {
  return {
    id,
    isLoading: false,
    isError: '',
    mode: 'add',
  } as StateCareTypeForm;
};

export const createDocumentData = (
  id: string,
  params: AppNS.Params
): AppNS.CareType => {
  return { ...params, id } as AppNS.CareType;
};

export const setConfig = (config: HeroCareTypesConfig): AppThunk => (
  dispatch
) => {
  dispatch(setModuleConfig(config));
};
