import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: '2px solid #E27816',
    zIndex: 1300,
    pointerEvents: 'none',
    '& .label': {
      position: 'absolute',
      left: '50%',
      top: '-2px',
      fontSize: '1rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      display: 'inline-block',
      background: '#e27816',
      color: '#fff',
      padding: '3px 10px',
      transform: 'translateX(-50%)',
      borderRadius: '0 0 5px 5px',
    },
  },
}));

export default function LoginAsOverlay() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className="label">Logged in as user</span>
    </div>
  );
}
