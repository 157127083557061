import React from 'react';
import { assetUrl } from '../../helpers/assetUrl';
import { makeStyles, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .logo': {
      position: 'relative',
      width: 100,
      height: 100,
      '& img': {
        display: 'block',
        marginTop: '6px',
      },

      '& .progress-container': {
        position: 'absolute',
        bottom: -10,
        width: '100%',
      },
    },
  },
}));

export const SplashScreen = React.memo(() => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="logo">
        <img
          width="100"
          src={`${assetUrl('/images/logos/logo-white.svg')}`}
          alt="OndeCare"
        />
        <div className="progress-container">
          <LinearProgress color="secondary" />
        </div>
      </div>
      <div className="app-splash__icons">
        <svg
          className="app-splash_icon"
          width="22"
          height="22"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9629 7.86902C15.3432 14.7933 21.2076 20.6577 28.1319 25.038"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.5604 20.1615L24.9849 16.737C26.1634 15.5564 27.679 14.7703 29.3229 14.487C30.678 14.2557 31.9276 13.6088 32.8989 12.636C35.3585 9.9741 35.2769 5.84494 32.7142 3.28221C30.1515 0.71948 26.0223 0.637944 23.3604 3.09751C22.387 4.06959 21.7402 5.32037 21.5094 6.67651C21.2245 8.31988 20.4387 9.83503 19.2594 11.0145L15.8379 14.439"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.915 29.4795C14.8949 29.4795 17.3105 27.0639 17.3105 24.084C17.3105 21.1042 14.8949 18.6885 11.915 18.6885C8.93518 18.6885 6.51953 21.1042 6.51953 24.084C6.51953 27.0639 8.93518 29.4795 11.915 29.4795Z"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.2 20.7C23.7565 25.45 21.8501 30.6475 17.5914 33.2645C13.3328 35.8816 7.83468 35.2343 4.30019 31.6998C0.7657 28.1653 0.118443 22.6672 2.73549 18.4086C5.35253 14.1499 10.55 12.2435 15.3 13.8"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          className="app-splash_icon"
          width="22"
          height="23"
          viewBox="0 0 36 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.875 18.375C28.0496 18.375 29.8125 16.6121 29.8125 14.4375C29.8125 12.2629 28.0496 10.5 25.875 10.5C23.7004 10.5 21.9375 12.2629 21.9375 14.4375C21.9375 16.6121 23.7004 18.375 25.875 18.375Z"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.375 29.625V26.25"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.1249 35.25C29.3675 35.25 30.3749 34.2426 30.3749 33V29.625C30.3749 30.8676 31.3822 31.875 32.6249 31.875C33.8675 31.875 34.8749 30.8676 34.8749 29.625V27.375C34.8749 23.6471 31.8528 20.625 28.1249 20.625H22.0169L17.8499 17.2995C16.903 16.5395 15.524 16.6677 14.7335 17.5894C13.9431 18.511 14.0265 19.8935 14.9219 20.7135L21.3794 25.998V33C21.3794 34.2426 22.3867 35.25 23.6294 35.25C24.872 35.25 25.8794 34.2426 25.8794 33C25.8794 34.2409 26.884 35.2475 28.1249 35.25Z"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.625 3.75L12.375 24H5.625V33C5.625 34.2426 4.61764 35.25 3.375 35.25C2.13236 35.25 1.125 34.2426 1.125 33V24"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.875 33V29.625"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          className="app-splash_icon"
          width="22"
          height="22"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.25 10.08C22.4246 10.08 24.1875 8.38766 24.1875 6.30002C24.1875 4.21238 22.4246 2.52002 20.25 2.52002C18.0754 2.52002 16.3125 4.21238 16.3125 6.30002C16.3125 8.38766 18.0754 10.08 20.25 10.08Z"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.25 25.2V32.76"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.75 23.04V18.72"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.25 25.2C11.25 24.0071 10.2426 23.04 9 23.04C7.75736 23.04 6.75 24.0071 6.75 25.2V34.92"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.75 23.04V32.76C15.75 33.9529 16.7574 34.92 18 34.92C19.2426 34.92 20.25 33.9529 20.25 32.76C20.25 33.9529 21.2574 34.92 22.5 34.92C23.7426 34.92 24.75 33.9529 24.75 32.76V23.04C24.75 24.2329 25.7574 25.2 27 25.2C28.2426 25.2 29.25 24.2329 29.25 23.04V18.72C29.25 15.1412 26.2279 12.24 22.5 12.24H18C14.2721 12.24 11.25 15.1412 11.25 18.72"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          className="app-splash_icon"
          width="22"
          height="23"
          viewBox="0 0 36 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.61328 12.1275C8.7879 12.1275 10.5508 10.3646 10.5508 8.19C10.5508 6.01538 8.7879 4.2525 6.61328 4.2525C4.43866 4.2525 2.67578 6.01538 2.67578 8.19C2.67578 10.3646 4.43866 12.1275 6.61328 12.1275Z"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.6427 33.861L14.8507 29.0025H10.6222C8.55674 29.0028 6.75626 27.5969 6.25573 25.593L4.43173 18.3C4.21585 17.5128 4.44326 16.6704 5.02596 16.0988C5.60865 15.5272 6.45524 15.316 7.23816 15.5469C8.02108 15.7778 8.61753 16.4147 8.79673 17.211L10.6192 24.5055H14.8507C17.0627 24.4978 18.9495 26.105 19.2937 28.29L20.0857 33.15C20.2837 34.3769 19.4496 35.532 18.2227 35.73C16.9958 35.928 15.8407 35.0939 15.6427 33.867V33.861Z"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.11328 33.5025H11.1133"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5668 4.55851C30.8096 2.80179 27.9611 2.80179 26.2038 4.55851L24.6123 6.15001L23.0208 4.55851C21.2592 2.82575 18.4299 2.8376 16.6829 4.58506C14.9358 6.33251 14.9247 9.16179 16.6578 10.923L23.8173 18.0825C24.2565 18.5212 24.9681 18.5212 25.4073 18.0825L32.5668 10.923C33.4109 10.0791 33.8851 8.93436 33.8851 7.74076C33.8851 6.54715 33.4109 5.40244 32.5668 4.55851Z"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.6133 8.7525V13.2525"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.3633 11.0025H26.8633"
            stroke="#67707E"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
});
