import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppNS } from '..';

export interface VisitorState {
  visitor: AppNS.Visitor;
}

export interface LocationParams {
  search: string;
  address: AppNS.Params;
  coordinates: { lat: string, lng: string }
}

export interface RatesParams {
  min: number;
  max: number;
}

const emptyVisitor = {
  viewedCareTypes: [],
  selectedCareType: '',
  locatedCity: '',
  locatedCountry: '',
  locatedState: '',
  locatedZip: '',
  locatedLat: '',
  locatedLng: '',
  geolocated: false,
  selectedHeroId: '',
  loginOrRegister: '',
  favoritedHeroIds: [],
  viewedSearches: [],
  viewedSearchesLatlng: [],
  selectedSearch: '',
  selectedLat: '',
  selectedLng: '',
  selectedTimeframe: '',
  selectedRateMin: 0,
  selectedRateMax: 0,
} as AppNS.Visitor;

const initialState: VisitorState = {
  visitor: emptyVisitor,
};

const visitor = createSlice({
  name: 'visitor',
  initialState,
  reducers: {
    resetVisitor(state: VisitorState) {
      state.visitor = emptyVisitor;
    },
    setVisitorCareType(state: VisitorState, action: PayloadAction<string>) {
      state.visitor.selectedCareType = action.payload;
    },
    setVisitorSearch(state: VisitorState, action: PayloadAction<string>) {
      state.visitor.selectedSearch = action.payload;
    },
    setVisitorSearchLocation(state: VisitorState, action: PayloadAction<LocationParams>) {
      state.visitor.selectedSearch = action.payload.search;
      state.visitor.locatedLat = action.payload.coordinates.lat;
      state.visitor.locatedLng = action.payload.coordinates.lng;
      state.visitor.locatedCountry = action.payload.address.country;
      state.visitor.locatedState = action.payload.address.state;
      state.visitor.locatedCity = action.payload.address.city;
      state.visitor.locatedZip = action.payload.address.zip;
    },
    setVisitorTimeframe(state: VisitorState, action: PayloadAction<string>) {
      state.visitor.selectedTimeframe = action.payload;
    },
    setVisitorRates(state: VisitorState, action: PayloadAction<RatesParams>) {
      state.visitor.selectedRateMin = action.payload.min;
      state.visitor.selectedRateMax = action.payload.max;
    },
  },
});

export const {
  resetVisitor,
  setVisitorCareType,
  setVisitorSearch,
  setVisitorSearchLocation,
  setVisitorTimeframe,
  setVisitorRates,
} = visitor.actions;

export default visitor.reducer;
