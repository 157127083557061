import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../../auth';

export const UNAVAILABLE_PERIODS_REDUCERS = {
  setUnavailablePeriodsStart(state: AuthState) {
    state.isChangingPeriods = true;
    state.isChangingPeriodsError = false;
  },
  setUnavailablePeriodsFailed(state: AuthState) {
    state.isChangingPeriodsError = true;
  },
  setUnavailablePeriodsSuccess(
    state: AuthState,
    action: PayloadAction<Array<string>>
  ) {
    state.isChangingPeriods = false;
    state.isChangingPeriodsError = false;

    if (state.user) {
      state.user.unavailablePeriods = action.payload || [];
    }
  },
};
