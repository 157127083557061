import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    tablet: true;
    lg: true;
    xl: true;
  }
}

export const GLOBAL_THEME = createMuiTheme({
  typography: {
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    htmlFontSize: 10,
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
    h1: {
      fontSize: '4rem',
      fontWeight: 'bold',
      fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      marginBottom: '11px',
      color: '#1a2c4e',
    },
    h2: {
      fontSize: '3.2rem',
      fontWeight: 'bold',
      fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      color: '#1a2c4e',
      marginBottom: '16px',
    },
    h3: {
      fontSize: '2.4rem',
      fontWeight: 'bold',
      fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 'normal',
      color: '#67707e',
    },
    h5: {
      fontSize: '2rem',
      fontWeight: 'bold',
      fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    h6: {
      fontSize: '1.8rem',
      fontWeight: 'normal',
      fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      textTransform: 'uppercase',
    },
  },
  palette: {
    primary: {
      dark: '#1a2c4e',
      main: '#149cc2',
    },
    secondary: {
      main: '#f4357a',
      dark: '#c72f66',
      contrastText: '#fff',
    },
    success: {
      main: '#28a96b',
      dark: '#166d12',
      light: '#e9f3e8',
    },
    error: {
      main: '#d50037',
      dark: '#aa002c',
    },
    text: {
      primary: '#535863',
      secondary: '#535863',
      hint: '#67707e',
    },
    info: {
      main: '#89929f',
      light: '#0F7895',
    },
    background: {
      paper: '#fff',
      default: '#f2f3f4',
    },
    divider: '#e1e5e9',
    grey: {
      50: '#fff',
      200: '#F2F3F4',
      400: '#e1e5e9',
      500: '#89929f',
      600: '#67707e',
      700: '#535863',
      900: '#0e182d',
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'tablet', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 448,
      md: 800,
      tablet: 960,
      lg: 1376,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 20,
  },
});
