import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import { AppNS } from 'app';
import { AppState } from 'app/store';
import { SplashScreen } from 'app/ui/components/SplashScreen';
import useLoggedStatus from '../hooks/useLoggedStatus';

interface CannotBeLoggedInGuardProps {
  children: React.ReactNode;
}

export const CannotBeLoggedInGuard = (props: CannotBeLoggedInGuardProps) => {
  const { children } = props;
  const { state }: { state: AppNS.Params } = useLocation();
  const user = useSelector(({ auth }: AppState) => auth.user);
  const waitAuthCheck = useLoggedStatus(user);

  if (waitAuthCheck) {
    return <SplashScreen />;
  }

  if (Array.isArray(user?.roles) && user?.roles.length === 0) {
    return <Redirect to={'/claim-account/choose-role'} />;
  } else if (user) {
    if (state?.referrer) {
      return (
        <Redirect
          to={{ pathname: state.referrer, state: { referrer: '' } }}
        ></Redirect>
      );
    }
    if (user.onboardingStep === "background_check") {
      return <Redirect to={'/dashboard'}></Redirect>;
    }
    return <Redirect to={'/client/onboarding/step1'}></Redirect>;
  }

  return <>{children}</>;
};

export default CannotBeLoggedInGuard;
