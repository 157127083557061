import { useEffect, useState } from 'react';
import { RootState } from 'app/store/rootReducer';
import useInterval from 'app/core/hooks/useInterval';
import { useDispatch, useSelector } from 'react-redux';
import { checkUnreadThreadsMessages } from 'app/store/messaging';

const POLLING_DELAY = 1000 * 10;

export default function FetchThreads() {
  const dispatch = useDispatch();

  const { isMessagingPageOn } = useSelector(
    (state: RootState) => state.messaging
  );

  const { user } = useSelector((state: RootState) => state.auth);

  const [delay, setDelay] = useState<number | null>(null);

  useEffect(() => {
    if (user) {
      dispatch(checkUnreadThreadsMessages());
    }
  }, [user]);

  useEffect(() => {
    if (isMessagingPageOn || !user) {
      setDelay(null);
    } else {
      setDelay(POLLING_DELAY);
    }
  }, [isMessagingPageOn, user]);

  useInterval(() => {
    dispatch(checkUnreadThreadsMessages());
  }, delay);

  return null;
}
