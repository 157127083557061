import React, { useState } from 'react';
import { Box, LinearProgress } from '@material-ui/core';
import useTimeout from '../../core/hooks/useTimeout';

interface AppLoadingProps {
  delay: number;
}
const AppLoading = ({ delay }: AppLoadingProps) => {
  const [showLoading, setShowLoading] = useState<boolean>(!delay);

  useTimeout(() => {
    setShowLoading(true);
  }, delay);

  if (!showLoading) {
    return null;
  }

  return (
    <Box>
      <LinearProgress color="secondary" />
    </Box>
  );
};

export default AppLoading;
