import React from 'react';
import {
  Button,
  Hidden,
  Toolbar,
  Box,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import NavbarMobileToggleButton from './NavbarMobileToggleButton';
import UserMenu from './UserMenu';
import ToolbarLogo from './ToolbarLogo';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/rootReducer';
import { Link } from 'react-router-dom';
import SearchHeartIcon from 'app/ui/icons/SearchHeartIcon';
import MessagesBell from 'app/ui/modules/messaging/components/messages-bell/MessagesBell';
import {
  ifClientDuringOnboarding,
  ifClientApproved,
} from 'app/core/guards/rules/clientRules';
import {
  ifHeroDuringOnboarding,
} from 'app/core/guards/rules/heroRules';
import PhoneIcon from 'app/ui/icons/PhoneIcon';

const ToolbarLogged: React.FunctionComponent = () => {
  const { isWithoutSidebar } = useSelector((state: RootState) => state.ui);
  const { user } = useSelector((state: RootState) => state.auth);

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const matches = useMediaQuery(theme.breakpoints.down(840), {
    noSsr: true,
  });

  let messagesDisabled = true;
  if (user.roles.includes('client')) {
    messagesDisabled =
      !ifClientDuringOnboarding(user) || !ifClientApproved(user);
  } else {
    messagesDisabled = ifHeroDuringOnboarding(user);
  }

  return (
    <Toolbar disableGutters>
      {!isWithoutSidebar && (
        <Hidden mdUp>
          <Box ml="-12px">
            <NavbarMobileToggleButton />
          </Box>
        </Hidden>
      )}
      <ToolbarLogo logged={true} />
      <Hidden smDown>
        <Box
          display="flex"
          alignItems="center"
          fontSize={matches ? "15px" : "16px"}
          fontWeight="600"
          ml={matches ? 2 : 4}
        >
          <PhoneIcon />
          <Box ml={0.5}>+1 (234) 567-8911</Box>
        </Box>
      </Hidden>
      <Box flexGrow="1" />
      {user.roles.includes('client') &&
        window.location.href.indexOf('onboarding') === -1 &&
        window.location.href.indexOf('job-create') === -1 && (
          <Box mr={1}>
            <Button
              id="find-your-hero"
              variant="contained"
              color="secondary"
              disableElevation
              component={Link}
              to={
                window.location.href.indexOf('job-create') === -1
                  ? `/job-create`
                  : '#'
              }
            >
              <SearchHeartIcon />
              {!matchesMd && <Box ml={1}>Find Your Hero</Box>}
            </Button>
          </Box>
        )}

      {!messagesDisabled && (
        <MessagesBell
          userRole={user.roles.includes('client') ? 'client' : 'hero'}
        />
      )}
      <UserMenu />
    </Toolbar>
  );
};

export default ToolbarLogged;
