import Axios from 'axios';
import { prepareRequestData } from './../helpers/prepareRequestData';

export const registerRequestInterceptor = () => {
  Axios.interceptors.request.use(
    function (config) {
      return { ...config, data: prepareRequestData(config.data) };
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};
