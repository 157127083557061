import React from 'react';
import { RootState } from 'app/store/rootReducer';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

interface ClientEnrollStep3GuardProps {
  children: React.ReactNode;
}

export const ClientEnrollStep3Guard = (
  props: ClientEnrollStep3GuardProps
) => {
  const { children } = props;

  const { visitor } = useSelector((state: RootState) => state.visitor);

  if (!visitor || !visitor.selectedCareType) {
    return <Redirect to={'/visitor/client-enroll/care-type'}></Redirect>;
  }

  if (!visitor.locatedLat || !visitor.locatedLng) {
    return <Redirect to={'/visitor/client-enroll/location'}></Redirect>;
  }

  return <>{children}</>;
};

export default ClientEnrollStep3Guard;
