import { AppNS } from 'app';
export const HERO_LANGUAGES_LEVELS = ['Learning', 'Conversational', 'Fluent'];

export const CARE_TYPE_YEARS_OF_EXPERIENCE = [
  {
    value: 0,
    label: '1 year',
  },
  {
    value: 1,
    label: '2 - 5 years',
  },
  {
    value: 2,
    label: '6 - 10 years',
  },
  {
    value: 3,
    label: '11 - 20 years',
  },
  {
    value: 4,
    label: 'Over 20 years',
  },
] as AppNS.CareTypeExperienceTime[];

export const CARE_TYPES = [
  { name: 'Adult/Elder Care', id: 'adultElderCare', slug: 'adult-care' },
  { name: 'Childcare', id: 'childCare', slug: 'child-care' },
  {
    name: 'Special Needs Care',
    id: 'specialNeedsCare',
    slug: 'special-needs-care',
  },
  { name: 'Infant Care', id: 'infantCare', slug: 'infant-care' },
] as AppNS.CareTypeType[];

export const CARE_TYPES_EXPERIENCE = [
  { name: 'Blind', id: 'icBlind', careTypeId: 'infantCare' },
  { name: 'Colick', id: 'icColick', careTypeId: 'infantCare' },
  { name: 'CPAP', id: 'icCPAP', careTypeId: 'infantCare' },
  { name: 'Deaf', id: 'icDeaf', careTypeId: 'infantCare' },
  { name: 'Diabetes', id: 'icDiabetes', careTypeId: 'infantCare' },
  { name: 'Food Allergies', id: 'icFoodAlergies', careTypeId: 'infantCare' },
  {
    name: 'Lactation Support',
    id: 'icLactationSupport',
    careTypeId: 'infantCare',
  },
  { name: 'Oral Issues', id: 'icOralIssues', careTypeId: 'infantCare' },
  { name: 'Potty Training', id: 'icPottyTraining', careTypeId: 'infantCare' },
  { name: 'Premies', id: 'icPremies', careTypeId: 'infantCare' },
  { name: 'Sensory Issues', id: 'icSensoryIssues', careTypeId: 'infantCare' },
  { name: 'Twins/Multiples', id: 'icTwinsMultiples', careTypeId: 'infantCare' },
  { name: 'Vision Issues', id: 'icVisionIssues', careTypeId: 'infantCare' },
  { name: 'ADHD', id: 'ccADHD', careTypeId: 'childCare' },
  { name: 'ADD', id: 'ccADD', careTypeId: 'childCare' },
  { name: 'Anxiety', id: 'ccAnxiety', careTypeId: 'childCare' },
  {
    name: "Asperger's Syndrome",
    id: 'ccAspergersSyndrome',
    careTypeId: 'childCare',
  },
  { name: 'Asthma', id: 'ccAsthma', careTypeId: 'childCare' },
  { name: 'Autism', id: 'ccAutism', careTypeId: 'childCare' },
  { name: 'Blind', id: 'ccBlind', careTypeId: 'childCare' },
  { name: 'CPAP', id: 'ccCPAP', careTypeId: 'childCare' },
  { name: 'Deaf', id: 'ccDeaf', careTypeId: 'childCare' },
  { name: 'Depression', id: 'ccDepression', careTypeId: 'childCare' },
  { name: 'Diabetes', id: 'ccDiabetes', careTypeId: 'childCare' },
  { name: 'Downs Syndrome', id: 'ccDownsSyndrome', careTypeId: 'childCare' },
  { name: 'Dyslexia', id: 'ccDyslexia', careTypeId: 'childCare' },
  { name: 'Epilepsy', id: 'ccEpilepsy', careTypeId: 'childCare' },
  { name: 'Food Allergies', id: 'ccFoodAlergies', careTypeId: 'childCare' },
  {
    name: 'Hearing Impairment',
    id: 'ccHearingImpairment',
    careTypeId: 'childCare',
  },
  { name: 'Potty Training', id: 'ccPottyTraining', careTypeId: 'childCare' },
  {
    name: 'Processing Disorders',
    id: 'ccProcessingDisorders',
    careTypeId: 'childCare',
  },
  { name: 'Sensory Issues', id: 'ccSensoryIssues', careTypeId: 'childCare' },
  { name: 'Speech Issues', id: 'ccSpeechIssues', careTypeId: 'childCare' },
  {
    name: "Tourette Syndrome",
    id: 'ccTouretteSyndrome',
    careTypeId: 'childCare',
  },
  { name: 'Twins/Multiples', id: 'ccTwinsMultiples', careTypeId: 'childCare' },
  { name: 'ADHD', id: 'sncADHD', careTypeId: 'specialNeedsCare' },
  { name: 'ADD', id: 'sncADD', careTypeId: 'specialNeedsCare' },
  { name: 'Anxiety', id: 'sncAnxiety', careTypeId: 'specialNeedsCare' },
  { name: 'Asthma', id: 'sncAsthma', careTypeId: 'specialNeedsCare' },
  { name: 'Autism', id: 'sncAutism', careTypeId: 'specialNeedsCare' },
  { name: 'Bi-polar', id: 'sncBi-polar', careTypeId: 'specialNeedsCare' },
  { name: 'Blind', id: 'sncBlind', careTypeId: 'specialNeedsCare' },
  { name: 'Catheter Care', id: 'sncCatheterCare', careTypeId: 'specialNeedsCare' },
  { name: 'Cerebral Palsy', id: 'sncCerebralPalsy', careTypeId: 'specialNeedsCare' },
  { name: 'CPAP', id: 'sncCPAP', careTypeId: 'specialNeedsCare' },
  { name: 'Deaf', id: 'sncDeaf', careTypeId: 'specialNeedsCare' },
  { name: 'Depression', id: 'sncDepression', careTypeId: 'specialNeedsCare' },
  {
    name: 'Down Syndrome',
    id: 'sncDownsSyndrome',
    careTypeId: 'specialNeedsCare',
  },
  { name: 'Dyslexia', id: 'sncDyslexia', careTypeId: 'specialNeedsCare' },
  { name: 'Epilepsy', id: 'sncEpilepsy', careTypeId: 'specialNeedsCare' },
  {
    name: 'Food Allergies',
    id: 'sncFoodAlergies',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Hearing Impairment',
    id: 'sncHearingImpairment',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Multiple Sclerosis',
    id: 'sncMultipleSclarosis',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Muscular dystrophy',
    id: 'sncMuscsularDystrophy',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Oppositional Defiance Disorder',
    id: 'sncOppositionalDefianceDisorder',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Potty Training',
    id: 'sncPottyTraining',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Processing Disorders',
    id: 'sncProcessingDisorders',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Sensory Issues',
    id: 'sncSensoryIssues',
    careTypeId: 'specialNeedsCare',
  },
  { name: 'Tube Feeding', id: 'sncTubeFeeding', careTypeId: 'specialNeedsCare' },
  { name: 'Twins/Multiples', id: 'sncTwinsMultiples', careTypeId: 'specialNeedsCare' },

  { name: 'ADHD', id: 'aecADHD', careTypeId: 'adultElderCare' },
  { name: 'ADD', id: 'aecADD', careTypeId: 'adultElderCare' },
  {
    name: "Alzheimer's Disease",
    id: 'aecAlzheimerDisease',
    careTypeId: 'adultElderCare',
  },
  { name: 'Anxiety', id: 'aecAnxiety', careTypeId: 'adultElderCare' },
  { name: 'Asthma', id: 'aecAsthma', careTypeId: 'adultElderCare' },
  { name: 'Bi-polar', id: 'aecBi-polar', careTypeId: 'adultElderCare' },
  { name: 'Blind', id: 'aecBlind', careTypeId: 'adultElderCare' },
  {
    name: 'Cardiovascular Disease',
    id: 'aecCardiovascularDisease',
    careTypeId: 'adultElderCare',
  },
  { name: 'Catheter Care', id: 'aecCatheterCare', careTypeId: 'adultElderCare' },
  { name: 'CPAP', id: 'aecCPAP', careTypeId: 'adultElderCare' },
  { name: 'Deaf', id: 'aecDeaf', careTypeId: 'adultElderCare' },
  { name: 'Depression', id: 'aecDepression', careTypeId: 'adultElderCare' },
  { name: 'Diabetes', id: 'aecDiabetes', careTypeId: 'adultElderCare' },
  { name: 'Dementia', id: 'aecDementia', careTypeId: 'adultElderCare' },
  { name: 'Dyslexia', id: 'aecDyslexia', careTypeId: 'adultElderCare' },
  { name: 'Epilepsy', id: 'aecEpilepsy', careTypeId: 'adultElderCare' },
  {
    name: 'Food Allergies',
    id: 'aecFoodAlergies',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Hearing Impairment',
    id: 'aecHearing Impairment',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Kidney/Bladder Problems',
    id: 'aecKidneyBladderProblems',
    careTypeId: 'adultElderCare',
  },
  {
    name: "Lou Gehrig's Disease",
    id: 'aecLouGehrigsDisease',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'LVAD Care',
    id: 'aecLVADCare',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Macular Degeneration',
    id: 'aecMuscsularDegeneration',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Memory Loss',
    id: 'aecMemoryLoss',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Multiple Sclerosis',
    id: 'aecMultipleSclarosis',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Muscular Dystrophy',
    id: 'aecMuscsularDystrophy',
    careTypeId: 'adultElderCare',
  },
  {
    name: "Parkinson's Disease",
    id: 'aecParkinsonDisease',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Processing Disorders',
    id: 'aecProcessingDisorders',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Sensory Issues',
    id: 'aecSensoryIssues',
    careTypeId: 'adultElderCare',
  },
  { name: 'Sundowners syndrome', id: 'aecSundownersSyndrome', careTypeId: 'adultElderCare' },
  {
    name: 'Tube Feeding',
    id: 'aecTubeFeeding',
    careTypeId: 'adultElderCare',
  },
] as AppNS.CareTypeService[];

export const CARE_TYPES_SERVICES = [
  { name: "Bottle Feeding", id: 'icBottleFeeding', careTypeId: 'infantCare' },
  { name: "Diaper Changes", id: 'icDiaperChanges', careTypeId: 'infantCare' },
  { name: "Doctor's Visits", id: 'icDoctorVisits', careTypeId: 'infantCare' },
  { name: 'Doula', id: 'icDoula', careTypeId: 'infantCare' },
  { name: 'House Cleaning', id: 'icHouseCleaning', careTypeId: 'infantCare' },
  { name: 'Laundry', id: 'icLaundry', careTypeId: 'infantCare' },
  {
    name: 'Light Housekeeping',
    id: 'icLightHousekeeping',
    careTypeId: 'infantCare',
  },
  {
    name: 'Meal Preparation',
    id: 'icMealPreparation',
    careTypeId: 'infantCare',
  },
  { name: 'Night Nurse', id: 'icNightNurse', careTypeId: 'infantCare' },
  { name: 'Pet Care', id: 'icPetCare', careTypeId: 'infantCare' },
  { name: 'Transportation', id: 'icTransportation', careTypeId: 'infantCare' },

  { name: "Doctor's Visits", id: 'ccDoctorVisits', careTypeId: 'childCare' },
  { name: 'Homework Help', id: 'ccHomeworkHelp', careTypeId: 'childCare' },
  {
    name: 'Errands/excursions',
    id: 'ccErrandsExcursions',
    careTypeId: 'childCare',
  },
  { name: 'House Cleaning', id: 'ccHouseCleaning', careTypeId: 'childCare' },
  { name: 'Laundry', id: 'ccLaundry', careTypeId: 'childCare' },
  {
    name: 'Light Housekeeping',
    id: 'ccLightHousekeeping',
    careTypeId: 'childCare',
  },
  {
    name: 'Meal Preparation',
    id: 'ccMealPreparation',
    careTypeId: 'childCare',
  },
  { name: 'Pet Care', id: 'ccPetCare', careTypeId: 'childCare' },
  {
    name: 'Recreational Activities',
    id: 'ccRecreationalActivities',
    careTypeId: 'childCare',
  },
  {
    name: 'Socialization/Entertainment',
    id: 'ccSocializationEntertainment',
    careTypeId: 'childCare',
  },
  { name: 'Transportation', id: 'ccTransportation', careTypeId: 'childCare' },
  { name: 'Tutoring', id: 'ccTutoring', careTypeId: 'childCare' },
  {
    name: 'Virtual Learning Assistance',
    id: 'ccVirtualLearningAssistance',
    careTypeId: 'childCare',
  },

  {
    name: 'Bathing/Dressing',
    id: 'sncBathingDressing',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Catheter Care',
    id: 'sncCatheterCare',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Companionship',
    id: 'sncCompanionship',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: "Doctor's Visits",
    id: 'sncDoctorVisits',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Homework Help',
    id: 'sncHomeworkHelp',
    careTypeId: 'specialNeedsCare',
  },
  { name: 'House Cleaning', id: 'sncHouseCleaning', careTypeId: 'specialNeedsCare' },
  {
    name: 'Errands/excursions',
    id: 'sncErrandsExcursions',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Incontinence Care',
    id: 'sncIncontienceCare',
    careTypeId: 'specialNeedsCare',
  },
  { name: 'Laundry', id: 'sncLaundry', careTypeId: 'specialNeedsCare' },
  {
    name: 'Light Housekeeping',
    id: 'sncLightHousekeeping',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Meal Preparation',
    id: 'sncMealPreparation',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Medication Reminders',
    id: 'sncMedicationReminders',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Oral Hygiene',
    id: 'sncOralHygiene',
    careTypeId: 'specialNeedsCare',
  },
  { name: 'Pet Care', id: 'sncPetCare', careTypeId: 'specialNeedsCare' },
  {
    name: 'Recreational Activities',
    id: 'sncRecreationalActivities',
    careTypeId: 'specialNeedsCare',
  },
  { name: 'Toileting', id: 'sncToileting', careTypeId: 'specialNeedsCare' },
  {
    name: 'Safety/Fall Protection',
    id: 'sncSafetyFallProtection',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Socialization/Entertainment',
    id: 'sncSocializationEntertainment',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Transferring/Positioning',
    id: 'sncTransferringPositioning',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Transportation',
    id: 'sncTransportation',
    careTypeId: 'specialNeedsCare',
  },
  { name: 'Tutoring', id: 'sncTutoring', careTypeId: 'specialNeedsCare' },
  {
    name: 'Walking/Mobility',
    id: 'sncWalkingMobility',
    careTypeId: 'specialNeedsCare',
  },
  {
    name: 'Virtual Learning Assistance',
    id: 'sncVirtualLearningAssistance',
    careTypeId: 'specialNeedsCare',
  },

  {
    name: 'Bathing/Dressing',
    id: 'aecBathingDressing',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Catheter Care',
    id: 'aecCatheterCare',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Companionship',
    id: 'aecCompanionship',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Doctors Visits',
    id: 'aecDoctorVisits',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Errands/excursions',
    id: 'aecErrandsExcursions',
    careTypeId: 'adultElderCare',
  },
  { name: 'House Cleaning', id: 'aecHouseCleaning', careTypeId: 'adultElderCare' },
  {
    name: 'Incontience Care',
    id: 'aecIncontienceCare',
    careTypeId: 'adultElderCare',
  },
  { name: 'Laundry', id: 'aecLaundry', careTypeId: 'adultElderCare' },
  {
    name: 'Light Housekeeping',
    id: 'aecLightHousekeeping',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Meal Preparation',
    id: 'aecMealPreparation',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Medication Reminders',
    id: 'aecMedicationReminders',
    careTypeId: 'adultElderCare',
  },
  { name: 'Memory Care', id: 'aecMemoryCare', careTypeId: 'adultElderCare' },
  { name: 'Oral Hygiene', id: 'aecOralHygiene', careTypeId: 'adultElderCare' },
  { name: 'Pet Care', id: 'aecPetCare', careTypeId: 'adultElderCare' },
  {
    name: 'Recreational Activities',
    id: 'aecRecreationalActivities',
    careTypeId: 'adultElderCare',
  },
  { name: 'Toileting', id: 'aecToileting', careTypeId: 'adultElderCare' },
  {
    name: 'Safety/Fall Protection',
    id: 'aecSafetyFallProtection',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Socialization/Entertainment',
    id: 'aecSocializationEntertainment',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Transferring/Positioning',
    id: 'aecTransferringPositioning',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Transportation',
    id: 'aecTransportation',
    careTypeId: 'adultElderCare',
  },
  {
    name: 'Walking/Mobility',
    id: 'aecWalkingMobility',
    careTypeId: 'adultElderCare',
  },
  { name: 'Wound Care', id: 'aecWoundCare', careTypeId: 'adultElderCare' },
] as AppNS.CareTypeExperience[];

export const CARE_TYPE_PROFESSIONAL_TITLE = [
  {
    name: 'Applied Behavior Analyst (ABA)',
    id: 'appliedBehaviorAnalyst'
  },
  {
    name: 'American Sign Language Teacher (ASL)',
    id: 'aSLTeacher'
  },
  {
    name: 'Autism Specialist',
    id: 'autismSpecialist'
  },
  {
    name: 'Board Certified Behavior Analyst (BCBA)',
    id: 'boardCertifiedBehaviorAnalyst',
  },
  {
    name: 'Behavior Analyst Student',
    id: 'behaviorAnalystStudent'
  },
  {
    name: 'Behavior Therapist',
    id: 'behaviorTherapist'
  },
  {
    name: 'Certified Nurse Practitioner (CNP)',
    id: 'certifiedNursePractitionerCNP',
  },
  {
    name: 'Certified Nursing Assistant (CNA)',
    id: 'certifiedNursingAssistantCNA',
  },
  {
    name: 'Daycare Owner/Administrator',
    id: 'daycareOwnerAdministrator'
  },
  { name: 'Dialysis Technician (DT)',
    id: 'dialysisTechnicianDT'
  },
  {
    name: 'Direct Support Professional (DSP)',
    id: 'directSupportProfessionalDSP',
  },
  {
    name: 'Disability Advocate',
    id: 'disabilityAdvocate'
  },
  { name: 'Doula', id: 'doula' },
  {
    name: 'Educational Aide',
    id: 'teachersAide'
  },
  { name: 'Education Student', id: 'educationStudent' },
  {
    name: 'Emergency Medical Technician (EMT)',
    id: 'emergencyMedicalTechnicianEMT',
  },
  { name: 'Firefighter', id: 'firefighter' },
  { name: 'Home Health Aide', id: 'homeHealthAide' },
  {
    name: 'Interdisciplinary Aide',
    id: 'interdisciplinaryAide'
  },
  { name: 'Licensed Practical Nurse (LPN)', id: 'licensedPracticalNurseLPN' },
  {
    name: 'Licensed Social Worker (LSW/LISW)',
    id: 'licensedSocialWorkerLSWLISW',
  },
  { name: 'Medical Assistant', id: 'medicalAssistant' },
  { name: 'Medical Student', id: 'medicalStudent' },
  { name: "Nurse's Assistant/Aide", id: 'nursesAssistantAide' },
  { name: 'Nanny/Au Pair', id: 'nannyAuPair' },
  { name: 'Nursing Student', id: 'nursingStudent' },
  { name: 'Occupational Therapist (OT)', id: 'occupationalTherapistOT' },
  { name: 'Occupational Therapist Assistant (OTA)',
    id: 'occupationalTherapistAssistant' },
  {
    name: 'Occupational Therapy Student',
    id: 'occupationalTherapistStudent',
  },
  { name: 'Paraprofessional', id: 'paraprofessional' },
  { name: 'Personal Care Assistant (PCA)', id: 'personalCareAssistantPCA' },
  { name: 'Physical Therapist (PT)', id: 'physicalTherapistPT' },
  { name: 'Physical Therapy Student', id: 'physicalTherapistStudent' },
  { name: 'Physician Assistant (PA)', id: 'physiciansAssistantPA' },
  { name: 'Physician Assistant Student', id: 'physiciansAssistantStudent' },
  { name: 'Police Officer', id: 'policeOfficer' },
  { name: 'Preschool Teacher', id: 'preschoolTeacher' },
  { name: "Preschool Teacher's Aide", id: 'preschoolTeachersAide' },
  {
    name: 'Registered Behavior Technician (RBT)',
    id: 'registeredBehaviorTechnicianRBT',
  },
  { name: 'Registered Nurse (RN)', id: 'registeredNurseRN' },
  { name: 'School Psychologist', id: 'schoolPsychologist'},
  { name: 'Speech Pathologist', id: 'speechPathologist' },
  { name: 'Speech Pathology Student', id: 'speechPathologyStudent' },
  { name: 'Speech Therapist (SLP)', id: 'speechTherapist' },
  { name: 'Speech Therapy Student', id: 'speechTherapyStudent' },
  {
    name: 'State Tested Nursing Assistant (STNA)',
    id: 'stateTestedNursingAssistantSTNA',
  },
  { name: 'Teacher', id: 'teacher' },
  { name: "Teacher's Assistant", id: 'teachersAssistant' },
  {
    name: 'Therapeutic Program Worker (TPW)',
    id: 'therapeuticProgramWorkerTPW',
  },
  { name: 'Tutor', id: 'tutor' },
] as AppNS.CareTypeTitle[];
