import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from 'app/store';
import { SplashScreen } from 'app/ui/components/SplashScreen';
import useLoggedStatus from '../hooks/useLoggedStatus';
import { logoutUser } from './../../store/auth/thunks/logout';

interface HasToBeLoggedInGuardProps {
  children: React.ReactNode;
}

export const HasToBeLoggedInGuard = (props: HasToBeLoggedInGuardProps) => {
  const { children } = props;
  const { pathname } = useLocation();
  const user = useSelector(({ auth }: AppState) => auth.user);
  const waitAuthCheck = useLoggedStatus(user);
  const dispatch = useDispatch();

  if (waitAuthCheck) {
    return <SplashScreen />;
  }

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { referrer: pathname },
        }}
      ></Redirect>
    );
  }

  if (
    user.approvalStatus === 'failed' ||
    user.deactivationReason === 'banned_by_admin'
  ) {
    dispatch(logoutUser(true));

    return (
      <Redirect
        to={{
          pathname: '/unauthorized',
          state: { referrer: pathname },
        }}
      ></Redirect>
    );
  }

  return <>{children}</>;
};

export default HasToBeLoggedInGuard;
