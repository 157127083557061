import React from 'react';
import { AppNS } from 'app';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation, matchPath } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    textAlign: 'left',
    width: '100%',
    fontSize: '1.6rem',
    lineHeight: `${theme.spacing(6)}px`,
    height: theme.spacing(6),
    borderRadius: '30px 0 0 30px',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '& .iconContainer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
      color: theme.palette.info.light,
    },
    '&:hover, &.active': {
      background: theme.palette.grey[200],
      color: theme.palette.primary.dark,
      '& .iconContainer': {
        color: theme.palette.primary.dark,
      },
    },
    '&:hover:after': {
      position: 'absolute',
      right: 0,
      top: 0,
      content: '""',
      display: 'block',
      height: '100%',
      width: theme.spacing(0.5),
      background: theme.palette.secondary.main,
      borderRadius: theme.shape.borderRadius,
    },
    '&[aria-disabled="true"]': {
      opacity: 0.5,
    },
  },
}));

const checkMatch = (pathname: string, pathToCheck: string) => {
  return matchPath(pathname, {
    path: pathToCheck,
    exact: false,
    strict: false,
  });
};

const getPathMatch = (
  pathname: string,
  pathToCheck: string | Array<string>
): boolean => {
  if (typeof pathToCheck === 'string') {
    return checkMatch(pathname, pathToCheck) !== null;
  } else {
    return pathToCheck.some((path: string) => {
      return checkMatch(pathname, path) !== null;
    });
  }
};

interface NavigationElementProps {
  element: AppNS.NavigationElement;
}
const NavigationElement: React.FunctionComponent<NavigationElementProps> = (
  props
) => {
  const { element } = props;
  const classes = useStyles();

  const { pathname } = useLocation();

  const match = getPathMatch(pathname, element.path);

  if (element?.isExternal) {
    return (
      <a
        className={classes.root}
        target="_blank"
        rel="noopener noreferrer"
        href={element.url}
      >
        <span className="iconContainer">{element.icon}</span>
        {element.name}
      </a>
    );
  }
  return (
    <ButtonBase
      component={RouterLink}
      to={element.url}
      className={clsx(classes.root, match && 'active')}
      disabled={element.disabled}
    >
      <span className="iconContainer">{element.icon}</span>
      {element.name}
    </ButtonBase>
  );
};

export default NavigationElement;
