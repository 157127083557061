export interface HoursFormValues {
  from: string;
  to: string;
  autoBid: boolean;
}

export interface FormValues {
  isOn: boolean;
  hours: Array<HoursFormValues>;
  overnight: boolean;
  disabled: boolean;
}

export const defaultValues: FormValues = {
  isOn: false,
  hours: [
    {
      from: '8:00',
      to: '0:00',
      autoBid: false,
    },
  ],
  overnight: false,
  disabled: false,
};
