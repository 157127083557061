import axios, { Canceler } from 'axios';

import { API_URL } from '../../core/config';
import { ApiNS } from '../api';

const FAVORITES_URL = 'user_favourites';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiAddToFavorites(
  heroId: string,
  careType: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${FAVORITES_URL}`, {
        properties: { heroId, careType },
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiRemoveFromFavorites(
  heroId: string,
  careTypeId: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/${FAVORITES_URL}`, {
        params: { hero_id: heroId, care_type: careTypeId },
      })
      .then(({ data }) => {
        if (data) {
          if (data.model) {
            resolve(data.model.id);
          }
          else {
            resolve(data.result);
          }
        } else{
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetFavorites(
  care_type: string,
  page: number,
  per_page: number
): Promise<ApiNS.FavoritesListResponse> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${FAVORITES_URL}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          care_type,
          page,
          per_page,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
