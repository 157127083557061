import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function ResourcesIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <g>
        <path
          d="M58,17h-1V1H7v16H6c-2.757,0-5,2.243-5,5v30c0,2.757,2.243,5,5,5h13v4h-4v2h34v-2h-4v-4h13c2.757,0,5-2.243,5-5V22   C63,19.243,60.757,17,58,17z M9,11h46v32H9V11z M55,3v6H9V3H55z M6,19h1v26h50V19h1c1.654,0,3,1.346,3,3v27H3V22   C3,20.346,4.346,19,6,19z M43,61H21v-4h22V61z M58,55H6c-1.654,0-3-1.346-3-3v-1h58v1C61,53.654,59.654,55,58,55z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.531,15h-1.062C28.454,15,26,17.454,26,20.469V22h2v-1.531C28,18.556,29.556,17,31.469,17h1.062   C34.444,17,36,18.556,36,20.469c0,1.212-0.646,2.352-1.685,2.975L31,25.434V31h2v-4.434l2.345-1.407   c1.638-0.982,2.655-2.78,2.655-4.69C38,17.454,35.546,15,32.531,15z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32,33c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S33.654,33,32,33z M32,37c-0.551,0-1-0.449-1-1s0.449-1,1-1   s1,0.449,1,1S32.551,37,32,37z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
