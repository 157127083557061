import React from 'react';
import { RootState } from 'app/store/rootReducer';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

interface ClientEnrollStep4GuardProps {
  children: React.ReactNode;
}

export const ClientEnrollStep4Guard = (
  props: ClientEnrollStep4GuardProps
) => {
  const { children } = props;

  const { visitor } = useSelector((state: RootState) => state.visitor);

  if (!visitor || !visitor.selectedCareType) {
    return <Redirect to={'/visitor/client-enroll/care-type'}></Redirect>;
  }

  if (!visitor.locatedLat || !visitor.locatedLng) {
    return <Redirect to={'/visitor/client-enroll/location'}></Redirect>;
  }

  if (!visitor.selectedTimeframe) {
    return <Redirect to={'/visitor/client-enroll/timing'}></Redirect>;
  }

  return <>{children}</>;
};

export default ClientEnrollStep4Guard;
