import { AppNS } from 'app';
import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const PERSONAL_DETAILS_REDUCERS = {
  setPersonalDataStart(state: AuthState) {
    state.isChangingPersonalData = true;
    state.isChangingPersonalDataError = false;
  },
  setPersonalDataFailed(state: AuthState) {
    state.isChangingPersonalDataError = true;
    state.isChangingPersonalData = false;
  },
  setPersonalDataSuccess(
    state: AuthState,
    action: PayloadAction<AppNS.Params>
  ) {
    state.isChangingPersonalData = false;
    state.isChangingPersonalDataError = false;

    if (state.user) {
      state.user.approvalStatus = action.payload.properties.approvalStatus;
      state.user.addresses = action.payload.addresses;
      state.user = {
        ...state.user,
        ...action.payload.properties,
      }
    }
  },
};
