import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function ProfileIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 24" fill="none">
      <g fill="none">
        <path
          d="M20.9624 13.5V20.25C20.9624 21.9069 19.5185 23.25 17.7374 23.25H8.0624C6.28128 23.25 4.8374 21.9069 4.8374 20.25V3.75C4.8374 2.09315 6.28128 0.75 8.0624 0.75H17.7374C19.5185 0.75 20.9624 2.09315 20.9624 3.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.8374 18.75H20.9624"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0938 12.75C13.6523 12.75 14.9157 11.5747 14.9157 10.125C14.9157 8.67525 13.6523 7.5 12.0938 7.5C10.5354 7.5 9.27197 8.67525 9.27197 10.125C9.27197 11.5747 10.5354 12.75 12.0938 12.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.125 15.75C15.331 14.3651 13.781 13.5 12.0938 13.5C10.4065 13.5 8.85652 14.3651 8.0625 15.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3501 7.5V10.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7373 9H20.9623"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
