import React, { useState } from 'react';
import {
  MenuItem,
  IconButton,
  Menu,
  CircularProgress,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import MenuHorizontalIcon from 'app/ui/icons/MenuHorizontalIcon';
import { getLoginAsAccessToken } from 'app/services/jwtService';
import { logoutUser } from 'app/store/auth/thunks/logout';

const UserMenu: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const { user, isLoggingOut } = useSelector((state: RootState) => state.auth);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return null;
  }

  const loginAsToken = getLoginAsAccessToken();

  return (
    <>
      {isLoggingOut && <CircularProgress color="secondary" size={20} />}

      {!isLoggingOut && (
        <>
          <IconButton aria-label="Menu" onClick={handleClick}>
            <MenuHorizontalIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              handleClose();
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                dispatch(logoutUser());
              }}
            >
              {loginAsToken && <>Logout and back to Admin Panel</>}
              {!loginAsToken && <>Logout</>}
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default UserMenu;
