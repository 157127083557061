import { AppNS } from 'app';
import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const LAST_SEARCH_REDUCERS = {
  getLastSearchStart(state: AuthState) {
    state.isLoadingLastSearch = true;
    state.isLoadingLastSearchError = false;
  },
  getLastSearchFailed(state: AuthState) {
    state.isLoadingLastSearch = false;
    state.isLoadingLastSearchError = true;
  },
  getLastSearchSuccess(
    state: AuthState,
    action: PayloadAction<AppNS.Params>
  ) {
    state.isLoadingLastSearch = false;
    state.isLoadingLastSearchError = false;
    state.userLastSearch = action.payload;
  },
}
