import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function SearchHeartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5149 17.5645C17.1881 16.0035 18.9003 11.7603 17.3392 8.08712C15.7782 4.41395 11.535 2.70174 7.86181 4.2628C4.18863 5.82386 2.47643 10.0671 4.03749 13.7402C5.59855 17.4134 9.84174 19.1256 13.5149 17.5645Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.2378 16.2371L21.4445 21.4445"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.753 14.4998C10.5661 14.2781 10.3554 14.0646 10.1151 13.8538C9.8837 13.6513 9.64043 13.4651 9.40605 13.2844C9.25771 13.1695 9.10641 13.0545 8.95807 12.9395C8.80676 12.8246 8.65843 12.7096 8.50712 12.5946C8.20748 12.362 7.97607 12.1649 7.78323 11.9732C7.60522 11.7953 7.46282 11.6311 7.34711 11.4723C7.11274 11.1466 7 10.7962 7 10.3993C7 10.3171 7.0089 10.2295 7.02373 10.1365C7.06824 9.86821 7.16911 9.62185 7.32041 9.40012C7.50435 9.13459 7.73576 8.94298 8.0265 8.81432C8.24011 8.72125 8.47152 8.67472 8.73556 8.6665C8.7593 8.6665 8.786 8.6665 8.80973 8.6665C8.9462 8.6665 9.07971 8.67745 9.21025 8.70209C9.58703 8.76779 9.89557 8.92108 10.1507 9.16744C10.2991 9.30979 10.4148 9.47129 10.4978 9.64922L10.75 10.1912L11.0022 9.64922C11.0852 9.47403 11.2009 9.31252 11.3493 9.16744C11.6074 8.91834 11.913 8.76779 12.2898 8.70209C12.4203 8.68019 12.5568 8.6665 12.6903 8.6665C12.714 8.6665 12.7407 8.6665 12.7644 8.6665C13.0285 8.67472 13.2629 8.72399 13.4735 8.81432C13.7642 8.94024 13.9956 9.13186 14.1796 9.40012C14.3309 9.62185 14.4318 9.86821 14.4763 10.1365C14.4911 10.2295 14.5 10.3171 14.5 10.3993C14.5 10.7962 14.3873 11.1466 14.1559 11.4696C14.0402 11.6311 13.8977 11.7953 13.7197 11.9705C13.5239 12.1621 13.2955 12.362 12.9958 12.5919C12.8475 12.7069 12.6962 12.8218 12.5449 12.9368C12.3966 13.0518 12.2453 13.1667 12.0969 13.2817C11.8625 13.4624 11.6193 13.6513 11.3879 13.8511C11.1505 14.0646 10.9399 14.2781 10.753 14.4998Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
