import { AppNS } from '..';
import axios from 'axios';
import moment from 'moment';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiNS } from 'app/api/api';
import {
  apiAddReportImage,
  apiFinalizeReport,
  apiDeleteReportImage,
  apiGetReport,
  apiUpdateReportChecklistItem,
  apiUpdateReportHeroNotes,
  apiUpdateTime,
  apiPausePaymentRequest,
} from '../api/hero-reports/hero-reports';
import {
  getTimeFromData,
  getTimeToData,
} from './../api/hero-reports/hero-reports-parsers';
import { parseTimeTo } from './../ui/modules/job-events/eventsHelper';
import { getHourTimestamp } from './../ui/components/availability-picker/helpers/parseData';
import { ImageInfo } from 'app/ui/components/multi-image-uploader/MultiImageUploader';

export interface HeroReportsState {
  currentReport: AppNS.JobEventReport | null;
  isReportLoading: boolean;
  isReportLoadingError: boolean;
  isDeleteEventLoading: boolean;
  isDeleteEventError: boolean;
  isReportStateLoadingError: boolean;
  isReportStateLoading: boolean;

  isReportValid: boolean;
  isReportValidChecked: boolean;
  reportValidErrors: Array<string>;
  isRequestLoading: boolean;
  isRequestLoadingError: boolean;

  isCancellingJob: boolean;
  isCancellingJobError: boolean;

  detailsActionDone: boolean;

  isTimeFromLoading: boolean;
  isTimeToLoading: boolean;
  isTimeLoadingError: boolean;

  isNotesLoading: boolean;
  isNotesLoadingError: boolean;

  isListItemLoading: boolean;
  isListItemLoadingError: boolean;

  isReportFinalizing: boolean;
  isReportFinalizingError: boolean;

  isPaymentRequestPausing: boolean;
  isPaymentRequestPausingError: boolean;
  paymentRequestPausingErrorMessage: string;

  isDetailsPageTouched: boolean;
}

const initialState: HeroReportsState = {
  currentReport: null,
  isReportLoading: false,
  isReportLoadingError: false,
  isDeleteEventLoading: false,
  isDeleteEventError: false,
  isReportStateLoadingError: false,
  isReportStateLoading: false,
  isReportValid: false,
  isReportValidChecked: false,
  reportValidErrors: [],
  isRequestLoading: false,
  isRequestLoadingError: false,
  isCancellingJob: false,
  isCancellingJobError: false,
  detailsActionDone: false,
  isTimeFromLoading: false,
  isTimeToLoading: false,
  isTimeLoadingError: false,
  isNotesLoading: false,
  isNotesLoadingError: false,
  isListItemLoading: false,
  isListItemLoadingError: false,
  isReportFinalizing: false,
  isReportFinalizingError: false,
  isPaymentRequestPausing: false,
  isPaymentRequestPausingError: false,
  paymentRequestPausingErrorMessage: '',
  isDetailsPageTouched: false,
};

function checkReportValid(state: HeroReportsState) {
  const report = state.currentReport;
  if (!report) {
    state.isReportValid = false;
    return;
  }
  const validationErrors: Array<string> = [];

  // all checklist items must be checked (if checklist exists)
  if (
    report.checklistItems.length &&
    report.checklistItems.find((item) => !item.done) !== undefined
  ) {
    validationErrors.push('Please check all items on checklist.');
  }

  if (!report.heroNotes) {
    validationErrors.push('Please write some event notes.');
  }

  state.reportValidErrors = validationErrors;
  state.isReportValid = validationErrors.length ? false : true;
}

function countProposedHours(state: HeroReportsState) {
  if (state.currentReport) {
    const report = state.currentReport;
    if (!report.proposedTimeTo && !report.proposedTimeFrom) {
      state.currentReport.proposedNumberOfHours = null;
      return;
    }
    const timeFrom = report.proposedTimeFrom || report.jobEvent.timeFrom;
    const timeTo = report.proposedTimeTo || report.jobEvent.timeTo;

    const timeToValue = parseTimeTo(timeTo);

    const momentFrom = moment(getHourTimestamp(timeFrom));
    const momentTo = moment(getHourTimestamp(timeToValue));
    const result = moment.duration(momentTo.diff(momentFrom)).asHours();
    state.currentReport.proposedNumberOfHours = roundResult(result);
  }
}

function roundResult(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

const heroReports = createSlice({
  name: 'heroReports',
  initialState,
  reducers: {
    getReportStart(state: HeroReportsState) {
      state.currentReport = null;
      state.isReportLoading = true;
      state.isReportLoadingError = false;
    },
    getCurrentReportSuccess(
      state: HeroReportsState,
      action: PayloadAction<AppNS.JobEventReport>
    ) {
      state.isReportLoadingError = false;
      state.isReportLoading = false;
      state.currentReport = action.payload;
      checkReportValid(state);
    },
    getReportFailed(state: HeroReportsState) {
      state.isReportLoadingError = true;
      state.isReportLoading = false;
    },
    setReportStateStart(state: HeroReportsState) {
      state.isReportStateLoading = true;
    },
    setReportStateSuccess(
      state: HeroReportsState,
      action: PayloadAction<AppNS.JobReportState>
    ) {
      state.isReportStateLoading = false;
      if (state.currentReport) {
        state.currentReport.state = action.payload;
      }
    },
    setReportStateFailed(state: HeroReportsState) {
      state.isReportStateLoadingError = true;
      state.isReportStateLoading = false;
    },
    setIsReportValidChecked(state: HeroReportsState) {
      state.isReportValidChecked = true;
    },
    setDetailsActionDone(
      state: HeroReportsState,
      action: PayloadAction<boolean>
    ) {
      state.detailsActionDone = action.payload;
    },
    setDetailsPageTouched(
      state: HeroReportsState,
      action: PayloadAction<boolean>
    ) {
      state.isDetailsPageTouched = action.payload;
    },
    setListItemStateStart(state: HeroReportsState) {
      state.isListItemLoading = true;
      state.isListItemLoadingError = false;
    },
    setListItemStateFailed(state: HeroReportsState) {
      state.isListItemLoading = false;
      state.isListItemLoadingError = true;
    },
    setListItemState(
      state: HeroReportsState,
      action: PayloadAction<{ id: string; state: boolean }>
    ) {
      if (state.currentReport) {
        state.currentReport.checklistItems = state.currentReport.checklistItems.map(
          (checklistItem) => {
            if (checklistItem.id === action.payload.id) {
              return { ...checklistItem, done: action.payload.state };
            }
            return checklistItem;
          }
        );

        checkReportValid(state);
        state.isListItemLoading = false;
      }
    },
    setNotesStart(state: HeroReportsState) {
      state.isNotesLoading = true;
      state.isNotesLoadingError = false;
    },
    setNotes(state: HeroReportsState, action: PayloadAction<string>) {
      if (state.currentReport) {
        state.currentReport.heroNotes = action.payload;
        checkReportValid(state);
        state.isNotesLoading = false;
      }
    },
    setNotesFailed(state: HeroReportsState) {
      state.isNotesLoading = false;
      state.isNotesLoadingError = true;
    },
    setTimeStart(state: HeroReportsState, action: PayloadAction<string>) {
      if (action.payload === "from") {
        state.isTimeFromLoading = true;
      } else {
        state.isTimeToLoading = true;
      }
      state.isTimeLoadingError = false;
    },
    setTimeFrom(state: HeroReportsState, action: PayloadAction<string | null>) {
      if (state.currentReport) {
        state.currentReport.proposedTimeFrom = action.payload;
        countProposedHours(state);
      }
      state.isTimeFromLoading = false;
    },
    setTimeTo(state: HeroReportsState, action: PayloadAction<string | null>) {
      if (state.currentReport) {
        state.currentReport.proposedTimeTo = action.payload;
        countProposedHours(state);
      }
      state.isTimeToLoading = false;
    },
    setTimeFailed(state: HeroReportsState) {
      state.isTimeFromLoading = false;
      state.isTimeToLoading = false;
      state.isTimeLoadingError = true;
    },
    addImage(state: HeroReportsState, action: PayloadAction<ImageInfo>) {
      if (state.currentReport) {
        state.currentReport.photos = [
          ...state.currentReport.photos,
          action.payload,
        ];
      }
    },
    removeImageStart(state: HeroReportsState, action: PayloadAction<string>) {
      if (state.currentReport) {
        state.currentReport.photos = state.currentReport.photos.map(
          (image: ImageInfo) => {
            if (image.id === action.payload) {
              return { ...image, isLoaded: false };
            }
            return image;
          }
        );
      }
    },
    removeImageSuccess(state: HeroReportsState, action: PayloadAction<string>) {
      if (state.currentReport) {
        state.currentReport.photos = state.currentReport.photos.map(
          (image: ImageInfo) => {
            if (image.id === action.payload) {
              return { ...image, isRemoved: true };
            }
            return image;
          }
        );
      }
    },
    setFinalizeStart(state: HeroReportsState) {
      state.isReportFinalizing = true;
      state.isReportFinalizingError = false;
    },
    setFinalizeSuccess(state: HeroReportsState) {
      state.isReportFinalizing = false;
      state.isReportFinalizingError = false;
      state.isReportValidChecked = false;
      state.reportValidErrors = [];
      if (state.currentReport) {
        state.currentReport.state = 'finalized_report';
      }
    },
    setFinalizeFailed(state: HeroReportsState) {
      state.isReportFinalizing = false;
      state.isReportFinalizingError = true;
    },
    setPausePaymentRequestStart(state: HeroReportsState) {
      state.isPaymentRequestPausing = true;
    },
    setPausePaymentRequestSuccess(state: HeroReportsState) {
      if (state.currentReport) {
        state.currentReport.state = 'payment_request_paused';
      }
      state.isPaymentRequestPausing = false;
    },
    setPausePaymentRequestFailed(state: HeroReportsState, action: PayloadAction<string>) {
      state.isPaymentRequestPausing = false;
      state.isPaymentRequestPausingError = true;
      state.paymentRequestPausingErrorMessage = action.payload;
    },
  },
});

export const {
  addImage,
  getCurrentReportSuccess,
  getReportFailed,
  getReportStart,
  removeImageStart,
  removeImageSuccess,
  setDetailsActionDone,
  setDetailsPageTouched,
  setFinalizeFailed,
  setFinalizeStart,
  setFinalizeSuccess,
  setPausePaymentRequestFailed,
  setPausePaymentRequestStart,
  setPausePaymentRequestSuccess,
  setIsReportValidChecked,
  setListItemState,
  setListItemStateFailed,
  setListItemStateStart,
  setNotes,
  setNotesFailed,
  setNotesStart,
  setReportStateFailed,
  setReportStateStart,
  setReportStateSuccess,
  setTimeFailed,
  setTimeFrom,
  setTimeStart,
  setTimeTo,
} = heroReports.actions;

export default heroReports.reducer;

export const getReport = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getReportStart());
    const result = await apiGetReport(id);
    dispatch(getCurrentReportSuccess(result));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getReportFailed());
  }
};

export const setChecklistItemState = (
  id: string,
  state: boolean
): AppThunk => async (dispatch) => {
  try {
    dispatch(setListItemStateStart());
    await apiUpdateReportChecklistItem({
      id,
      properties: { done: state },
    });
    dispatch(setListItemState({ id, state }));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(setListItemStateFailed());
  }
};

export const setEventNotes = (id: string, notes: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(setNotesStart());
    await apiUpdateReportHeroNotes({
      id,
      properties: { heroNotes: notes },
    });
    dispatch(setNotes(notes));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(setNotesFailed());
  }
};

export const addReportImage = (
  reportId: string,
  imageInfo: ImageInfo
): AppThunk => async (dispatch) => {
  try {
    const output = await apiAddReportImage({
      properties: {
        eventReportId: reportId,
        file: imageInfo.url || '',
      },
    });
    dispatch(addImage({ ...output, uppyId: imageInfo.uppyId }));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
  }
};

export const removeReportImage = (imageId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(removeImageStart(imageId));
    await apiDeleteReportImage(imageId);
    dispatch(removeImageSuccess(imageId));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
  }
};

export const setProposedTime = (
  id: string,
  time: string,
  type: 'from' | 'to'
): AppThunk => async (dispatch) => {
  try {
    dispatch(setTimeStart(type));

    if (type === 'from') {
      await apiUpdateTime<ApiNS.UpdateTimeRequestFrom>(
        getTimeFromData(id, time)
      );
      dispatch(setTimeFrom(time));
    } else {
      await apiUpdateTime<ApiNS.UpdateTimeRequestTo>(getTimeToData(id, time));
      dispatch(setTimeTo(time));
    }
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(setTimeFailed());
  }
};

export const finalizeReport = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setFinalizeStart());
    await apiFinalizeReport(id);
    dispatch(setFinalizeSuccess());
    dispatch(setDetailsActionDone(true));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(setFinalizeFailed());
  }
};

export const pausePaymentRequest = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setPausePaymentRequestStart());
    await apiPausePaymentRequest(id);
    dispatch(setPausePaymentRequestSuccess());
    dispatch(setDetailsActionDone(false));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(setPausePaymentRequestFailed(err.toString()));
  }
};
