import moment, { Moment } from 'moment';

export const AVAILABILITY_DAYS = [
  {
    name: 'Sunday',
    id: 0,
  },
  {
    name: 'Monday',
    id: 1,
  },
  {
    name: 'Tuesday',
    id: 2,
  },
  {
    name: 'Wednesday',
    id: 3,
  },
  {
    name: 'Thursday',
    id: 4,
  },
  {
    name: 'Friday',
    id: 5,
  },
  {
    name: 'Saturday',
    id: 6,
  },
];

export const getAvailabilityHoursData = (excludeTimes: Array<number> = []) => {
  const range = getTimeRange('00:00', '23:45', 15);
  const hours = range
    .filter((item: Moment) => {
      let minutes =
        parseInt(item.format('H')) * 60 + parseInt(item.format('mm'));
      return !excludeTimes.includes(minutes);
    })
    .map((item: Moment) => {
      return {
        id: item.format('H:mm'),
        name: item.format('h:mma'),
      };
    });
    hours[0].name = "12:00am";
    hours[48].name = "noon";
    return hours
};

export const getTimeRange = (
  start: string,
  end: string,
  step: number // in minutes
): Array<Moment> => {
  const momentDate = moment(`2000-01-01 ${start}`);
  let date = momentDate;
  const output = [momentDate];
  let counter = 0;

  if (momentDate.isValid()) {
    do {
      counter++;
      date = momentDate.clone().add(step * counter, 'minute');
      output.push(date);
    } while (date.format('HH:mm') !== end && counter < 1600);

    return output;
  }
  console.error(`[getTimeRange] Invalid start time: ${start}`);
  return [];
};
