import axios, { Canceler } from 'axios';
import { API_URL } from '../core/config';
import { ApiNS } from 'app/api/api';

const URL = 'schedule';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetSchedule(
  userRole: string,
  sectionId: string,
  query: string,
  dateFrom: string,
  dateTo: string,
): Promise<ApiNS.ScheduleListResponse> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          userRole,
          sectionId,
          query,
          date_from: dateFrom,
          date_to: dateTo,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetScheduleCsv(
  userRole: string,
  sectionId: string,
  query: string,
  dateFrom: string,
  dateTo: string
): Promise<Blob> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}.csv`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          userRole, 
          sectionId,
          query,
          date_from: dateFrom,
          date_to: dateTo,
        },
        headers: {
          Accept: 'text/csv',
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(new Blob([data], { type: 'text/csv' }));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
