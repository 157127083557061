import { AppNS } from 'app';
export const DOCUMENT_TYPES = [
  'id',
  'resume',
  'certification',
  'automobile_insurance',
] as Array<AppNS.DocumentType>;

export const DOCUMENT_UPLOAD_CONFIG = {
  restrictionsNote: '1 file. Image or PDF',
  filesDropNote: '',
  restrictionsRules: {
    maxNumberOfFiles: 1,
    maxFileSize: 5242880, // 5 MB
    allowedFileTypes: ['image/*', 'application/pdf'],
  },
  presignedUrl: {
    modelName: 'document',
    propertyName: 'file',
  },
  isWithCompressor: {
    maxWidth: 2048,
    maxHeight: 2048,
    mimeType: 'image/jpeg',
  },
};
