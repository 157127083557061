import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AppNS } from 'app';
import BellIcon from 'app/ui/icons/BellIcon';
import { RootState } from 'app/store/rootReducer';

export interface MessagesBellProps {
  userRole: AppNS.Role;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 64,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    height: 64,
    width: 40,
    padding: 0,
    minWidth: 40,
  },
}));

export default function MessagesBell(props: MessagesBellProps) {
  const { userRole } = props;
  const { unreadCount, isMessagingPageOn } = useSelector(
    (state: RootState) => state.messaging
  );

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        component={Link}
        to={`/${userRole}/messaging/inbox`}
      >
        <BellIcon />
        {unreadCount > 0 && (
          <Box
            position="absolute"
            right="0"
            top="12px"
            width="17px"
            height="17px"
            borderRadius="8px"
            zIndex="10"
            style={{
              background: '#c72f66',
            }}
            fontSize="1.2rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="#fff"
          >
            {unreadCount}
          </Box>
        )}
        {isMessagingPageOn > 0 && (
          <Box
            position="absolute"
            right="0"
            bottom="0"
            width="100%"
            height="4px"
            borderRadius="2px"
            zIndex="10"
            style={{
              background: '#f4357a',
            }}
          ></Box>
        )}
      </Button>
    </div>
  );
}
