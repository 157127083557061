import axios, { Canceler } from 'axios';

import { AppNS } from '..';
import { API_URL } from '../core/config';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetConstants(): Promise<AppNS.Constants> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/constants.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.result) {
          resolve(data.result);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetCalendlyConstants(
  req: string
): Promise<AppNS.Constants> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/calendly.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          type: "calendly",
          req, 
        }
      })
      .then(({ data }) => {
        if (data.result) {
          resolve(data.result);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

