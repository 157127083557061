import { ApiNS } from 'app/api/api';
import { AppNS } from '..';
import axios from 'axios';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  apiGetFavorites,
  apiRemoveFromFavorites,
} from 'app/api/client-favorites/client-favorites';

export interface ClientFavoritesState {
  page: number;
  perPage: number;
  careType: string;
  totalEntries: number;
  items: Array<AppNS.ClientFavorite>;
  isLeadLoading: boolean;
  isLoaded: boolean;
  isLoading: boolean;
  isFavRemoving: boolean;
  isFavRemovingError: boolean;
}

const initialState: ClientFavoritesState = {
  page: 1,
  perPage: 10,
  careType: '',
  totalEntries: 0,
  items: [],
  isLeadLoading: false,
  isLoaded: false,
  isLoading: false,
  isFavRemoving: false,
  isFavRemovingError: false,
};

const clientFavorites = createSlice({
  name: 'clientFavorites',
  initialState,
  reducers: {
    resetCollection(state) {
      state.items = [];
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCurrentParams(state, action: PayloadAction<AppNS.Params>) {
      state.page = action.payload.page;
      state.careType = action.payload.careType;
    },
    getFavoritesStart(state: ClientFavoritesState) {
      state.isLoading = true;
    },
    getFavoritesSuccess(
      state: ClientFavoritesState,
      action: PayloadAction<ApiNS.FavoritesListResponse>
    ) {
      // state.error = null;
      state.isLoading = false;
      state.items = action.payload.results;
      state.totalEntries = action.payload.totalEntries;
    },
    getFavoritesFailed(state: ClientFavoritesState) {
      state.items = [];
      // state.error = action.payload;
      state.isLoading = false;
    },
    removeFromFavStart(state: ClientFavoritesState) {
      state.isFavRemovingError = false;
      state.isFavRemoving = true;
    },
    removeFromFavFailed(state: ClientFavoritesState) {
      state.isFavRemovingError = true;
      state.isFavRemoving = false;
    },
    removeFromFavSuccess(
      state: ClientFavoritesState,
      action: PayloadAction<string>
    ) {
      state.isFavRemovingError = false;
      state.isFavRemoving = false;

      if (state.items.length) {
        state.items = state.items.filter((item) => item.id !== action.payload);
      }
    },
  },
});

export const {
  getFavoritesStart,
  getFavoritesSuccess,
  getFavoritesFailed,
  setCurrentParams,
  removeFromFavStart,
  removeFromFavFailed,
  removeFromFavSuccess,
} = clientFavorites.actions;

export default clientFavorites.reducer;

export const getFavorites = (
  searchQuery: string,
  page: number,
  per_page: number =  10
): AppThunk => async (dispatch) => {
  try {
    dispatch(getFavoritesStart());
    const result = await apiGetFavorites(searchQuery, page, per_page);
    dispatch(getFavoritesSuccess(result));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getFavoritesFailed());
  }
};

export const removeFromFavorites = (
  heroId: string,
  careTypeId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(removeFromFavStart());
    const id = await apiRemoveFromFavorites(heroId, careTypeId);
    dispatch(removeFromFavSuccess(id));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err);
    dispatch(removeFromFavFailed());
  }
};
