import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import 'react-dates/lib/css/_datepicker.css';

import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { registerRequestInterceptor } from './app/api/interceptors/registerRequestInterceptor';
import { registerResponseInterceptor } from './app/api/interceptors/registerResponseInterceptor';
import { setCustomConsoleLog } from './app/helpers/customConsoleLog';

// http interceptors
registerRequestInterceptor();
registerResponseInterceptor();

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

setCustomConsoleLog('Easy, safe, and when you need it.', '', 'success');
