import { AppNS } from '..';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { verifyEmail, resendEmail } from '../api/email-verification';

export interface EmailVerificationState {
  errorMessage: string;
  isActionLoading: boolean;
  isActionRequested: boolean;
}

const initialState: EmailVerificationState = {
  errorMessage: '',
  isActionLoading: false,
  isActionRequested: false,
};

const emailVerification = createSlice({
  name: 'emailVerification',
  initialState,
  reducers: {
    actionStart(state: EmailVerificationState) {
      state.isActionLoading = true;
      state.isActionRequested = false;
    },
    actionSuccess(state: EmailVerificationState) {
      state.isActionLoading = false;
      state.isActionRequested = true;
      state.errorMessage = '';
    },
    actionFailed(state: EmailVerificationState, action: PayloadAction<string>) {
      state.isActionRequested = true;
      state.isActionLoading = false;
      state.errorMessage = action.payload;
    },
  },
});

export const {
  actionStart,
  actionSuccess,
  actionFailed,
} = emailVerification.actions;

export default emailVerification.reducer;

export const checkEmail = (params: AppNS.Params): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(actionStart());
    await verifyEmail(params);
    dispatch(actionSuccess());
  } catch (err) {
    dispatch(actionFailed(err.toString()));
  }
};

export const resendEmailAction = (params: AppNS.Params): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(actionStart());
    await resendEmail(params);
    dispatch(actionSuccess());
  } catch (err) {
    dispatch(actionFailed(err.toString()));
  }
};
