import camelCaseKeys from 'camelcase-keys';
import { parseAvatar } from './../common-parsers/common-parsers';
import { AppNS } from 'app';
export const parseThread = (item: AppNS.Params): AppNS.MessageThread => {
  return {
    ...item,
    participant: parseParticipant(item.participant),
    messages: [] as Array<AppNS.Message>,
  } as AppNS.MessageThread;
};

export const parseMessage = (item: AppNS.Params): AppNS.Message => {
  return {
    ...item,
    actions: item.actions ? camelCaseKeys(item.actions) : [],
  } as AppNS.Message;
};

export const parseParticipant = (
  item: AppNS.Params
): AppNS.ThreadParticipant | null => {
  if (item.length === 0) {
    return null;
  }
  return {
    firstName: item[0].firstName,
    lastName: item[0].lastName,
    id: item[0].id,
    avatar: parseAvatar(item[0].avatar),
    roles: item[0].roles,
  };
};
