import { AppNS } from 'app';
import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const GIFT_CARDS_REDUCERS = {
  getGiftCardsStart(state: AuthState) {
    state.isLoadingGiftCards = true;
    state.isLoadingGiftCardError = false;
  },
  getGiftCardsFailed(state: AuthState) {
    state.isLoadingGiftCards = false;
    state.isLoadingGiftCardError = true;
  },
  getGiftCardsSuccess(
    state: AuthState,
    action: PayloadAction<Array<AppNS.GiftCard>>
  ) {
    state.isLoadingGiftCards = false;
    state.isLoadingGiftCardError = false;
    state.userGiftCards = action.payload;
  },
  addGiftCardStart(state: AuthState) {
    state.isAddingGiftCard = true;
    state.isAddingGiftCardError = false;
  },
  addGiftCardFailed(state: AuthState) {
    state.isAddingGiftCard = false;
    state.isAddingGiftCardError = true;
  },
  addGiftCardSuccess(
    state: AuthState,
    action: PayloadAction<AppNS.GiftCard>
  ) {
    state.isAddingGiftCard = false;
    state.isAddingGiftCardError = false;
    state.userGiftCards = [action.payload, ...state.userGiftCards];
  },
};
