import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../../core/config';
import { parseJobLead, parseProposal } from './hero-jobs-parsers';

const URL = 'jobs';
const LEADS_URL = 'job_leads';
const EVENTS_URL = 'job_events.json';
const BOOKINGS_URL = 'bookings';
const STATE_URL = 'change-state';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetJobLeads(
  type: string,
  searchQuery: string,
  page: number
): Promise<AppNS.GetJobLeadsApiResponse> {
  if (cancel !== undefined) {
    cancel();
  }
  if (type === 'job-opportunities') {
    return apiGetOpportunities(searchQuery, page);
  } else if (type === 'confirmed-jobs') {
    return apiGetBookings(searchQuery, ['confirmed'], page);
  } else {
    return apiGetBookings(
      searchQuery,
      ['past', 'cancelled_by_hero', 'cancelled_by_client'],
      page
    );
  }
}

export function apiGetJobLead(
  type: string,
  id: string
): Promise<AppNS.JobLead> {
  if (cancel !== undefined) {
    cancel();
  }

  if (type === 'job-opportunities') {
    return apiGetJobLeadById(id);
  } else {
    return apiGetJobBookingById(id);
  }
}

function apiGetOpportunities(
  searchQuery: string,
  page: number
): Promise<AppNS.GetJobLeadsApiResponse> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${LEADS_URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          query: searchQuery,
          states: ['pending', 'proposal_sent', 'accepted', 'auto_bid'],
          page,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve({
            ...data,
            results: data.results.map((item: AppNS.JobLead) => {
              return parseJobLead(item);
            }),
          });
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetMatchRecommendations(
  jobId: string, 
  leadId: string,
  careTypeId: string,
): Promise<AppNS.ClientJob> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    const params = {jobId, leadId, careTypeId};
    axios
      .get(`${API_URL}/${LEADS_URL}/match-recommendations.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(data.results);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiDeleteJobEvent(reqData: AppNS.Params): Promise<string> {
  const req = {
    id: reqData.id,
    requires_backup: reqData.backup,
    properties: {
      state: 'deleted_by_hero',
      cancellation_message: reqData.msg,
    },
  };
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${EVENTS_URL}`, req, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

function apiGetBookings(
  searchQuery: string,
  states: string | Array<string>,
  page: number
): Promise<AppNS.GetJobLeadsApiResponse> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${BOOKINGS_URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          query: searchQuery,
          states,
          page,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve({
            ...data,
            results: data.results.map((item: AppNS.JobLead) => {
              return parseJobLead(item);
            }),
          });
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetJobLeadById(leadId: string): Promise<AppNS.JobLead> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${LEADS_URL}/${leadId}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(parseJobLead(data.model));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetJobBookingById(
  bookingId: string
): Promise<AppNS.JobLead> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${BOOKINGS_URL}/${bookingId}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(parseJobLead(data.model));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetLeadState(
  reqData: AppNS.Params
): Promise<AppNS.JobLeadState> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${LEADS_URL}/${STATE_URL}.json`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.properties.state);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiCreateProposal(
  reqData: AppNS.LeadProposal
): Promise<boolean> {
  const data = parseProposal(reqData);
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${LEADS_URL}.json`, data)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.properties.state);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiCancelJob(reqData: AppNS.Params): Promise<string> {
  const data = {
    id: reqData.id,
    propertiesAttributes: {
      cancellationMessage: reqData.message,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${URL}/cancel.json`, data)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
