import React from 'react';

import { AppBar, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import mainTheme from '../../theme/mainTheme';

import ToolbarNotLogged from './ToolbarNotLogged';
import ToolbarLogged from './ToolbarLogged';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: theme.palette.background.paper,
  },
  container: {
    margin: 0,
  },
}));

export interface ToolbarLayoutProps {
  isWithoutSidebar: boolean;
  isUserLogged: boolean;
}

export default function ToolbarLayout(props: ToolbarLayoutProps) {
  const { isWithoutSidebar, isUserLogged } = props;

  const classes = useStyles(mainTheme);
  return (
    <AppBar color="transparent" className={classes.root} elevation={1}>
      <Container
        maxWidth="lg"
        className={!isWithoutSidebar ? classes.container : ''}
      >
        {!isUserLogged && <ToolbarNotLogged></ToolbarNotLogged>}
        {isUserLogged && <ToolbarLogged></ToolbarLogged>}
      </Container>
    </AppBar>
  );
}
