import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function FavouritesIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 24" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5436 23.2493L13.046 17.9153C12.0559 16.9943 11.8108 15.5871 12.4376 14.4223V14.4223C12.905 13.5525 13.7935 12.9448 14.8254 12.7891C15.8574 12.6333 16.9068 12.9486 17.6459 13.6363L18.5436 14.4713L19.4412 13.6363C20.1805 12.9486 21.2301 12.6334 22.2622 12.7891C23.2942 12.9448 24.1829 13.5525 24.6506 14.4223V14.4223C25.2764 15.5874 25.0309 16.9942 24.0411 17.9153L18.5436 23.2493Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.86865 17.2506H2.41865C1.52809 17.2506 0.806152 16.5791 0.806152 15.7506V3.76562C0.806152 2.9372 1.52809 2.26562 2.41865 2.26562H16.9312C17.8217 2.26562 18.5437 2.9372 18.5437 3.76562V8.25063"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.806152 6.75H18.5437"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M5.63623 3.75V0.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6987 3.75V0.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
