import { AppNS } from 'app';
import moment from 'moment';
import { orderBy } from 'lodash-es';
import {
  DB_DATE_FORMAT_STRING,
  DB_DATETIME_FORMAT_STRING,
} from 'app/helpers/parseDate';
import { parseMoney } from 'app/helpers/parseMoney';

export const parseTimeTo = (timeTo: string): string => {
  return timeTo === '0:00' ? '23:59:59' : timeTo;
};

export const getHourFormatted = (hour: string, type: string): string => {
  if (hour === '12:00') {
    return 'noon';
  }
  if (hour === '0:00' && type === 'to') {
    return 'midnight';
  }
  return moment(`2000-01-01 ${hour}`, DB_DATETIME_FORMAT_STRING).format(
    'h:mma'
  );
};

export const timesToTimeSpan = (from: string, to: string): string => {
  return `${getHourFormatted(from, 'from')} - ${getHourFormatted(to, 'to')}`;
};

export const getDateFormatted = (date: string): string => {
  return moment(date, DB_DATE_FORMAT_STRING).format('ddd, MMM D, YYYY');
};

// arg 'date' must be in 'MMM D, YYYY' format, e.g. 'Jul 8, 2021'
export const parseDayOfWeek = (
  date: string,
  dayFormat: string = 'ddd'
): string => {
  if (date) {
    return moment(date, 'MMM D, YYYY').format(dayFormat);
  }
  return '';
};

export const getEventById = (
  id: string,
  data: Array<AppNS.Params>
): AppNS.Params => {
  return data.filter((event: AppNS.Params): boolean => {
    return event.id === id;
  })[0];
};

export const isEventInProgess = (
  date: string,
  timeFrom: string,
  timeTo: string
): boolean => {
  const parsedTimeTo = parseTimeTo(timeTo);

  const from = moment(`${date} ${timeFrom}`, DB_DATETIME_FORMAT_STRING);
  const to = moment(`${date} ${parsedTimeTo}`, DB_DATETIME_FORMAT_STRING);

  return moment().isBetween(from, to);
};

export const isEventPast = (date: string, timeTo: string): boolean => {
  const parsedTimeTo = parseTimeTo(timeTo);
  const to = moment(`${date} ${parsedTimeTo}`, DB_DATETIME_FORMAT_STRING);
  return moment().isAfter(to);
};

const getHourlyRate = (
  mpoFee: number,
  fixHourlyRate: number | null,
  careTypeHourlyRate: number,
  rateModifierPercent: number
): number => {
  let result: number;
  if (fixHourlyRate) {
    result = fixHourlyRate * mpoFee;
  } else {
    result = rateModifierPercent * mpoFee * careTypeHourlyRate;
  }
  return Math.round(result * 100) / 100;
};

const getHourlyRateForHeroBooking = (
  mpoFee: number,
  careTypeHourlyRate: number,
  preferredHourlyRate: number,
  rateModifierPercent: number,
  leadState: string
): number => {
  let result: number;
  if (
    leadState !== 'auto_bid' &&
    preferredHourlyRate !== 0 &&
    preferredHourlyRate / mpoFee < careTypeHourlyRate * rateModifierPercent
  ) {
    result = preferredHourlyRate / mpoFee;
  } else {
    result = rateModifierPercent * careTypeHourlyRate;
  }
  return Math.round(result * 100) / 100;
};

export const getEventListItems = (
  mpoFee: number,
  fixHourlyRate: number | null,
  careTypeHourlyRate: number,
  events: Array<AppNS.JobEvent>,
  loyaltyDiscountPercent: number
): Array<AppNS.JobEventListItem> => {
  const sorted = sortEventsByDate(events);

  const output = sorted.map((item: AppNS.JobEvent) => {
    const timeValues = getTimeValues(item);

    return {
      id: item.id,
      date: getDateFormatted(item.date),
      discount: loyaltyDiscountPercent,
      timeSpan: timesToTimeSpan(timeValues.timeFrom, timeValues.timeTo),
      numberOfHours: timeValues.numberOfHours,
      hourlyRate: getHourlyRate(
        mpoFee,
        fixHourlyRate,
        careTypeHourlyRate,
        item.rateModifierPercent
      ),
      total: item.report?.amountForClient || 0,
      conflicts: [] as Array<string>,
      state: item?.state || 'upcoming',
      report: item.report,
    };
  });
  return output as Array<AppNS.JobEventListItem>;
};

export const getEventsListForSendProposalDialog = (
  jobEvents: Array<AppNS.JobEvent>,
  proposalEvents: Array<AppNS.LeadProposalEvent>
): Array<{ date: string; timeSpan: string } | undefined> => {
  const events = jobEvents.map((jobEvent: AppNS.JobEvent) => {
    const proposalEvent = proposalEvents.find(
      (event: AppNS.LeadProposalEvent) => {
        return event.jobEventId === jobEvent.id;
      }
    );

    if (proposalEvent && proposalEvent.notAvailable) {
      return;
    }

    return {
      date: getDateFormatted(jobEvent.date),
      timeSpan: timesToTimeSpan(
        proposalEvent?.timeFrom || jobEvent.timeFrom,
        proposalEvent?.timeTo || jobEvent.timeTo
      ),
    };
  });

  return (
    events.filter(function (x) {
      return x != undefined;
    }) || []
  );
};

export const getEventListItemsForHeroBooking = (
  mpoFee: number,
  careTypeHourlyRate: number,
  preferredHourlyRate: number,
  events: Array<AppNS.JobEvent>,
  fixHourlyRate: number | null,
  leadState: string
): Array<AppNS.JobEventListItem> => {
  const sorted = sortEventsByDate(events);
  const output = sorted
    .map((item: AppNS.JobEvent) => {
      let hourlyRate = 0;
      if (!!fixHourlyRate) {
        hourlyRate = fixHourlyRate;
      } else {
        hourlyRate = getHourlyRateForHeroBooking(
          mpoFee,
          careTypeHourlyRate,
          preferredHourlyRate,
          item.rateModifierPercent,
          leadState
        );
      }

      const timeValues = getTimeValues(item);
      if (timeValues.numberOfHours === 0) {
        return null;
      }

      return {
        id: item.id,
        state: item.state,
        report: item.report,
        date: getDateFormatted(item.date),
        timeSpan: timesToTimeSpan(timeValues.timeFrom, timeValues.timeTo),
        numberOfHours: timeValues.numberOfHours,
        conflicts: item.conflict,
        hourlyRate,
        discount: 0,
        total: item.report?.amountForHero || 0,
      };
    })
    .filter((item) => {
      return item;
    });
  return output as Array<AppNS.JobEventListItem>;
};

const getTimeValues = (
  event: AppNS.JobEvent
): Pick<AppNS.JobEvent, 'timeTo' | 'timeFrom' | 'numberOfHours'> => {
  const report = event?.report;

  const timeFrom =
    report?.proposedTimeFrom ||
    event.jobProposalEventChange?.timeFrom ||
    event.timeFrom;
  const timeTo =
    report?.proposedTimeTo ||
    event.jobProposalEventChange?.timeTo ||
    event.timeTo;
  const numberOfHours = event.jobProposalEventChange?.notAvailable
    ? 0
    : report?.proposedNumberOfHours ||
      event.jobProposalEventChange?.numberOfHours ||
      event.numberOfHours;

  return {
    timeFrom,
    timeTo,
    numberOfHours,
  };
};

export const getHeroHourlyRateByCareType = (
  careTypeId: string,
  heroCareTypes: Array<{
    id: string;
    type: string;
    hourlyRate: number;
  }>
): number => {
  return (
    heroCareTypes.filter((item) => item.type === careTypeId)[0]?.hourlyRate || 0
  );
};

export const getEventsSummaryHero = (
  eventsList: Array<AppNS.JobEventListItem>
): AppNS.JobEventsSummary => {
  const output = {
    careEventsNumber: 0,
    sumOfHours: 0,
    rateSum: 0,
    avgRate: 0,
    discounts: 0,
    total: 0,
  };
  eventsList.forEach((item) => {
    if (
      item.state?.includes('deleted_by') ||
      item.state?.includes('cancelled')
    ) {
      return;
    }
    output.careEventsNumber += 1;
    output.sumOfHours += item.numberOfHours;
    output.rateSum += item.hourlyRate;
    output.total +=
      item.total > 0 ? item.total : item.hourlyRate * item.numberOfHours;
  });

  output.avgRate =
    Math.round((output.rateSum / output.careEventsNumber) * 100) / 100;
  return output as AppNS.JobEventsSummary;
};

export const getEventsSummaryClient = (
  eventsList: Array<AppNS.JobEventListItem>
): AppNS.JobEventsSummary => {
  const output = {
    careEventsNumber: 0,
    sumOfHours: 0,
    rateSum: 0,
    avgRate: 0,
    discounts: 0,
    total: 0,
  };
  eventsList.forEach((item) => {
    if (
      item.state?.includes('deleted_by') ||
      item.state?.includes('cancelled')
    ) {
      return;
    }
    output.careEventsNumber += 1;
    const discount =
      item.total > 0
        ? item.report?.clientLoyaltyDiscountAmount || 0
        : item.hourlyRate * item.numberOfHours * (item.discount / 100);
    output.sumOfHours += item.numberOfHours;
    output.rateSum += item.hourlyRate;
    output.discounts += discount;
    output.total +=
      item.total > 0
        ? item.total + discount
        : item.hourlyRate * item.numberOfHours;
  });

  output.avgRate =
    Math.round((output.rateSum / output.careEventsNumber) * 100) / 100;
  return output as AppNS.JobEventsSummary;
};

export const startsSameDayNextDay = (
  adHocJobStartDate: string,
  recurringJobStartDate: AppNS.Params
): boolean => {
  const jobStartDate =
    adHocJobStartDate || recurringJobStartDate.properties.date;
  const d = moment(jobStartDate, 'YYYY-MM-DD');
  return moment().isSame(d, 'd') || moment().add(1, 'd').isSame(d, 'd');
};

export const getTimeSpan = (job: AppNS.Job): string => {
  if (job.startDate) {
    if (job.startDate === job.endDate) {
      return `${moment(job.startDate, DB_DATE_FORMAT_STRING).format(
        'ddd, MMM D, YYYY'
      )}`;
    }

    let startDateFormat = 'ddd, MMM D, YYYY';
    if (job.startDate.slice(0, 4) === job.endDate.slice(0, 4)) {
      startDateFormat = 'ddd, MMM D';
    }
    return `${moment(job.startDate, DB_DATE_FORMAT_STRING).format(
      startDateFormat
    )} - ${moment(job.endDate, DB_DATE_FORMAT_STRING).format(
      'ddd, MMM D, YYYY'
    )}`;
  }
  return '';
};

export const getTimeSpanFormatted = (job: AppNS.Job): string => {
  const shortNotice = startsSameDayNextDay(job.startDate, job.jobSchedule[0]);
  if (job.startDate) {
    if (job.startDate === job.endDate) {
      return `${moment(job.startDate, DB_DATE_FORMAT_STRING).format(
        'ddd, MMM D, YYYY'
      )}${shortNotice ? '*' : ''}`;
    }

    let startDateFormat = 'ddd, MMM D, YYYY';
    if (job.startDate.slice(0, 4) === job.endDate.slice(0, 4)) {
      startDateFormat = 'ddd, MMM D';
    }
    return `${moment(job.startDate, DB_DATE_FORMAT_STRING).format(
      startDateFormat
    )}${shortNotice ? '*' : ''} - ${moment(
      job.endDate,
      DB_DATE_FORMAT_STRING
    ).format('ddd, MMM D, YYYY')}`;
  }
  return '';
};

export const eventsCanBeDeleted = (
  allEvents: Array<AppNS.JobEventListItem>
): boolean => {
  // can delete if at least one event is completed
  // or upcoming/in progress events length > 1
  const completed = allEvents.filter((ev) => ev?.state === 'completed');
  const upcoming = allEvents.filter(
    (ev) => ev?.state === 'upcoming' || ev?.state === 'in_progress'
  );
  if (completed.length > 0 || upcoming.length > 1) {
    return true;
  }
  return false;
};

export const eventStatuses: Map<AppNS.JobEventState, string> = new Map([
  ['upcoming', 'Upcoming'],
  ['completed', 'Completed'],
  ['past', 'Past'],
  ['in_progress', 'In Progress'],
  ['deleted_by_client', 'Cancelled by Client'],
  ['deleted_by_hero', 'Cancelled by Hero'],
  ['job_cancelled_by_hero', 'Cancelled by Hero'],
  ['job_cancelled_by_client', 'Cancelled by Client'],
]);

export const getEventStatus = (
  status: AppNS.JobEventState,
  type: string
): string => {
  if (
    (type === 'hero' && status === 'deleted_by_hero') ||
    (type === 'hero' && status === 'job_cancelled_by_hero') ||
    (type === 'client' && status === 'job_cancelled_by_client') ||
    (type === 'client' && status === 'deleted_by_client')
  ) {
    return 'Cancelled by me';
  }
  if (type === 'client' && status === 'completed') {
    return 'Fulfilled';
  }
  return eventStatuses.get(status) || '';
};

const sortEventsByDate = (
  events: Array<AppNS.JobEvent>
): Array<AppNS.JobEvent> => {
  return orderBy(events, (item: AppNS.JobEvent) => {
    return moment(item.date, DB_DATE_FORMAT_STRING).valueOf();
  });
};

export const jobCanBeDeleted = (
  events: Array<AppNS.JobEventListItem>
): boolean => {
  // cannot delete if at least one event report is in state "finalized_report"
  return !events.some((ev) => ev?.report?.state === 'finalized_report');
};

export const getClientPays = (
  data: AppNS.JobEventReport,
  heroIncome: number
): number => {
  return data.amountForClient && data.amountForClient > 0
    ? data.amountForClient
    : (heroIncome *
        data.booking.mpoFeePercent *
        (100 - data.client.loyaltyDiscountPercent)) /
        100;
};

export const getEventTotal = (item: AppNS.JobEventListItem) => {
  if (item.total > 0) {
    return parseMoney(item.total * 100);
  }
  if (item.discount > 0) {
    return parseMoney(
      item.hourlyRate * item.numberOfHours * (100 - item.discount)
    );
  } else {
    return parseMoney(item.hourlyRate * item.numberOfHours * 100);
  }
};

export const getProposalEvent = (events: Array<AppNS.Params>, id: string) => {
  return events?.find((event: AppNS.Params) => {
    return event.jobEventId === id;
  });
};
