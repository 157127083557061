import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function DashboardIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 24" fill="none">
      <g fill="none">
        <path
          d="M8.86426 19.5L13.132 15.53C13.2832 15.3892 13.4884 15.3102 13.7023 15.3102C13.9162 15.3102 14.1214 15.3892 14.2726 15.53L16.357 17.469C16.5082 17.6098 16.7134 17.6888 16.9273 17.6888C17.1412 17.6888 17.3464 17.6098 17.4976 17.469L21.7643 13.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.801758 2.25C0.801758 1.42157 1.47333 0.75 2.30176 0.75H23.4893C24.3177 0.75 24.9893 1.42157 24.9893 2.25V21.75C24.9893 22.5784 24.3177 23.25 23.4893 23.25H2.30176C1.47333 23.25 0.801758 22.5784 0.801758 21.75V2.25Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.86436 12.75C11.536 12.75 13.7019 10.7353 13.7019 8.25C13.7019 5.76472 11.536 3.75 8.86436 3.75C6.19268 3.75 4.02686 5.76472 4.02686 8.25C4.02686 10.7353 6.19268 12.75 8.86436 12.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.86426 3.75V8.25L12.2849 11.432"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7329 5.25H20.9579"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7329 8.25H20.9579"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
