import { AppNS } from 'app';
import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const CARE_CREDIT_REDUCERS = {
  getCareCreditsStart(state: AuthState) {
    state.isLoadingCareCredits = true;
    state.isLoadingCareCreditError = false;
  },
  getCareCreditsFailed(state: AuthState) {
    state.isLoadingCareCredits = false;
    state.isLoadingCareCreditError = true;
  },
  getCareCreditsSuccess(
    state: AuthState,
    action: PayloadAction<Array<AppNS.CareCredit>>
  ) {
    state.isLoadingCareCredits = false;
    state.isLoadingCareCreditError = false;
    state.userCareCredits = action.payload;
  },
}
