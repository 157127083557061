import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiGetConstants, apiGetCalendlyConstants } from 'app/api/constants';
import { AppNS } from '..';
import { AppThunk } from './index';

export type ConstantsState = {
  error: string | null;
  isLoading: boolean;
  constants: AppNS.Constants;
};

const initialState: ConstantsState = {
  error: null,
  isLoading: false,
  constants: {} as AppNS.Constants,
};

function startLoading(state: ConstantsState) {
  state.isLoading = true;
}

const constantsSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {
    getConstantsStart: startLoading,
    resetCollection(state) {
      state.constants = initialState.constants;
    },
    getConstantsSuccess(
      state: ConstantsState,
      action: PayloadAction<AppNS.Constants>
    ) {
      state.constants = action.payload;
      state.isLoading = false;
    },
    getConstantsFailed(state: ConstantsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  getConstantsStart,
  resetCollection,
  getConstantsSuccess,
  getConstantsFailed,
} = constantsSlice.actions;

export default constantsSlice.reducer;

export const fetchConstants = (): AppThunk => async (dispatch) => {
  try {
    const constants = await apiGetConstants();
    dispatch(getConstantsSuccess(constants));
  } catch (err) {
    dispatch(getConstantsFailed(err.toString()));
  }
};

export const fetchCalendlyConstants =
  (req: string): AppThunk =>
  async (dispatch) => {
    try {
      const constants = await apiGetCalendlyConstants(req);
      dispatch(getConstantsSuccess(constants));
    } catch (err) {
      dispatch(getConstantsFailed(err.toString()));
    }
  };
