import { AppNS } from 'app';
import axios from 'axios';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  apiGetClientCorporate,
  apiGetClientCorporateStats,
  apiSetClientCorporate,
} from 'app/api/client-corporate/client-corporate';

export interface ClientCorporateState {
  currentItem: AppNS.Params | null;
  isLoading: boolean;
  error: string;
  currentItemStats: AppNS.Params | null;
  isLoadingStats: boolean;
  updatedItem: AppNS.Params | null;
  isUpdating: Boolean;
  errorUpdating: string;
}

const initialState: ClientCorporateState = {
  currentItem: null,
  isLoading: false,
  error: '',
  currentItemStats: null,
  isLoadingStats: false,
  updatedItem: null,
  isUpdating: false,
  errorUpdating: '',
};

const clientCorporate = createSlice({
  name: 'clientCorporate',
  initialState,
  reducers: {
    resetCollection(state) {
      state.currentItem = null;
    },
    getClientCorporateStart(state: ClientCorporateState) {
      state.isLoading = true;
    },
    getClientCorporateSuccess(state: ClientCorporateState, action: any) {
      state.isLoading = false;
      state.currentItem = action.payload;
    },
    getClientCorporateFailed(
      state: ClientCorporateState,
      action: PayloadAction<string>
    ) {
      state.isLoadingStats = false;
      state.currentItemStats = null;
      state.error = action.payload;
    },
    getClientCorporateStatsStart(state: ClientCorporateState) {
      state.isLoadingStats = true;
    },
    getClientCorporateStatsSuccess(state: ClientCorporateState, action: any) {
      state.isLoadingStats = false;
      state.currentItemStats = action.payload;
    },
    getClientCorporateStatsFailed(
      state: ClientCorporateState,
      action: PayloadAction<string>
    ) {
      state.isLoadingStats = false;
      state.currentItemStats = null;
      state.error = action.payload;
    },
    setClientCorporateStart(state: ClientCorporateState) {
      state.isUpdating = true;
    },
    setClientCorporateSuccess(state: ClientCorporateState, action: any) {
      state.isUpdating = false;
      state.updatedItem = action.payload;
      state.errorUpdating = '';
    },
    setClientCorporateFailed(
      state: ClientCorporateState,
      action: PayloadAction<string>
    ) {
      state.isUpdating = false;
      state.updatedItem = null;
      state.errorUpdating = action.payload;
    },
  },
});

export const {
  getClientCorporateStart,
  getClientCorporateSuccess,
  getClientCorporateFailed,
  getClientCorporateStatsStart,
  getClientCorporateStatsSuccess,
  getClientCorporateStatsFailed,
  setClientCorporateStart,
  setClientCorporateSuccess,
  setClientCorporateFailed,
} = clientCorporate.actions;

export default clientCorporate.reducer;

export const fetchClientCorporateInfo = (): AppThunk => async (dispatch) => {
  try {
    const response = await apiGetClientCorporate();
    dispatch(getClientCorporateSuccess(response));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getClientCorporateFailed(err.toString()));
  }
};

export const fetchClientCorporateStats = (): AppThunk => async (dispatch) => {
  try {
    const response = await apiGetClientCorporateStats();
    dispatch(getClientCorporateStatsSuccess(response));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getClientCorporateStatsFailed(err.toString()));
  }
};

export const updateClientCorporateData =
  (formData: AppNS.Params): AppThunk =>
  async (dispatch) => {
    try {
      const response = await apiSetClientCorporate(formData);
      dispatch(setClientCorporateSuccess(response));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error('err', err);
      dispatch(setClientCorporateFailed(err));
    }
  };
