import { AppNS } from 'app';
import { parseAvatar, parseEvents } from '../common-parsers/common-parsers';

export const parseJob = (data: AppNS.Params): AppNS.ClientJob => {
  data.pickedHeroId = data.userId;
  data.jobSchedule = data.jobSchedules.map((item: AppNS.Params) => {
    if (!item.date) {
      return {
        properties: {
          dayOfWeek: item.dayOfWeek,
          timesForTheDay: item.timesForTheDay,
        },
      };
    } else {
      return {
        properties: {
          date: item.date,
          timesForTheDay: item.timesForTheDay,
        },
      };
    }
  });
  if (data.jobEvents) {
    data.jobEvents = parseEvents(data.jobEvents);
  }
  return data as AppNS.ClientJob;
};

export const parseJobLead = (data: AppNS.Params): AppNS.JobLead => {
  data.job = parseJob(data.job);
  data.client.avatar = parseAvatar(data.client.avatar);
  return data as AppNS.JobLead;
};

export const parseProposal = (data: AppNS.LeadProposal): AppNS.Params => {
  const proposal: AppNS.Params = { id: data.id, properties: {}, models: {} };
  proposal.properties.personalizedMessage = data.personalizedMessage;

  proposal.properties.fixedHourlyRate = data.fixedHourlyRate || data.initialHourlyRate;

  proposal.models.jobProposalEventChange = parseProposalEvents(data.events);

  return proposal;
};

export const parseProposalEvents = (
  data: Array<AppNS.LeadProposalEvent>
): AppNS.Params => {
  const parsedEvents = data.map((event: AppNS.LeadProposalEvent) => {
    if (event.notAvailable === false && event.timeFrom) {
      return {
        properties: {
          jobEventId: event.jobEventId,
          timeFrom: event.timeFrom,
          timeTo: event.timeTo,
        },
      };
    }
    if (event.notAvailable) {
      return {
        properties: {
          jobEventId: event.jobEventId,
          notAvailable: true,
        },
      };
    }
    return null;
  });

  return parsedEvents.filter((event) => event);
};
