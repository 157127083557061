import { ApiNS } from 'app/api/api';
import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../../core/config';
import { parseThread, parseMessage } from './messaging-parsers';

const THREADS = 'threads';
const MESSAGES = 'messages';

const CancelToken = axios.CancelToken;
let cancel: Canceler;
let cancel1: Canceler;
let cancel2: Canceler;

export function apiGetThreads(
  type: string,
  searchQuery: string,
  page: number,
  perPage: number = 20
): Promise<ApiNS.GetThreadsResponse> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${THREADS}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          query: searchQuery,
          page,
          archived: type === 'archived' ? true : false,
          per_page: perPage,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve({
            ...data,
            results: data.results.map((item: AppNS.Params) => {
              return parseThread(item);
            }),
          });
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetThread(
  type: string,
  id: string
): Promise<AppNS.MessageThread> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${THREADS}/${id}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetConciergeThreadId(): Promise<AppNS.MessageThread> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${THREADS}/concierge-thread.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data) {
          resolve(data.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiCreateThread(
  reqData: ApiNS.CreateThreadRequest
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${THREADS}.json`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiCreateMessage(
  reqData: ApiNS.CreateMessageRequest
): Promise<AppNS.Message> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${MESSAGES}`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetMessages(
  threadId: string,
  page: number
): Promise<ApiNS.GetMessagesResponse> {
  const params = {
    thread_id: threadId,
    page: page,
  };
  return getMessages(params);
}

export function apiCheckNewMessages(
  threadId: string,
  senderId: string,
  createdAfter: string
): Promise<ApiNS.GetMessagesResponse> {
  const params = {
    thread_id: threadId,
    not_sender_id: senderId,
    created_after: createdAfter,
  };
  return getMessages(params);
}

function getMessages(
  params: ApiNS.GetMessagesRequestParams
): Promise<ApiNS.GetMessagesResponse> {
  if (cancel1 !== undefined) {
    cancel1();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${MESSAGES}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel1 = c;
        }),
        params,
      })
      .then(({ data }) => {
        if (data) {
          resolve({
            ...data,
            results: data.results.map((result: AppNS.Params) =>
              parseMessage(result)
            ),
          });
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetMessageById(id: string): Promise<AppNS.Message> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${MESSAGES}`, {
        params: {
          id,
        },
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(parseMessage(data.model));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export const apiCheckUnreadThreadsCount = (): Promise<number> => {
  if (cancel2 !== undefined) {
    cancel2();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${THREADS}/check-unread`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel2 = c;
        }),
      })
      .then(({ data }) => {
        if (data) {
          resolve(data.totalEntries);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
};

export function apiArchiveThread(threadId: string): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${THREADS}.json`, { id: threadId })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export const apiGetJobDetailsId = (
  threadId: string
): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${THREADS}/get-link`, { params: { id: threadId } })
      .then(({ data }) => {
        if (data) {
          resolve(data.link);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
};
