import { createSlice } from '@reduxjs/toolkit';

import { AppNS } from '..';
import { AppThunk } from './index';
import { registerUser, apiSetClientDetails } from '../api/users';
import { updateUserData } from './auth';

export interface ClientOnboardingState {
  isRegisterClientLoading: boolean;
  isRegisterClientRequested: boolean;
  isRegisterClientSuccess: boolean;
  isDetailsClientRequested: boolean;
  isDetailsClientLoading: boolean;
  isDetailsClientSuccess: boolean;
}

const initialState: ClientOnboardingState = {
  isRegisterClientLoading: false,
  isRegisterClientRequested: false,
  isRegisterClientSuccess: false,
  isDetailsClientRequested: false,
  isDetailsClientLoading: false,
  isDetailsClientSuccess: false,
};

const clientOnboardingSlice = createSlice({
  name: 'clientOnboarding',
  initialState,
  reducers: {
    registerClientReset(state: ClientOnboardingState) {
      state.isRegisterClientRequested = false;
      state.isRegisterClientLoading = false;
      state.isRegisterClientSuccess = false;
    },
    registerClientStart(state: ClientOnboardingState) {
      state.isRegisterClientRequested = false;
      state.isRegisterClientLoading = true;
      state.isRegisterClientSuccess = false;
    },
    registerClientSuccess(state: ClientOnboardingState) {
      state.isRegisterClientRequested = true;
      state.isRegisterClientLoading = false;
      state.isRegisterClientSuccess = true;
    },
    registerClientFailed(state: ClientOnboardingState) {
      state.isRegisterClientRequested = true;
      state.isRegisterClientSuccess = false;
      state.isRegisterClientLoading = false;
    },
    detailsClientStart(state: ClientOnboardingState) {
      state.isDetailsClientRequested = false;
      state.isDetailsClientLoading = true;
      state.isDetailsClientSuccess = false;
    },
    detailsClientSuccess(state: ClientOnboardingState) {
      state.isDetailsClientRequested = true;
      state.isDetailsClientLoading = false;
      state.isDetailsClientSuccess = true;
    },
    detailsClientFailed(state: ClientOnboardingState) {
      state.isDetailsClientRequested = true;
      state.isDetailsClientSuccess = false;
      state.isDetailsClientLoading = false;
    },
  },
});

export const {
  registerClientFailed,
  registerClientStart,
  registerClientSuccess,
  detailsClientStart,
  detailsClientSuccess,
  detailsClientFailed,
  registerClientReset,
} = clientOnboardingSlice.actions;

export default clientOnboardingSlice.reducer;

export const registerClient = (params: AppNS.Params): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(registerClientStart());
    await registerUser(params);
    dispatch(registerClientSuccess());
  } catch (err) {
    console.error(err.toString());
    dispatch(registerClientFailed());
  }
};

export const setClientDetails = (params: AppNS.Params): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(detailsClientStart());
    const userData = await apiSetClientDetails(params);

    dispatch(
      updateUserData({
        ...userData.properties,
        addresses: [params.models.address[0].properties],
      })
    );

    dispatch(detailsClientSuccess());
  } catch (err) {
    console.error(err.toString());
    dispatch(detailsClientFailed());
  }
};
