import { createMuiTheme } from '@material-ui/core/styles';
import { GLOBAL_THEME } from './global';

export const OVERRIDES_THEME = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        minWidth: '96px',
        '&.fullWidth': {
          minWidth: '100%',
        },
        '&.success': {
          background: GLOBAL_THEME.palette.success.main,
        },
        '&.fail': {
          background: GLOBAL_THEME.palette.error.main,
        },
        '&.dark': {
          background: GLOBAL_THEME.palette.primary.dark,
        },
      },
      text: {
        fontWeight: 'normal',
        color: `${GLOBAL_THEME.palette.info.light} !important`,
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'none',
          background: 'none !important',
        },
        '&.Mui-disabled': {
          color: `${GLOBAL_THEME.palette.grey[500]} !important`,
          opacity: 0.7,
        },
      },
      outlined: {
        minWidth: '85px',
        color: '#0f7895',
        '&.error': {
          color: GLOBAL_THEME.palette.error.main,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&.error': {
          color: GLOBAL_THEME.palette.error.main,
        },
        '&.outlined': {
          border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        '&.filled': {
          color: GLOBAL_THEME.palette.grey['50'],
          backgroundColor: GLOBAL_THEME.palette.primary.main,
          padding: '10px',
        },
        '&.greyed': {
          color: GLOBAL_THEME.palette.grey['50'],
          backgroundColor: GLOBAL_THEME.palette.grey['500'],
          padding: '10px',
        },
        '&.active': {
          backgroundColor: `${GLOBAL_THEME.palette.secondary.main} !important`,
        },
        '&.fixed-size': {
          width: '40px',
          height: '40px',
        },
        '&.fixed-size-small': {
          width: '30px',
          height: '30px',
          fontSize: '1.6rem',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: `0px 3px 5px rgba(52, 61, 75, 0.05)`,
      },
    },
    MuiCard: {
      root: {
        marginBottom: GLOBAL_THEME.spacing(4),
        '&.card-type-dark': {
          color: GLOBAL_THEME.palette.common.white,
          background: GLOBAL_THEME.palette.primary.dark,
          '& a': {
            color: GLOBAL_THEME.palette.common.white,
          },
        },
        '&.card-type-success': {
          color: GLOBAL_THEME.palette.common.white,
          background: GLOBAL_THEME.palette.success.main,
        },
        '&.card-type-error': {
          color: GLOBAL_THEME.palette.common.white,
          background: GLOBAL_THEME.palette.error.main,
        },
        '&.card-type-info': {
          color: GLOBAL_THEME.palette.common.white,
          background: '#e27816',
          '& a': {
            color: GLOBAL_THEME.palette.common.white,
          },
        },
        '&.card-type-neutral': {
          color: GLOBAL_THEME.palette.common.white,
          background: GLOBAL_THEME.palette.grey[500],
          '& a': {
            color: GLOBAL_THEME.palette.common.white,
          },
        },
        '&.card-type-neutral-inversed': {
          color: GLOBAL_THEME.palette.grey[700],
          background: GLOBAL_THEME.palette.grey[400],
          '& svg': {
            color: `${GLOBAL_THEME.palette.grey[700]} !important`,
          },
        },
        '&.card-type-info-light': {
          color: GLOBAL_THEME.palette.grey[700],
          background: '#fff3e6',
          '& svg': {
            color: `${GLOBAL_THEME.palette.grey[700]} !important`,
          },
        },
        '&.left-no-radius': {
          borderRadius: `0 ${GLOBAL_THEME.shape.borderRadius}px ${GLOBAL_THEME.shape.borderRadius}px ${GLOBAL_THEME.shape.borderRadius}px`,
        },
        '&.right-no-radius': {
          borderRadius: `${GLOBAL_THEME.shape.borderRadius}px 0 ${GLOBAL_THEME.shape.borderRadius}px ${GLOBAL_THEME.shape.borderRadius}px`,
        },
        '&.overflow-visible': {
          overflow: 'visible',
        },
        '&.secondaryLevel': {
          background: GLOBAL_THEME.palette.grey[200],
        },
      },
    },
    MuiCardContent: {
      root: {
        padding: GLOBAL_THEME.spacing(4),
        [GLOBAL_THEME.breakpoints.down('sm')]: {
          padding: `${GLOBAL_THEME.spacing(3.5)}px ${GLOBAL_THEME.spacing(
            2.75
          )}px`,
        },
        '&.with-indicator': {
          position: 'relative',
          '&:before': {
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'block',
            content: '""',
            width: GLOBAL_THEME.spacing(0.5),
            height: `calc(100% - ${GLOBAL_THEME.spacing(4)}px)`,
            background: GLOBAL_THEME.palette.primary.dark,
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiInputLabel: {
      shrink: { transform: 'none' },
      formControl: {
        position: 'relative',
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 'bold',
        '&.Mui-focused': {
          color: GLOBAL_THEME.palette.primary.dark,
        },
      },
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: GLOBAL_THEME.spacing(0.8),
        },
      },
    },
    MuiInputBase: {
      root: {
        overflow: 'hidden',
        width: '100%',
        background: '#f2f3f4',
        borderRadius: GLOBAL_THEME.shape.borderRadius,
        border: `1px solid #67707e`,
        '&.Mui-error': {
          borderColor: GLOBAL_THEME.palette.error.main,
          borderWidth: 2,
          '& input': {
            height: `${GLOBAL_THEME.spacing(5) - 4}px`,
            paddingLeft: GLOBAL_THEME.spacing(2) - 1,
          },
          '& .MuiSelect-select': {
            height: `${GLOBAL_THEME.spacing(5) - 4}px`,
            paddingLeft: GLOBAL_THEME.spacing(2) - 1,
            lineHeight: `${GLOBAL_THEME.spacing(5) - 4}px`,
          },
        },
        '&.Mui-focused': {
          borderColor: GLOBAL_THEME.palette.text.primary,
          borderWidth: 2,
          '& input': {
            height: `${GLOBAL_THEME.spacing(5) - 4}px`,
            paddingLeft: GLOBAL_THEME.spacing(2) - 1,
          },
          '& .MuiSelect-select': {
            height: `${GLOBAL_THEME.spacing(5) - 4}px`,
            paddingLeft: GLOBAL_THEME.spacing(2) - 1,
            lineHeight: `${GLOBAL_THEME.spacing(5) - 4}px`,
          },
        },
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '&.Mui-disabled': {
          borderColor: GLOBAL_THEME.palette.divider,
        },
      },
      input: {
        padding: `0 0 0 ${GLOBAL_THEME.spacing(2)}px`,
        lineHeight: `${GLOBAL_THEME.spacing(5) - 2}px`,
        height: `${GLOBAL_THEME.spacing(5) - 2}px`,
        fontSize: '2rem',
        fontWeight: 600,
        color: GLOBAL_THEME.palette.text.primary,
      },
      multiline: {
        paddingLeft: GLOBAL_THEME.spacing(2),
        paddingRight: GLOBAL_THEME.spacing(2),
      },
    },
    MuiSelect: {
      icon: {
        right: GLOBAL_THEME.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 5,
        backgroundColor: GLOBAL_THEME.palette.text.primary,
        '& .MuiBox-root': {
          lineHeight: 1.33,
          fontSize: '1.2rem',
        },
        '& .MuiTooltip-arrow': { color: GLOBAL_THEME.palette.text.primary },
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${GLOBAL_THEME.spacing(2)}px ${GLOBAL_THEME.spacing(3)}px`,
      },
    },
    MuiTabs: {
      root: {
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          height: '1px',
          background: GLOBAL_THEME.palette.divider,
        },
        '&.round-filled:before': {
          display: 'none',
        },
        '&.round-filled.right-aligned .MuiTabs-flexContainer': {
          justifyContent: 'flex-end',
        },
        '&.round-filled .MuiTabs-indicator': {
          display: 'none',
        },

        '&.round-filled .MuiTab-root': {
          fontSize: '2rem',
          minHeight: '56px',
          padding: '0 30px',
          borderRadius: '20px 20px 0 0',
          border: '1px solid #e1e5e9',
          borderWidth: '1px 1px 0 1px',
          '&.Mui-selected': {
            borderColor: '#fff',
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: '0 !important',
        textTransform: 'none',
        fontSize: '1.6rem',
        fontWeight: 'normal',
        '&.Mui-selected': {
          color: GLOBAL_THEME.palette.primary.dark,
        },
      },
    },
    MuiContainer: {
      root: {
        [GLOBAL_THEME.breakpoints.up('lg')]: {
          padding: `0 ${GLOBAL_THEME.spacing(6)}px`,
        },
        '&.disableCenter': {
          margin: 0,
        },
        '&.negativeGutter-md': {
          [GLOBAL_THEME.breakpoints.down('sm')]: {
            margin: `0 -${GLOBAL_THEME.spacing(2)}px`,
            width: `calc(100% + ${GLOBAL_THEME.spacing(4)}px)`,
          },
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: GLOBAL_THEME.palette.grey[400],
        '&.flat': {
          borderRadius: '5px',
        },
        '&.no-label': {
          padding: '5px',
          '& .MuiChip-label': {
            display: 'none',
          },
          '& .MuiChip-icon': {
            margin: 0,
          },
        },
        '&.bold': {
          fontWeight: 'bold',
        },
        '&.dark': {
          backgroundColor: GLOBAL_THEME.palette.primary.dark,
          color: '#fff',
        },
        '&.white': {
          backgroundColor: GLOBAL_THEME.palette.common.white,
        },
        '&.upper': {
          textTransform: 'uppercase',
        },
        '&.success': {
          backgroundColor: GLOBAL_THEME.palette.success.light,
          border: `1px solid ${GLOBAL_THEME.palette.success.dark}`,
          color: GLOBAL_THEME.palette.success.dark,
        },
        '&.success-flat': {
          backgroundColor: GLOBAL_THEME.palette.success.main,
          color: GLOBAL_THEME.palette.common.white,
        },
      },
    },
    MuiAccordion: {
      root: {
        '&.noSummary': {
          '&.Mui-expanded': { margin: 0, padding: 0 },
          '& .MuiAccordionSummary-root': { display: 'none' },
          '&:before': { display: 'none' },
          '& .MuiExpanded': { margin: 0, padding: 0 },
          '& .MuiAccordionDetails-root': { display: 'block', padding: 0 },
        },
      },
    },
    MuiGrid: {
      item: {
        '&.with-h-divider': {
          borderLeft: `1px solid ${GLOBAL_THEME.palette.divider}`,
        },
        '&.with-v-divider': {
          borderBottom: `1px solid ${GLOBAL_THEME.palette.divider}`,
        },
      },
    },
    MuiStepper: {
      root: {
        '&.isWithoutBg': {
          background: 'none',
        },
      },
    },
    MuiStepIcon: {
      root: {
        width: GLOBAL_THEME.spacing(5),
        height: GLOBAL_THEME.spacing(5),
        marginLeft: -GLOBAL_THEME.spacing(1),
      },
    },
    MuiStepLabel: {
      iconContainer: {
        paddingRight: GLOBAL_THEME.spacing(2),
      },
      label: {
        color: '#1a2c4e !important',
        fontSize: '3.2rem',
        fontWeight: 'bold',
        fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        '&.clickable': {
          cursor: 'pointer',
        },
        '& .secondary-text': {
          fontSize: '2.4rem',
          fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(
            ','
          ),
          fontWeight: 'normal',
        },
      },
    },
    MuiStepContent: {
      root: {
        paddingLeft: GLOBAL_THEME.spacing(4.5),
        [GLOBAL_THEME.breakpoints.down('sm')]: {
          marginLeft: 0,
          paddingLeft: 0,
          border: 'none',
        },
      },
    },
    MuiStepConnector: {
      root: {
        display: 'block',
        [GLOBAL_THEME.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    MuiTable: {
      root: {
        '&.no-borders .MuiTableCell-root': {
          borderBottom: 'none',
        },
      },
    },
    MuiTableRow: {
      root: {},
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${GLOBAL_THEME.palette.divider}`,
        padding: GLOBAL_THEME.spacing(1),
        fontSize: '1.6rem',
        '&.with-border-bottom': {
          borderBottom: `1px solid ${GLOBAL_THEME.palette.divider} !important`,
        },
        '&.with-summary-border': {
          borderBottom: `2px solid ${GLOBAL_THEME.palette.primary.dark} !important`,
        },
        '&.with-summary-top-border': {
          borderTop: `2px solid ${GLOBAL_THEME.palette.primary.dark} !important`,
        },
      },
      head: {
        fontSize: '1.4rem',
        textTransform: 'uppercase',
        color: GLOBAL_THEME.palette.text.hint,
        fontWeight: 'normal',
      },
    },
    MuiDialog: {
      paper: {
        [GLOBAL_THEME.breakpoints.down('md')]: {
          margin: GLOBAL_THEME.spacing(0.5),
        },
      },
    },
  },
});
