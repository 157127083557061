import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './core/history/@history';
import store from './store';

import { create } from 'jss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, jssPreset, ThemeProvider } from '@material-ui/styles';
import AppLayout from './core/layout/AppLayout';
import mainTheme from './core/theme/mainTheme';
import { RouteGuard } from './core/guards/RouteGuard';
import { doTrack } from './services/trackingService';

doTrack(history.location.pathname);

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point') as HTMLElement,
});

function App() {
  return (
    <StylesProvider jss={jss}>
      <Provider store={store}>
        <Router history={history}>
          <RouteGuard>
            <ThemeProvider theme={mainTheme}>
              <CssBaseline />
              <AppLayout />
            </ThemeProvider>
          </RouteGuard>
        </Router>
      </Provider>
    </StylesProvider>
  );
}

export default App;
