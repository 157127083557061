import React from 'react';
import { withStyles, Theme } from '@material-ui/core';

import { useLocation } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';

import routes from '../config/routes';
import DefaultLayout from './DefaultLayout';
import LoginAsOverlay from './components/LoginAsOverlay';
import { getLoginAsAccessToken } from 'app/services/jwtService';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
});

interface AppLayoutProps {
  classes: {
    root: string;
  };
}

const AppLayout: React.FunctionComponent<AppLayoutProps> = (props) => {
  const { pathname } = useLocation();
  const matched = matchRoutes(routes, pathname)[0];

  const Layout = matched.route.layout || DefaultLayout;

  // check if 'login as' token is present
  // if so, add `login as` overlay info
  const loginAsToken = getLoginAsAccessToken();

  return (
    <>
      <Layout classes={{ root: props.classes.root }} />
      {loginAsToken && <LoginAsOverlay />}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(AppLayout);
