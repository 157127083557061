import { ApiNS } from 'app/api/api';
import { AppNS } from '..';
import axios from 'axios';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiGetReports } from 'app/api/hero-reports/hero-reports';

export interface ClientPaymentsListState {
  page: number;
  perPage: number;
  search: string;
  dateFrom: string;
  dateTo: string;
  totalEntries: number;
  totalAmount: number;
  totalSaving: number;
  items: Array<AppNS.JobEventReport>;
  isLeadLoading: boolean;
  isLoaded: boolean;
  isLoading: boolean;
}

const initialState: ClientPaymentsListState = {
  page: 1,
  perPage: 10,
  search: '',
  dateFrom: '',
  dateTo: '',
  totalEntries: 0,
  totalAmount: 0,
  totalSaving: 0,
  items: [],
  isLeadLoading: false,
  isLoaded: false,
  isLoading: false,
};

const heroPaymentsList = createSlice({
  name: 'heroPaymentsList',
  initialState,
  reducers: {
    resetCollection(state) {
      state.items = [];
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCurrentParams(state, action: PayloadAction<AppNS.Params>) {
      state.page = action.payload.page;
      state.search = action.payload.search;
      state.dateTo = action.payload.dateTo;
      state.dateFrom = action.payload.dateFrom;
    },
    getPaymentsStart(state: ClientPaymentsListState) {
      state.isLoading = true;
    },
    getPaymentsSuccess(
      state: ClientPaymentsListState,
      action: PayloadAction<ApiNS.ReportsListResponse>
    ) {
      // state.error = null;
      state.isLoading = false;
      state.items = action.payload.results;
      state.totalEntries = action.payload.totalEntries;
      state.totalAmount = action.payload.totalAmount || 0;
      state.totalSaving = action.payload.totalSaving || 0;
    },
    getPaymentsFailed(state: ClientPaymentsListState) {
      state.items = [];
      // state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  getPaymentsStart,
  getPaymentsSuccess,
  getPaymentsFailed,
  setCurrentParams,
} = heroPaymentsList.actions;

export default heroPaymentsList.reducer;

export const getPayments = (
  searchQuery: string,
  dateFrom: string,
  dateTo: string,
  page: number
): AppThunk => async (dispatch) => {
  try {
    dispatch(getPaymentsStart());
    const result = await apiGetReports(searchQuery, dateFrom, dateTo, page);
    dispatch(getPaymentsSuccess(result));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getPaymentsFailed());
  }
};
