import { AppNS } from 'app';

export const ifClientDuringOnboarding = (userData: AppNS.User): boolean => {
  if (userData.onboardingStep === 'background_check') {
    return true;
  }
  return false;
};

export const ifClientApproved = (userData: AppNS.User): boolean => {
  if (userData.approvalStatus === 'passed') {
    return true;
  }
  return false;
};
