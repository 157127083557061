import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from 'app/store';

interface HasToBeHeroOnboardingStep2GuardProps {
  children: React.ReactNode;
}

export const HasToBeHeroOnboardingStep2Guard = (
  props: HasToBeHeroOnboardingStep2GuardProps
) => {
  const { children } = props;

  const user = useSelector(({ auth }: AppState) => auth.user);

  if (user) {
    if (user.onboardingStep === 'qualifications') {
      return <>{children}</>;
    } else if (user.onboardingStep === 'screening_interview') {
      return <Redirect to={'/hero/onboarding/schedule-interview'}></Redirect>;
    } else {
      return <Redirect to={'/hero/dashboard'}></Redirect>;
    }
  }

  return <Redirect to={'/auth/login'}></Redirect>;
};

export default HasToBeHeroOnboardingStep2Guard;
