import axios from 'axios';
import { AppNS } from '..';
import { API_URL } from '../core/config';

export const FILE_UPLOADS_URL = `${API_URL}/file_uploads.json`;
export const VIMEO_UPLOADS_URL = `${API_URL}/vimeo_uploads.json`;

export function getPresignedUrl(
  modelName: string,
  propertyName: string
): Promise<AppNS.UploadPresign> {
  return new Promise((resolve, reject) => {
    axios
      .get(FILE_UPLOADS_URL, {
        params: { model_name: modelName, property_name: propertyName },
      })
      .then(({ data }) => {
        if (data) {
          // TODO: store presigned url - get to know how long
          resolve({
            method: 'POST',
            url: data.upload_url,
            fields: data.upload_url_payload,
          });
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function getVimeoPresignedUrl(fileSize: number): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .get(VIMEO_UPLOADS_URL, {
        params: { file_size: fileSize },
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
