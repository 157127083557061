import React, { useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { useDispatch, useSelector } from 'react-redux';

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import routes from '../config/routes';
import AppSuspense from '../../ui/components/AppSuspense';
import ToolbarLayout from './components/ToolbarLayout';
import { setNoSidebarLayoutStyle } from 'app/store/ui';
import { RootState } from 'app/store/rootReducer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  content: {
    position: 'relative',
    display: 'flex',
    overflow: 'auto',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    '-webkit-overflow-scrolling': 'touch',
    zIndex: 2,
    marginTop: 64,
  },
}));

export default function NoSidebar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(setNoSidebarLayoutStyle());
  }, []);

  return (
    <div className={classes.root}>
      <ToolbarLayout
        isUserLogged={user ? true : false}
        isWithoutSidebar={true}
      />
      <main className={classes.content}>
        <AppSuspense>{renderRoutes(routes)}</AppSuspense>
      </main>
    </div>
  );
}
