import React from 'react';
import { Redirect } from 'react-router';
import NoSidebar from '../../layout/NoSidebar';
import { HasToBeLoggedInGuard } from '../../guards/HasToBeLoggedInGuard';
import { CannotBeLoggedInGuard } from '../../guards/CannotBeLoggedInGuard';
import { HasToBeHeroGuard } from '../../guards/HasToBeHeroGuard';
import HasToPassOnboarding from '../../guards/HasToPassOnboarding';
import HasToBeHeroOnboardingStep2Guard from '../../guards/HasToBeHeroOnboardingStep2Guard';
import HasToBeHeroOnboardingStep3Guard from '../../guards/HasToBeHeroOnboardingStep3Guard';
import CheckIsLoggedInGuard from 'app/core/guards/CheckIsLoggedInGuard';

const heroRoutes = [
  // CLAIM ACCOUNT 
  {
    path: '/hero/onboarding/claim-account',
    component: React.lazy(
      () => import('../../../main/hero/onboarding/step1/HeroOnboardingStep1')
    ),
    canActivate: [HasToBeLoggedInGuard],
    layout: NoSidebar,
  },

  // ONBOARDING
  {
    path: '/hero/onboarding/step1',
    component: React.lazy(
      () => import('../../../main/hero/onboarding/step1/HeroOnboardingStep1')
    ),
    canActivate: [CannotBeLoggedInGuard],
    layout: NoSidebar,
  },
  {
    path: '/hero/onboarding/qualifications',
    component: React.lazy(
      () => import('../../../main/hero/onboarding/step2/HeroOnboardingStep2')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToBeHeroOnboardingStep2Guard,
    ],
    layout: NoSidebar,
  },
  {
    path: '/hero/onboarding/schedule-interview',
    component: React.lazy(
      () => import('../../../main/hero/onboarding/step3/HeroOnboardingStep3')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToBeHeroOnboardingStep3Guard,
    ],
    layout: NoSidebar,
  },
  {
    path: '/hero/onboarding/schedule-orientation-interview',
    component: React.lazy(
      () =>
        import(
          '../../../main/hero/onboarding/schedule-orientation-interview/ScheduleOrientationInterview'
        )
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },
  {
    path: '/hero/onboarding/reschedule-interview',
    component: React.lazy(
      () => import('../../../main/hero/onboarding/reschedule-interview/RescheduleInterview')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },

  // DASHBOARD
  {
    path: '/hero',
    exact: true,
    component: () => <Redirect to={'/hero/dashboard'} />,
  },
  {
    path: '/hero/dashboard',
    component: React.lazy(
      () => import('../../../main/hero/dashboard/HeroDashboard')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },
  {
    path: '/hero/schedule',
    exact: true,
    component: () => <Redirect to={'/hero/schedule/week'} />,
  },
  {
    path: '/hero/schedule/:id',
    exact: true,
    component: React.lazy(
      () => import('../../../main/hero/schedule/HeroSchedulePage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding,
    ],
  },

  // PROFILE
  {
    path: '/hero/profile',
    exact: true,
    component: () => <Redirect to={'/hero/profile/start'} />,
  },
  {
    path: '/hero/profile/:id',
    component: React.lazy(
      () => import('../../../main/hero/profile/HeroProfilePage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },

  // HERO PUBLIC PROFILE
  {
    path: '/hero/public/:id/:careType/:authIdType/:authId',
    exact: true,
    component: React.lazy(
      () => import('../../../main/hero/public-profile/PublicProfile')
    ),
    canActivate: [CheckIsLoggedInGuard],
  },
  {
    path: '/hero/public/:id/:careType',
    exact: true,
    component: React.lazy(
      () => import('../../../main/hero/public-profile/PublicProfile')
    ),
    canActivate: [CheckIsLoggedInGuard],
  },

  // JOB OPPORTUNITIES SECTION
  {
    path: '/hero/job-opportunities/details/:leadId',
    component: React.lazy(
      () => import('../../../main/hero/job-opportunities/details/JobOpportunityDetailsPage')
    ),
    exact: true,
        canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding,
    ],
  },
  {
    path: '/hero/job-opportunities/proposal/:leadId',
    component: React.lazy(
      () => import('../../../main/hero/job-opportunities/proposal/HeroProposalPage')
    ),
    exact: true,
        canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding,
    ],
  },
  {
    path: '/hero/job-opportunities',
    component: React.lazy(
      () => import('../../../main/hero/job-opportunities/JobOpportunitiesPage')
    ),
        canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding,
    ],
  },

  // BOOKINGS SECTION
  {
    path: '/hero/bookings',
    exact: true,
    component: () => <Redirect to={'/hero/bookings/confirmed-jobs'} />,
  },
  {
    path: '/hero/bookings/:sectionId/details/:leadId',
    component: React.lazy(
      () => import('../../../main/hero/bookings/details/HeroBookingDetailsPage')
    ),
    exact: true,
        canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },
  {
    path: '/hero/bookings/:sectionId/report/:reportId',
    component: React.lazy(
      () =>
        import(
          '../../../main/hero/bookings/report-details/HeroReportDetailsPage'
        )
    ),
    exact: true,
        canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },
  {
    path: '/hero/bookings/:sectionId',
    component: React.lazy(
      () => import('../../../main/hero/bookings/HeroBookingsPage')
    ),
        canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },

  // MESSAGING SECTION
  {
    path: '/hmi',
    exact: true,
    component: () => <Redirect to={'/hero/messaging/inbox'} />,
  },
  {
    path: '/hmi/:id',
    exact: true,
    component: (props: any) => (
      <Redirect to={`/hero/messaging/inbox/${props.match.params.id}`} />
    ),
  },
  {
    path: '/hero/messaging',
    exact: true,
    component: () => <Redirect to={'/hero/messaging/inbox'} />,
  },
  {
    path: [
      '/hero/messaging/create-thread/:recipientId',
      '/hero/messaging/create-thread/:recipientId/:jobId',
    ],
    component: React.lazy(
      () => import('../../../main/hero/messaging/HeroCreateThreadPage')
    ),
    exact: true,
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },
  {
    path: '/hero/messaging/:sectionId',
    component: React.lazy(
      () => import('../../../main/hero/messaging/HeroMessagingPage')
    ),
    exact: true,
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },
  {
    path: '/hero/messaging/:sectionId/:threadId',
    component: React.lazy(
      () => import('../../../main/hero/messaging/HeroMessagingPage')
    ),
    exact: true,
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },
  {
    path: '/hero/notifications/:messageId',
    component: React.lazy(
      () => import('../../../main/hero/messaging/HeroNotificationLandingPage')
    ),
    exact: true,
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },

  // SETTINGS SECTION
  {
    path: '/hero/settings',
    exact: true,
    component: () => <Redirect to={'/hero/settings/general'} />,
  },
  {
    path: '/hero/settings/:sectionId',
    component: React.lazy(
      () => import('../../../main/hero/settings/HeroSettingsPage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },

  // PAYMENTS
  {
    path: '/hero/payments',
    exact: true,
    component: () => <Redirect to={'/hero/payments/payments-list'} />,
  },
  {
    path: '/hero/payments/:id',
    component: React.lazy(
      () => import('../../../main/hero/payments/HeroPaymentsPage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },

  // REVIEWS
  {
    path: '/hero/reviews',
    exact: true,
    component: React.lazy(
      () => import('../../../main/hero/reviews/HeroReviewsPage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },

  // WELCOME SECTION
  {
    path: '/hero/welcome',
    exact: true,
    component: React.lazy(
      () => import('../../../main/hero/welcome/Welcome')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeHeroGuard,
      HasToPassOnboarding
    ],
  },
];

export default heroRoutes;
