import { PayloadAction } from '@reduxjs/toolkit';
import { AppNS } from 'app';
import { AuthState } from './../../auth';

export const AVATAR_REDUCERS = {
  setAvatarStart(state: AuthState) {
    state.isChangingAvatar = true;
    state.isChangingAvatarError = false;
  },
  setAvatarFailed(state: AuthState) {
    state.isChangingAvatarError = true;
  },
  setAvatarSuccess(state: AuthState, action: PayloadAction<AppNS.Avatar>) {
    state.isChangingAvatar = false;
    state.isChangingAvatarError = false;
    if (state.user) {
      state.user.avatar = action.payload;
    }
  },
};
