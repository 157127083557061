import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../core/config';

const URL = 'documents.json';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function getDocuments(params: AppNS.Params): Promise<any> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params,
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(parseDocumentsData(data.results));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function createDocument(reqData: AppNS.Params): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${URL}`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function updateDocument(reqData: AppNS.Params): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${URL}?return_properties=true`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function deleteDocument(reqData: AppNS.Params): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/${URL}`, { params: reqData })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

const parseDocumentsData = (
  data: Array<AppNS.Params>
): Array<AppNS.Document> => {
  return data.map((item: AppNS.Params) => {
    const document = { ...item };

    delete document.file;
    delete document.fileUrl;

    document.file = {
      uploadUrl: item.fileUrl.url,
    };

    return document as AppNS.Document;
  });
};
