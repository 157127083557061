import { AppNS } from 'app';
import {
  isEventInProgess,
  isEventPast,
} from './../../ui/modules/job-events/eventsHelper';

export const parseAvatar = (avatarParams: AppNS.Params): string => {
  if (avatarParams) {
    return avatarParams.versions.small;
  }
  return '';
};

export const parseEvents = (
  events: Array<AppNS.JobEvent>
): Array<AppNS.JobEvent> => {
  return events.map((event) => {
    const state = parseEventStateByDate(event);
    return { ...event, state };
  });
};

export const parseEventStateByDate = (
  jobEvent: AppNS.JobEvent
): AppNS.JobEventState => {
  if (jobEvent.state === 'upcoming') {
    const isInProgress = isEventInProgess(
      jobEvent.date,
      jobEvent.timeFrom,
      jobEvent.timeTo
    );
    const isPast = isEventPast(jobEvent.date, jobEvent.timeTo);

    if (isInProgress || isPast) {
      return 'in_progress';
    }
  }
  return jobEvent?.state || 'upcoming';
};

export const parseReportStateByDate = (
  jobEvent: AppNS.JobEvent,
  reportState: AppNS.JobReportState
): AppNS.JobReportState => {
  if (reportState === 'upcoming') {
    const isInProgress = isEventInProgess(
      jobEvent.date,
      jobEvent.timeFrom,
      jobEvent.timeTo
    );
    const isPast = isEventPast(jobEvent.date, jobEvent.timeTo);

    if (isInProgress || isPast) {
      return 'in_progress';
    }
  }
  return reportState;
};
