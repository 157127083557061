import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function MessagingIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 25" fill="none">
      <g fill="none">
        <path
          d="M23.3811 16.75V19.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.4187 19.75V9.25C2.4187 8.42157 3.14064 7.75 4.0312 7.75H8.06245"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6777 19.75C15.1081 20.6774 14.048 21.2497 12.8999 21.2497C11.7518 21.2497 10.6917 20.6774 10.1221 19.75H0.806152V21.25C0.806152 22.9069 2.25003 24.25 4.03115 24.25H21.7687C23.5498 24.25 24.9937 22.9069 24.9937 21.25V19.75H15.6777Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0584 2.01078C17.4745 1.29252 14.6781 2.05355 12.9179 3.95406C11.1576 5.85458 10.762 8.53982 11.9078 10.8108L9.68896 14.9998L14.7662 13.7098C15.214 13.9485 15.6898 14.1387 16.1841 14.2768C19.8012 15.2072 23.5517 13.2659 24.6087 9.91619C25.6656 6.5665 23.638 3.04754 20.0541 2.01178L20.0584 2.01078Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.1323 6.65625C16.1323 5.62072 17.0348 4.78125 18.1479 4.78125C19.2611 4.78125 20.1636 5.62072 20.1636 6.65625C20.1636 7.69178 19.2611 8.53125 18.1479 8.53125"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.148 10.7813C17.9254 10.7813 17.7449 10.9491 17.7449 11.1563C17.7449 11.3634 17.9254 11.5313 18.148 11.5313C18.3706 11.5313 18.5511 11.3634 18.5511 11.1563C18.5514 11.0567 18.509 10.9612 18.4334 10.8908C18.3577 10.8204 18.255 10.781 18.148 10.7813V10.7813"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
