import { ApiNS } from 'app/api/api';
import { AppNS } from '..';
import moment from 'moment';
import { orderBy } from 'lodash-es';
import axios from 'axios';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  apiGetThreads,
  apiGetThread,
  apiCreateThread,
  apiCreateMessage,
  apiGetMessages,
  apiCheckNewMessages,
  apiCheckUnreadThreadsCount,
  apiArchiveThread,
  apiGetJobDetailsId,
} from '../api/messaging/messaging';
import { checkIsDateNewer } from './../helpers/checkIsDateNewer';

export type LoadingType = 'firstLoad' | 'pageLoad' | '';

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export interface MessagesState {
  // THREADS
  page: number;
  perPage: number;
  search: string;
  totalPages: number;
  items: Array<AppNS.MessageThread>;

  currentThread: AppNS.MessageThread | null;
  isThreadLoading: boolean;
  isLoaded: boolean;
  isLoading: LoadingType;
  isThreadLoadingError: boolean;
  isDeleteEventLoading: boolean;
  isDeleteEventError: boolean;
  isLeadStateLoadingError: boolean;
  isLeadStateLoading: boolean;

  isCancellingJob: boolean;
  isCancellingJobError: boolean;
  isJobCancelled: string;

  newCreatedThreadId: string | null;
  isThreadCreating: boolean;
  isThreadCreatingError: boolean;

  isMessageCreating: boolean;
  isMessageCreatingError: boolean;

  isMessagesLoading: boolean;
  isMessagesLoadingError: boolean;

  senderId: string;
  unreadCount: number;

  isMessagingPageOn: boolean;

  isThreadStatusChanging: string;
  isThreadStatusChangingError: boolean;

  userRole: AppNS.Role | '';
  ifThreadNotFound: boolean;
}

const initialState: MessagesState = {
  page: 1,
  perPage: 20,
  search: '',
  currentThread: null,
  totalPages: 0,
  items: [],
  isThreadLoading: false,
  isThreadLoadingError: false,
  isDeleteEventLoading: false,
  isDeleteEventError: false,
  isLeadStateLoadingError: false,
  isLeadStateLoading: false,
  isLoaded: false,
  isLoading: '',
  isCancellingJob: false,
  isCancellingJobError: false,
  isJobCancelled: '',
  newCreatedThreadId: null,
  isThreadCreating: false,
  isThreadCreatingError: false,
  isMessageCreating: false,
  isMessageCreatingError: false,
  isMessagesLoading: false,
  isMessagesLoadingError: false,
  senderId: '',
  unreadCount: 0,
  isMessagingPageOn: false,
  isThreadStatusChanging: '',
  isThreadStatusChangingError: false,
  userRole: '',
  ifThreadNotFound: false,
};

function appendMessage(state: MessagesState, message: AppNS.Message) {
  if (state.currentThread) {
    const exists = state.currentThread.messages.find(
      (msg: AppNS.Message) =>
        msg.id === message.id && msg.threadId === state.currentThread?.id
    );
    if (!exists) {
      state.currentThread.messages.push(message);
      state.currentThread.lastMessage = message;
      state.currentThread.lastMessageAt = message.createdAt;
    }
  }
}

function prependMessage(state: MessagesState, message: AppNS.Message) {
  if (state.currentThread) {
    const exists = state.currentThread.messages.find(
      (msg: AppNS.Message) => msg.id === message.id
    );
    if (!exists) {
      state.currentThread.messages = [message, ...state.currentThread.messages];
    }
  }
}

function sortThreadsByDate(
  items: Array<AppNS.MessageThread>
): Array<AppNS.MessageThread> {
  return orderBy(
    items,
    (item: AppNS.MessageThread) => {
      let date = item.lastMessageAt;

      // corner case when there's no messages in thread,
      if (!item.lastMessageAt) {
        date = '2020-01-01 01:01:01';
      }

      // for Concierge (admin) threads return date from future that it will be always pinned at first position
      if (item.participantIds.includes('admin')) {
        return moment().add(1, 'day').valueOf();
      }
      return moment(date, DATETIME_FORMAT).valueOf();
    },
    'desc'
  );
}

function sortMessagesByDate(items: Array<AppNS.Message>): Array<AppNS.Message> {
  return orderBy(
    items,
    (item: AppNS.Message) => {
      let date = item.createdAt;
      return moment(date, DATETIME_FORMAT).valueOf();
    },
    'asc'
  );
}

function addThreads(state: MessagesState, threads: Array<AppNS.MessageThread>) {
  if (state.items.length) {
    threads.forEach((incomingThread: AppNS.MessageThread) => {
      if (
        state.items.find(
          (thread: AppNS.MessageThread) => thread.id === incomingThread.id
        )
      ) {
        updateThread(state, incomingThread);
      } else {
        state.items = [...state.items, incomingThread];
      }
    });
    state.items = sortThreadsByDate(state.items);
  } else {
    state.items = threads;
  }

  countUnreads(state);
}

function updateThread(
  state: MessagesState,
  incomingThread: AppNS.MessageThread
) {
  if (state.items.length) {
    state.items = state.items.map((thread: AppNS.MessageThread) => {
      if (
        thread.id === incomingThread.id &&
        checkIsDateNewer(thread.lastMessageAt, incomingThread.lastMessageAt)
      ) {
        return incomingThread;
      }
      return thread;
    });
  }
}

function countUnreads(state: MessagesState) {
  let counter = 0;
  if (state.items.length) {
    state.items.forEach((item: AppNS.MessageThread) => {
      if (!item.readFor.includes(state.senderId)) {
        counter++;
      }
    });
  }
  state.unreadCount = counter;
}

function decreaseMessageCounter(state: MessagesState) {
  if (state.unreadCount > 0) {
    state.unreadCount = state.unreadCount - 1;
  }
}

const messaging = createSlice({
  name: 'messaging',
  initialState,
  reducers: {
    resetCollection(state) {
      state.items = [];
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCurrentParams(state, action: PayloadAction<AppNS.Params>) {
      state.search = action.payload.search;
    },
    getThreadsStart(state: MessagesState, action: PayloadAction<LoadingType>) {
      state.isLoading = action.payload;
    },
    getThreadsSuccess(
      state: MessagesState,
      action: PayloadAction<ApiNS.GetThreadsResponse>
    ) {
      // state.error = null;
      state.isLoading = '';
      addThreads(state, action.payload.results);
      state.totalPages = action.payload.totalPages;
    },
    getThreadsFailed(state: MessagesState, action: PayloadAction<string>) {
      state.items = [];
      // state.error = action.payload;
      state.isLoading = '';
    },
    getThreadStart(state: MessagesState) {
      state.currentThread = null;
      state.isLoaded = false;
      state.isThreadLoading = true;
      state.isThreadLoadingError = false;
    },
    getCurrentThreadSuccess(
      state: MessagesState,
      action: PayloadAction<AppNS.MessageThread>
    ) {
      state.isThreadLoadingError = false;
      state.isThreadLoading = false;
      state.currentThread = action.payload;
    },
    getThreadFailed(state: MessagesState) {
      state.isThreadLoadingError = true;
      state.isThreadLoading = false;
    },
    pickCurrentThread(state: MessagesState, action: PayloadAction<string>) {
      if (
        state.items.length > 0 &&
        action.payload !== state.currentThread?.id
      ) {
        let currentThread: AppNS.MessageThread | null = null;
        state.items = state.items.map((thread: AppNS.MessageThread) => {
          if (thread.id === action.payload) {
            currentThread = thread;
            currentThread.currentPage = 1;
            if (!thread.readFor.includes(state.senderId)) {
              decreaseMessageCounter(state);
              return {
                ...thread,
                readFor: [...thread.readFor, state.senderId],
              };
            }
          }
          return thread;
        });
        if (!currentThread) {
          state.ifThreadNotFound = true;
        }
        state.currentThread = currentThread;
      }
    },
    removeCurrentThread(state: MessagesState) {
      state.currentThread = null;
    },
    createThreadStart(state: MessagesState) {
      state.newCreatedThreadId = null;
      state.isThreadCreating = true;
      state.isThreadCreatingError = false;
    },
    createThreadSuccess(state: MessagesState, action: PayloadAction<string>) {
      state.isThreadLoadingError = false;
      state.isThreadLoading = false;
      state.newCreatedThreadId = action.payload;
    },
    createThreadFailed(state: MessagesState) {
      state.isThreadCreatingError = true;
      state.isThreadCreating = false;
    },
    createMessageStart(state: MessagesState) {
      state.isMessageCreating = true;
      state.isMessageCreatingError = false;
    },
    createMessageSuccess(
      state: MessagesState,
      action: PayloadAction<AppNS.Message>
    ) {
      state.isMessageCreating = false;
      state.isMessageCreatingError = false;
      appendMessage(state, action.payload);
    },
    createMessageFailed(state: MessagesState) {
      state.isMessageCreatingError = true;
      state.isMessageCreating = false;
    },
    getMessagesStart(state: MessagesState) {
      state.isMessagesLoading = true;
      state.isMessagesLoadingError = false;
    },
    getMessagesSuccess(
      state: MessagesState,
      action: PayloadAction<{
        result: ApiNS.GetMessagesResponse;
        action: 'prepend' | 'replace';
        detailsJobId: string | null;
        threadId: string;
      }>
    ) {
      if (
        state.currentThread &&
        state.currentThread?.id === action.payload.threadId
      ) {
        state.isMessagesLoading = false;
        state.isMessagesLoadingError = false;
        if (action.payload.action === 'replace') {
          state.currentThread.messages = sortMessagesByDate(
            action.payload.result.results
          );
          state.currentThread.totalPages = action.payload.result.totalPages;
          state.currentThread.detailsJobId = action.payload.detailsJobId;
        } else {
          action.payload.result.results.forEach((msg: AppNS.Message) =>
            prependMessage(state, msg)
          );
        }
      }
    },
    getMessagesFailed(state: MessagesState) {
      state.isMessagesLoadingError = true;
      state.isMessagesLoading = false;
    },
    setSenderId(state: MessagesState, action: PayloadAction<string>) {
      state.senderId = action.payload;
    },
    checkMessagesSuccess(
      state: MessagesState,
      action: PayloadAction<{
        threadId: string;
        response: ApiNS.GetMessagesResponse;
      }>
    ) {
      action.payload.response.results.forEach((msg: AppNS.Message) => {
        appendMessage(state, msg);
      });

      state.isMessagesLoading = false;
    },
    checkMessagesFailed(state: MessagesState) {},
    checkUnreadThreadsSuccess(
      state: MessagesState,
      action: PayloadAction<number>
    ) {
      state.unreadCount = action.payload;
    },

    setMessagingPageState(
      state: MessagesState,
      action: PayloadAction<boolean>
    ) {
      state.isMessagingPageOn = action.payload;
    },
    resetThreads(state: MessagesState) {
      state.items = [];
      state.currentThread = null;
      state.newCreatedThreadId = null;
    },
    setThreadStatusStart(state: MessagesState, action: PayloadAction<string>) {
      state.isThreadStatusChanging = action.payload;
      state.isThreadStatusChangingError = false;
    },
    setThreadStatusSuccess(
      state: MessagesState,
      action: PayloadAction<string>
    ) {
      state.isThreadStatusChanging = '';
      state.isThreadStatusChangingError = false;

      if (state.items.length > 0) {
        state.items = state.items.filter(
          (item: AppNS.MessageThread) => item.id !== action.payload
        );
        state.currentThread = null;
      }
    },
    setThreadStatusFailed(state: MessagesState) {
      state.isMessageCreatingError = true;
      state.isMessageCreating = false;
    },
    setAnotherPage(state: MessagesState) {
      if (state.currentThread) {
        state.currentThread.currentPage = state.currentThread.currentPage + 1;
      }
    },
    setAnotherThreadsPage(state: MessagesState) {
      state.page = state.page + 1;
    },
    setUserRole(state: MessagesState, action: PayloadAction<AppNS.Role>) {
      state.userRole = action.payload;
    },
    setIfThreadNotFound(state: MessagesState, action: PayloadAction<boolean>) {
      state.ifThreadNotFound = action.payload;
    },
  },
});

export const {
  checkMessagesFailed,
  checkMessagesSuccess,
  checkUnreadThreadsSuccess,
  createMessageFailed,
  createMessageStart,
  createMessageSuccess,
  createThreadFailed,
  createThreadStart,
  createThreadSuccess,
  getCurrentThreadSuccess,
  getMessagesFailed,
  getMessagesStart,
  getMessagesSuccess,
  getThreadFailed,
  getThreadsStart,
  getThreadsSuccess,
  getThreadStart,
  pickCurrentThread,
  removeCurrentThread,
  setCurrentParams,
  setMessagingPageState,
  setSenderId,
  resetThreads,
  setThreadStatusFailed,
  setThreadStatusStart,
  setThreadStatusSuccess,
  setAnotherPage,
  setAnotherThreadsPage,
  setUserRole,
  setIfThreadNotFound,
} = messaging.actions;

export default messaging.reducer;

export const getThreads =
  (
    type: string,
    searchQuery: string,
    page: number,
    loadingType: LoadingType,
    threadId: string = ''
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (loadingType !== '') {
        dispatch(getThreadsStart(loadingType));
      }
      const result = await apiGetThreads(
        type,
        searchQuery,
        page,
        loadingType !== '' ? 20 : 3
      );
      dispatch(getThreadsSuccess(result));
      if (threadId) {
        dispatch(pickCurrentThread(threadId));
      }
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getThreadFailed());
    }
  };

export const getCurrentThread =
  (type: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getThreadStart());
      const result = await apiGetThread(type, id);
      dispatch(getCurrentThreadSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getThreadFailed());
    }
  };

export const createThread =
  (recipientId: string, jobId: string | null): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createThreadStart());
      const request = {
        properties: {
          recipientId,
          jobId: jobId || '',
        },
      };
      const result = await apiCreateThread(request);
      dispatch(createThreadSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(createThreadFailed());
    }
  };

export const createMessage =
  (
    recipientId: string,
    jobId: string | null,
    body: string,
    includeConcierge: boolean = false
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createMessageStart());
      const request = {
        properties: {
          recipientId,
          jobId: jobId || '',
          body,
          includeConcierge,
        },
      };
      const result = await apiCreateMessage(request);
      dispatch(createMessageSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(createMessageFailed());
    }
  };

export const getMessages =
  (
    threadId: string,
    page: number,
    action: 'prepend' | 'replace',
    isSilent?: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      let detailsJobId = null;
      if (!isSilent) {
        dispatch(getMessagesStart());
        detailsJobId = await apiGetJobDetailsId(threadId);
      }
      if (action === 'replace') {
        detailsJobId = await apiGetJobDetailsId(threadId);
      }
      const result = await apiGetMessages(threadId, page);

      dispatch(getMessagesSuccess({ threadId, result, action, detailsJobId }));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getMessagesFailed());
    }
  };

export const checkMessages =
  (threadId: string, senderId: string, createdAfter: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await apiCheckNewMessages(
        threadId,
        senderId,
        createdAfter
      );
      dispatch(checkMessagesSuccess({ threadId, response }));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(checkMessagesFailed());
    }
  };

export const checkUnreadThreadsMessages = (): AppThunk => async (dispatch) => {
  try {
    const result = await apiCheckUnreadThreadsCount();
    dispatch(checkUnreadThreadsSuccess(result));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(checkMessagesFailed());
  }
};

export const archiveThread =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setThreadStatusStart(id));
      const result = await apiArchiveThread(id);
      dispatch(setThreadStatusSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(setThreadStatusFailed());
    }
  };
