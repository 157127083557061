import React from 'react';
import { AppNS } from 'app';
import DashboardIcon from 'app/ui/icons/DashboardIcon';
import JobOpportunitiesIcon from 'app/ui/icons/JobOpportunitiesIcon';
import BookingsIcon from 'app/ui/icons/BookingsIcon';
import MessagingIcon from 'app/ui/icons/MessagingIcon';
import PaymentsIcon from 'app/ui/icons/PaymentsIcon';
import FavouritesIcon from 'app/ui/icons/FavouritesIcon';
import ReviewsIcon from 'app/ui/icons/ReviewsIcon';
import ProfileIcon from 'app/ui/icons/ProfileIcon';
import SettingsIcon from 'app/ui/icons/SettingsIcon';
import HowItWorksIcon from 'app/ui/icons/HowItWorksIcon';
import SearchHeartIcon from 'app/ui/icons/SearchHeartIcon';
import BrowseIcon from 'app/ui/icons/BrowseIcon';
import ResourcesIcon from 'app/ui/icons/ResourcesIcon';
import {
  ifHeroBackgroundChecked,
  ifHeroCompletedFirstMilestone,
  ifHeroDuringOnboarding,
} from 'app/core/guards/rules/heroRules';
import {
  ifClientApproved,
  ifClientDuringOnboarding,
} from 'app/core/guards/rules/clientRules';
import BuyGiftCardIcon from 'app/ui/icons/BuyGiftCardIcon';

export const getNavigationConfig = (
  user: AppNS.User
): AppNS.NavigationElement[] => {
  const heroNavigation: AppNS.NavigationElement[] = [
    {
      id: 'dashboard',
      name: 'Dashboard',
      url: '/hero/dashboard',
      path: '/hero/dashboard',
      icon: <DashboardIcon />,
      disabled: false,
      show: true,
    },
    {
      id: 'opportunities',
      name: 'Job Opportunities',
      url: '/hero/job-opportunities',
      path: '/hero/job-opportunities',
      icon: <JobOpportunitiesIcon />,
      disabled:
        !ifHeroCompletedFirstMilestone(user) && !ifHeroBackgroundChecked(user),
      show: true,
    },
    {
      id: 'bookings',
      name: 'My Bookings',
      url: '/hero/bookings',
      path: '/hero/bookings',
      icon: <BookingsIcon />,
      disabled:
        !ifHeroCompletedFirstMilestone(user),
      show: true,
    },
    {
      id: 'messaging',
      name: 'Messaging',
      url: '/hero/messaging',
      path: '/hero/messaging/inbox',
      icon: <MessagingIcon />,
      disabled: !ifHeroCompletedFirstMilestone(user) && !ifHeroDuringOnboarding(user),
      show: true,
    },
    {
      id: 'payments',
      name: 'Payments',
      url: '/hero/payments/payments-list',
      path: '/hero/payments',
      icon: <PaymentsIcon />,
      disabled: !ifHeroCompletedFirstMilestone(user),
      show: true,
    },
    {
      id: 'reviews',
      name: 'Reviews',
      url: '/hero/reviews',
      path: '/hero/reviews',
      icon: <ReviewsIcon />,
      disabled:
        !ifHeroCompletedFirstMilestone(user),
      show: true,
    },
    {
      id: 'profile',
      name: 'My Hero Profile',
      url: '/hero/profile',
      path: '/hero/profile/:id',
      icon: <ProfileIcon />,
      disabled:
        !ifHeroCompletedFirstMilestone(user) && !ifHeroDuringOnboarding(user),
      show: true,
    },
    {
      id: 'welcome',
      name: 'Welcome Package',
      url: '/hero/welcome',
      path: '/hero/welcome',
      icon: <HowItWorksIcon />,
      disabled:
        false,
      show: true,
    },
    {
      id: 'settings',
      name: 'Settings',
      url: '/hero/settings',
      path: '/hero/settings',
      icon: <SettingsIcon />,
      disabled:
        !ifHeroCompletedFirstMilestone(user) && !ifHeroDuringOnboarding(user),
      show: false,
    },
  ];

  const clientNavigation: AppNS.NavigationElement[] = [
    {
      id: 'dashboard',
      name: 'Dashboard',
      url: '/client/dashboard',
      path: '/client/dashboard',
      icon: <DashboardIcon />,
      disabled: !ifClientDuringOnboarding(user),
      show: true,
    },
    {
      id: 'findYourHero',
      name: 'Find Your Hero',
      url: '/job-create',
      path: ['/job-create', '/find-a-hero', '/find-a-hero/:id'],
      icon: <SearchHeartIcon />,
      disabled: false,
      show: true,
    },
    // {
    //   id: 'browse',
    //   name: 'Browsing',
    //   url: '/client/browse',
    //   path: '/client/browse',
    //   icon: <BrowseIcon />,
    //   disabled: false,
    //   show: true,
    // },
    {
      id: 'bookings',
      name: 'My bookings',
      url: '/client/bookings',
      path: ['/client/bookings'],
      icon: <BookingsIcon />,
      disabled: !ifClientDuringOnboarding(user),
      show: true,
    },
    {
      id: 'messaging',
      name: 'Messaging',
      url: '/client/messaging/inbox',
      path: '/client/messaging',
      icon: <MessagingIcon />,
      disabled: !ifClientDuringOnboarding(user),
      show: true,
    },
    {
      id: 'payments',
      name: 'Payments',
      url: '/client/payments',
      path: '/client/payments',
      icon: <PaymentsIcon />,
      disabled: !ifClientDuringOnboarding(user),
      show: true,
    },
    {
      id: 'favourites',
      name: 'Favorites',
      url: '/client/favorites',
      path: '/client/favorites',
      icon: <FavouritesIcon />,
      disabled: !ifClientDuringOnboarding(user),
      show: true,
    },
    {
      id: 'profile',
      name: 'Profile',
      url: '/client/profile',
      path: '/client/profile',
      icon: <ProfileIcon />,
      disabled: !ifClientDuringOnboarding(user),
      show: true,
    },
    {
      id: 'welcome',
      name: 'Welcome Package',
      url: '/client/welcome',
      path: '/client/welcome',
      icon: <HowItWorksIcon />,
      disabled:
        false,
      show: true,
    },
    {
      id: 'resources',
      name: 'Resources',
      url: '/resources-for-families',
      path: '/Resources-for-families',
      icon: <ResourcesIcon />,
      disabled:
        false,
      show: true,
    },
    {
      id: 'buyGiftCards',
      name: 'Buy Gift Cards',
      url: '/buy-gift-cards',
      path: '/buy-gift-cards',
      icon: <BuyGiftCardIcon />,
      disabled:
        false,
      show: true,
    },
    {
      id: 'settings',
      name: 'Settings',
      url: '/client/settings',
      path: '/client/settings',
      icon: <SettingsIcon />,
      disabled: !ifClientDuringOnboarding(user) || !ifClientApproved(user),
      show: false,
    },
  ];

  if (user.roles.includes('hero')) {
    return heroNavigation;
  }
  return clientNavigation;
};
