import React from 'react';
import { RootState } from 'app/store/rootReducer';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

interface ClientEnrollStep2GuardProps {
  children: React.ReactNode;
}

export const ClientEnrollStep2Guard = (
  props: ClientEnrollStep2GuardProps
) => {
  const { children } = props;

  const { visitor } = useSelector((state: RootState) => state.visitor);

  if (!visitor || !visitor.selectedCareType) {
    return <Redirect to={'/visitor/client-enroll/care-type'}></Redirect>;
  }

  return <>{children}</>;
};

export default ClientEnrollStep2Guard;
