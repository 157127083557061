import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { Redirect } from 'react-router';

interface HasToBeOnboardedClientGuardProps {
  children: React.ReactNode;
}

export const HasToBeOnboardedClientGuard = (props: HasToBeOnboardedClientGuardProps) => {
  const { children } = props;

  const user = useSelector(({ auth }: AppState) => auth.user);

  if (!user) {
    return <Redirect to={'/auth/login'}></Redirect>;
  }
  
  if (user.onboardingStep !== 'background_check') {
    return <Redirect to={'/client/onboarding/step1'}></Redirect>;
  }

  return <>{children}</>;
};

export default HasToBeOnboardedClientGuard;
