import { ApiNS } from 'app/api/api';
import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../../core/config';
import { normalizeToLead, parseJobLead } from './client-bookings-parsers';


const URL = 'jobs';
const LEADS_URL = 'job_leads';
const EVENTS_URL = 'job_events.json';
const BOOKINGS_URL = 'bookings';
const STATE_URL = 'change-state';
const PAYMENT_METHOD_URL = 'update-payment-method';
const REVIEWS_URL = 'reviews';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetJobLeads(
  type: string,
  searchQuery: string,
  page: number
): Promise<AppNS.GetJobLeadsApiResponse> {
  if (cancel !== undefined) {
    cancel();
  }
  if (type === 'pending-matches') {
    return apiGetJobs(searchQuery, page);
  } else if (type === 'confirmed-bookings') {
    return apiGetBookings(searchQuery, ['confirmed'], page);
  } else {
    return apiGetBookings(
      searchQuery,
      ['past', 'cancelled_by_hero', 'cancelled_by_client'],
      page
    );
  }
}

export function apiGetJobLead(
  type: string,
  id: string
): Promise<AppNS.JobLead> {
  if (cancel !== undefined) {
    cancel();
  }

  if (type === 'pending-matches') {
    return apiGetJobLeadById(id);
  } else {
    return apiGetJobBookingById(id);
  }
}

function apiGetJobs(
  searchQuery: string,
  page: number
): Promise<AppNS.GetJobLeadsApiResponse> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          query: searchQuery,
          states: ['new', 'unfinished'],
          page,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve({
            ...data,
            results: data.results.map((item: AppNS.JobLead) => {
              return normalizeToLead(item);
            }),
          });
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiDeleteJobEvent(reqData: AppNS.Params): Promise<string> {
  const req = {
    id: reqData.id,
    properties: {
      state: 'deleted_by_client',
      cancellation_message: reqData.msg,
    },
  };

  if (cancel !== undefined) {
    cancel();
  }

  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${EVENTS_URL}`, req, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

function apiGetBookings(
  searchQuery: string,
  states: string | Array<string>,
  page: number
): Promise<AppNS.GetJobLeadsApiResponse> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${BOOKINGS_URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          query: searchQuery,
          states,
          page,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve({
            ...data,
            results: data.results.map((item: AppNS.JobLead) => {
              return parseJobLead(item);
            }),
          });
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetJobLeadById(leadId: string): Promise<AppNS.JobLead> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${LEADS_URL}/${leadId}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(parseJobLead(data.model));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetJobBookingById(
  bookingId: string
): Promise<AppNS.JobLead> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${BOOKINGS_URL}/${bookingId}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(parseJobLead(data.model));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetLeadState(
  reqData: AppNS.Params
): Promise<AppNS.JobLeadState> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${LEADS_URL}/${STATE_URL}.json`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.properties.state);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiCancelJob(reqData: AppNS.Params): Promise<string> {
  const data = {
    id: reqData.id,
    propertiesAttributes: {
      cancellationMessage: reqData.message,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${URL}/cancel.json`, data)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiAddBookingReview(
  reqData: ApiNS.AddBookingReviewRequest
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${REVIEWS_URL}.json`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetBookingReviews(
  jobId: string,
): Promise<Array<AppNS.BookingReview>> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${REVIEWS_URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          job_id: jobId
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(data.results);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiUpdatePaymentMethod(
  reqData: AppNS.Params
): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${BOOKINGS_URL}/${PAYMENT_METHOD_URL}.json`, reqData)
      .then(({ data }) => {
        if (data.modelUpdate) {
          resolve(data.modelUpdate);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
