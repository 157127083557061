import { ApiNS } from 'app/api/api';
import { AppNS } from '..';
import axios from 'axios';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiGetReviews } from 'app/api/hero-reviews/hero-reviews';

export interface HeroReviewsState {
  page: number;
  perPage: number;
  search: string;
  totalEntries: number;
  items: Array<AppNS.BookingReview>;
  isLoaded: boolean;
  isLoading: boolean;
}

const initialState: HeroReviewsState = {
  page: 1,
  perPage: 10,
  search: '',
  totalEntries: 0,
  items: [],
  isLoaded: false,
  isLoading: false,
};

const heroReviews = createSlice({
  name: 'heroReviews',
  initialState,
  reducers: {
    resetCollection(state) {
      state.items = [];
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCurrentParams(state, action: PayloadAction<AppNS.Params>) {
      state.page = action.payload.page;
      state.search = action.payload.search;
    },
    getReviewsStart(state: HeroReviewsState) {
      state.isLoading = true;
    },
    getReviewsSuccess(
      state: HeroReviewsState,
      action: PayloadAction<ApiNS.ReviewsListResponse>
    ) {
      // state.error = null;
      state.isLoading = false;
      state.items = action.payload.results;
      state.totalEntries = action.payload.totalEntries;
    },
    getReviewsFailed(state: HeroReviewsState) {
      state.items = [];
      // state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  getReviewsStart,
  getReviewsSuccess,
  getReviewsFailed,
  setCurrentParams,
} = heroReviews.actions;

export default heroReviews.reducer;

export const getReviews = (
  searchQuery: string,
  page: number
): AppThunk => async (dispatch) => {
  try {
    dispatch(getReviewsStart());
    const result = await apiGetReviews(searchQuery, page);
    dispatch(getReviewsSuccess(result));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getReviewsFailed());
  }
};
