import React from 'react';
import { Redirect } from 'react-router';
import NoSidebar from '../../layout/NoSidebar';
import { HasToBeLoggedInGuard } from '../../guards/HasToBeLoggedInGuard';
import { CannotBeLoggedInGuard } from '../../guards/CannotBeLoggedInGuard';
import CheckIsLoggedInGuard from 'app/core/guards/CheckIsLoggedInGuard';

const commonRoutes = [
  // CLAIM ACCOUNT
  {
    path: '/claim-account/choose-role',
    component: React.lazy(
      () => import('../../../main/claim-account/choose-role/ChooseRoleWrapper')
    ),
    layout: NoSidebar,
    canActivate: [HasToBeLoggedInGuard],
  },
  {
    path: '/claim-account',
    component: React.lazy(
      () => import('../../../main/claim-account/ClaimAccountPage')
    ),
    layout: NoSidebar,
  },

  // REGISTER 
  {
    path: '/register',
    component: React.lazy(
      () => import('../../../main/register-page/RegisterPage')
    ),
    canActivate: [CannotBeLoggedInGuard],
    layout: NoSidebar,
  },

  // AUTHENTICATION
  {
    path: '/reset-password',
    component: React.lazy(
      () => import('../../../main/reset-password/ResetPasswordPage')
    ),
    layout: NoSidebar,
  },
  {
    path: '/forgot-password',
    component: React.lazy(
      () => import('../../../main/reset-password/ForgotPasswordPage')
    ),
    layout: NoSidebar,
  },
  {
    path: '/resend-email',
    component: React.lazy(
      () => import('../../../main/resend-email/ResendEmailPage')
    ),
    canActivate: [CannotBeLoggedInGuard],
    layout: NoSidebar,
  },
  {
    path: '/auth/login',
    component: React.lazy(() => import('../../../main/auth/Login')),
    canActivate: [CannotBeLoggedInGuard],
    layout: NoSidebar,
  },
  {
    path: '/auth/login-as/:id',
    component: React.lazy(() => import('../../../main/auth/login-as/LoginAs')),
    layout: NoSidebar,
  },
  {
    path: '/auth/logout',
    component: React.lazy(() => import('../../../main/auth/Logout')),
    layout: NoSidebar,
  },

  // COMMON ROUTES
  {
    path: '/dashboard',
    exact: true,
    component: React.lazy(() => import('../../../main/dashboard/Dashboard')),
    canActivate: [HasToBeLoggedInGuard],
  },
  {
    path: '/buy-gift-cards',
    exact: true,
    component: React.lazy(() => import('../../../main/gift-cards/BuyGiftCard')),
    canActivate: [CheckIsLoggedInGuard],
  },
  {
    path: '/calls/:type/:callee_id',
    component: React.lazy(
      () => import('../../../main/common/video-calls/VideoCalls')
    ),
    canActivate: [HasToBeLoggedInGuard],
  },

  // UNAUTHORIZED ROUTES
  {
    path: '/auth',
    exact: true,
    component: () => <Redirect to={'/auth/login'} />,
  },
  {
    path: '/unauthorized',
    exact: true,
    component: React.lazy(() => import('../../../main/auth/unauthorized')),
    layout: NoSidebar,
  },

  // MAIN ROUTE REDIRECT TO AUTH
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={'/dashboard'} />,
  },
  
  // ROUTE NOT FOUND
  {
    component: React.lazy(() => import('../../../main/errors/Error404Page')),
    layout: NoSidebar,
  },
];

export default commonRoutes;
