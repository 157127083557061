import { AppNS } from '..';
import axios from 'axios';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  apiApproveAndPayReport,
  apiGetReport,
  apiAddChecklistItem,
  apiDeleteChecklistItem,
} from '../api/client-reports/client-reports';

export interface ClientReportsState {
  currentReport: AppNS.JobEventReport | null;
  isReportLoading: boolean;
  isReportLoadingError: boolean;
  isDeleteEventLoading: boolean;
  isDeleteEventError: boolean;
  isReportStateLoadingError: boolean;
  isReportStateLoading: boolean;

  isReportValid: boolean;
  isReportValidChecked: boolean;
  reportValidErrors: Array<string>;
  isRequestLoading: boolean;
  isRequestLoadingError: boolean;

  isCancellingJob: boolean;
  isCancellingJobError: boolean;

  detailsActionDone: boolean;

  isTimeLoading: boolean;
  isTimeLoadingError: boolean;

  isNotesLoading: boolean;
  isNotesLoadingError: boolean;

  isListItemLoading: boolean;
  isListItemLoadingError: boolean;
  isReportFinalizing: boolean;
  isReportFinalizingError: boolean;
  isReportFinalizingErrorMessage: string;

  isDetailsPageTouched: boolean;
  isAddingChecklist: boolean;
  isAddingChecklistError: boolean;

  isRemovingChecklist: boolean;
  isRemovingChecklistError: boolean;

  creditCardId: string;
  giftCardIds: string[];
  careCreditIds: string[];
  remainingAmountToPay: number | null;
}

const initialState: ClientReportsState = {
  currentReport: null,
  isReportLoading: false,
  isReportLoadingError: false,
  isDeleteEventLoading: false,
  isDeleteEventError: false,
  isReportStateLoadingError: false,
  isReportStateLoading: false,
  isReportValid: false,
  isReportValidChecked: false,
  reportValidErrors: [],
  isRequestLoading: false,
  isRequestLoadingError: false,
  isCancellingJob: false,
  isCancellingJobError: false,
  detailsActionDone: false,
  isTimeLoading: false,
  isTimeLoadingError: false,
  isNotesLoading: false,
  isNotesLoadingError: false,
  isListItemLoading: false,
  isListItemLoadingError: false,
  isReportFinalizing: false,
  isReportFinalizingError: false,
  isReportFinalizingErrorMessage: '',
  isDetailsPageTouched: false,
  isAddingChecklist: false,
  isAddingChecklistError: false,
  isRemovingChecklist: false,
  isRemovingChecklistError: false,
  creditCardId: '',
  giftCardIds: [],
  careCreditIds: [],
  remainingAmountToPay: null,
};

function checkReportValid(state: ClientReportsState) {
  const report = state.currentReport;
  if (!report) {
    state.isReportValid = false;
    return;
  }
  const validationErrors: Array<string> = [];

  if (!state.creditCardId && (state.remainingAmountToPay && state.remainingAmountToPay > 0)) {
    validationErrors.push('Please pick or add some credit card');
  }

  state.reportValidErrors = validationErrors;
  state.isReportValid = validationErrors.length ? false : true;
}

const clientReports = createSlice({
  name: 'clientReports',
  initialState,
  reducers: {
    getReportStart(state: ClientReportsState) {
      state.currentReport = null;
      state.isReportLoading = true;
      state.isReportLoadingError = false;
    },
    getCurrentReportSuccess(
      state: ClientReportsState,
      action: PayloadAction<AppNS.JobEventReport>
    ) {
      state.isReportLoadingError = false;
      state.isReportLoading = false;
      state.currentReport = action.payload;
      checkReportValid(state);
    },
    getReportFailed(state: ClientReportsState) {
      state.isReportLoadingError = true;
      state.isReportLoading = false;
    },
    setReportStateStart(state: ClientReportsState) {
      state.isReportStateLoading = true;
    },
    setReportStateSuccess(
      state: ClientReportsState,
      action: PayloadAction<AppNS.JobReportState>
    ) {
      state.isReportStateLoading = false;
      if (state.currentReport) {
        state.currentReport.state = action.payload;
      }
    },
    setReportStateFailed(state: ClientReportsState) {
      state.isReportStateLoadingError = true;
      state.isReportStateLoading = false;
    },
    setIsReportValidChecked(state: ClientReportsState) {
      state.isReportValidChecked = true;
    },
    setDetailsActionDone(
      state: ClientReportsState,
      action: PayloadAction<boolean>
    ) {
      state.detailsActionDone = action.payload;
      state.creditCardId = '';
      state.isReportValid = false;
    },
    setDetailsPageTouched(
      state: ClientReportsState,
      action: PayloadAction<boolean>
    ) {
      state.isDetailsPageTouched = action.payload;
    },
    setListItemStateStart(state: ClientReportsState) {
      state.isListItemLoading = true;
      state.isListItemLoadingError = false;
    },
    setListItemStateFailed(state: ClientReportsState) {
      state.isListItemLoading = false;
      state.isListItemLoadingError = true;
    },
    setListItemState(
      state: ClientReportsState,
      action: PayloadAction<{ id: string; state: boolean }>
    ) {
      if (state.currentReport) {
        state.currentReport.checklistItems = state.currentReport.checklistItems.map(
          (checklistItem) => {
            if (checklistItem.id === action.payload.id) {
              return { ...checklistItem, done: action.payload.state };
            }
            return checklistItem;
          }
        );

        checkReportValid(state);
        state.isListItemLoading = false;
      }
    },
    setFinalizeStart(state: ClientReportsState) {
      state.isReportFinalizing = true;
      state.isReportFinalizingError = false;
    },
    setFinalizeSuccess(state: ClientReportsState) {
      state.isReportFinalizing = false;
      state.isReportFinalizingError = false;
      if (state.currentReport) {
        state.currentReport.state = 'finalized_report';
      }
    },
    setFinalizeFailed(state: ClientReportsState, action: PayloadAction<string>) {
      state.isReportFinalizing = false;
      state.isReportFinalizingError = true;
      state.isReportFinalizingErrorMessage = action.payload;
    },
    addChecklistItemStart(state: ClientReportsState) {
      state.isAddingChecklist = true;
      state.isAddingChecklistError = false;
    },
    addChecklistItemSuccess(
      state: ClientReportsState,
      action: PayloadAction<{ id: string; name: string }>
    ) {
      state.isAddingChecklist = false;
      state.isAddingChecklistError = false;
      if (state.currentReport) {
        state.currentReport.checklistItems.push({
          id: action.payload.id,
          name: action.payload.name,
          done: false,
        });
      }
    },
    addChecklistItemFailed(state: ClientReportsState) {
      state.isAddingChecklist = false;
      state.isAddingChecklistError = true;
    },
    removeChecklistItemStart(state: ClientReportsState) {
      state.isRemovingChecklist = true;
      state.isRemovingChecklistError = false;
    },
    removeChecklistItemSuccess(
      state: ClientReportsState,
      action: PayloadAction<string>
    ) {
      state.isRemovingChecklist = false;
      state.isRemovingChecklistError = false;
      if (state.currentReport) {
        state.currentReport.checklistItems = state.currentReport.checklistItems.filter(
          (item) => item.id !== action.payload
        );
      }
    },
    removeChecklistItemFailed(state: ClientReportsState) {
      state.isRemovingChecklist = false;
      state.isRemovingChecklistError = true;
    },
    setCreditCardId(state: ClientReportsState, action: PayloadAction<string>) {
      state.creditCardId = action.payload;
      checkReportValid(state);
    },
    setGiftCardIds(state: ClientReportsState, action: PayloadAction<string[]>) {
      state.giftCardIds = action.payload;
      checkReportValid(state);
    },
    setCareCreditIds(state: ClientReportsState, action: PayloadAction<string[]>) {
      state.careCreditIds = action.payload;
      checkReportValid(state);
    },
    setRemainingAmountToPay(state: ClientReportsState, action: PayloadAction<number>) {
      state.remainingAmountToPay = action.payload;
      checkReportValid(state);
    },
  },
});

export const {
  addChecklistItemStart,
  addChecklistItemSuccess,
  addChecklistItemFailed,
  removeChecklistItemStart,
  removeChecklistItemSuccess,
  removeChecklistItemFailed,
  getCurrentReportSuccess,
  getReportFailed,
  getReportStart,
  setCreditCardId,
  setDetailsActionDone,
  setDetailsPageTouched,
  setFinalizeFailed,
  setFinalizeStart,
  setFinalizeSuccess,
  setIsReportValidChecked,
  setListItemState,
  setListItemStateFailed,
  setListItemStateStart,
  setReportStateFailed,
  setReportStateStart,
  setReportStateSuccess,
  setRemainingAmountToPay,
  setGiftCardIds,
  setCareCreditIds,
} = clientReports.actions;

export default clientReports.reducer;

export const getReport = (id: string, isSilent: boolean): AppThunk => async (
  dispatch
) => {
  try {
    if (!isSilent) {
      dispatch(getReportStart());
    }

    const result = await apiGetReport(id);
    dispatch(getCurrentReportSuccess(result));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getReportFailed());
  }
};

export const finalizeReport = (
  id: string,
  creditCardId: string,
  giftCardIds: string[],
  careCreditIds: string[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(setFinalizeStart());
    await apiApproveAndPayReport(id, creditCardId, giftCardIds, careCreditIds);
    dispatch(setFinalizeSuccess());
    dispatch(setDetailsPageTouched(true));
    dispatch(setDetailsActionDone(true));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(setFinalizeFailed(err.toString()));
  }
};

export const addChecklistItem = (
  eventReportId: string,
  name: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(addChecklistItemStart());
    const id = await apiAddChecklistItem({
      properties: { eventReportId, name },
    });
    dispatch(addChecklistItemSuccess({ id, name }));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(addChecklistItemFailed());
  }
};

export const removeChecklistItem = (id: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(removeChecklistItemStart());
    await apiDeleteChecklistItem(id);
    dispatch(removeChecklistItemSuccess(id));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(removeChecklistItemFailed());
  }
};
