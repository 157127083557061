import React, { Suspense } from 'react';
import AppLoading from './AppLoading';

const AppSuspense: React.FunctionComponent<{}> = props => {
  return (
    <Suspense fallback={<AppLoading delay={0} />}>{props.children}</Suspense>
  );
};

export default AppSuspense;
