import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function MenuHorizontalIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.375 14.6484C4.82475 14.6484 6 13.4732 6 12.0234C6 10.5737 4.82475 9.39844 3.375 9.39844C1.92525 9.39844 0.75 10.5737 0.75 12.0234C0.75 13.4732 1.92525 14.6484 3.375 14.6484Z"
          stroke="#67707E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.625 14.6484C22.0747 14.6484 23.25 13.4732 23.25 12.0234C23.25 10.5737 22.0747 9.39844 20.625 9.39844C19.1753 9.39844 18 10.5737 18 12.0234C18 13.4732 19.1753 14.6484 20.625 14.6484Z"
          stroke="#67707E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 14.6484C13.4497 14.6484 14.625 13.4732 14.625 12.0234C14.625 10.5737 13.4497 9.39844 12 9.39844C10.5503 9.39844 9.375 10.5737 9.375 12.0234C9.375 13.4732 10.5503 14.6484 12 14.6484Z"
          stroke="#67707E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
