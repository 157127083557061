import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function SettingsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 25" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.241 5.94083C12.4728 6.17899 12.8019 6.31486 13.1473 6.31486C13.4926 6.31486 13.8218 6.17899 14.0535 5.94083L14.6394 5.34083C14.9854 4.98426 15.5352 4.86893 16.0122 5.05285C16.4893 5.23677 16.7902 5.68011 16.7636 6.15983L16.7195 6.97183C16.7017 7.29276 16.831 7.60567 17.0752 7.8329C17.3195 8.06014 17.6559 8.18039 18.0009 8.16383L18.8738 8.12183C19.3898 8.09702 19.8666 8.37724 20.0642 8.82137C20.2617 9.26551 20.1371 9.77723 19.7531 10.0988L19.1081 10.6438C18.8515 10.8589 18.7051 11.1651 18.7051 11.4863C18.7051 11.8076 18.8515 12.1138 19.1081 12.3288L19.7531 12.8738C20.1371 13.1954 20.2617 13.7071 20.0642 14.1513C19.8666 14.5954 19.3898 14.8756 18.8738 14.8508L18.0009 14.8088C17.6557 14.7922 17.3191 14.9126 17.0748 15.1401C16.8305 15.3675 16.7014 15.6807 16.7195 16.0018L16.7636 16.8138C16.7902 17.2935 16.4893 17.7369 16.0122 17.9208C15.5352 18.1047 14.9854 17.9894 14.6394 17.6328L14.0535 17.0328C13.8218 16.7947 13.4926 16.6588 13.1473 16.6588C12.8019 16.6588 12.4728 16.7947 12.241 17.0328L11.6562 17.6328C11.3099 17.989 10.7602 18.104 10.2832 17.9202C9.80629 17.7364 9.50511 17.2935 9.53096 16.8138L9.57503 16.0018C9.59535 15.6797 9.46734 15.3648 9.223 15.1358C8.97867 14.9068 8.64111 14.7854 8.29471 14.8018L7.42181 14.8438C6.90619 14.8679 6.43002 14.5877 6.23243 14.144C6.03485 13.7003 6.15854 13.189 6.54138 12.8668L7.18638 12.3218C7.44235 12.1065 7.5884 11.8004 7.5884 11.4793C7.5884 11.1583 7.44235 10.8522 7.18638 10.6368L6.54138 10.0918C6.16191 9.76965 6.03979 9.26099 6.23585 8.81924C6.43191 8.37749 6.90423 8.09713 7.41751 8.11783L8.29041 8.15983C8.63595 8.17727 8.97321 8.05741 9.21821 7.83007C9.46322 7.60273 9.59292 7.28931 9.57503 6.96783L9.53096 6.15583C9.50511 5.67618 9.80629 5.23323 10.2832 5.04943C10.7602 4.86563 11.3099 4.98069 11.6562 5.33683L12.241 5.94083Z"
          stroke="#0F7895"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1473 13.7324C14.4831 13.7324 15.566 12.7251 15.566 11.4824C15.566 10.2398 14.4831 9.23242 13.1473 9.23242C11.8114 9.23242 10.7285 10.2398 10.7285 11.4824C10.7285 12.7251 11.8114 13.7324 13.1473 13.7324Z"
          stroke="#0F7895"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.806152 2.25195C0.806152 1.42353 1.47773 0.751953 2.30615 0.751953H23.4937C24.3221 0.751953 24.9937 1.42353 24.9937 2.25195V21.752C24.9937 22.5804 24.3221 23.252 23.4937 23.252H2.30615C1.47773 23.252 0.806152 22.5804 0.806152 21.752V2.25195Z"
          stroke="#0F7895"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
