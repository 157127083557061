import axios from 'axios';
import { AppNS } from '..';
import { API_URL } from '../core/config';

const VERIFICATION_URL = 'users/email_verification';

export function verifyEmail(reqData: AppNS.Params): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${VERIFICATION_URL}.json`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch((error) => {
        return reject(error.response.data.errors);
      });
  });
}

export function resendEmail(reqData: AppNS.Params): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${VERIFICATION_URL}/resend.json`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch((error) => {
        return reject(error.response.data.errors);
      });
  });
}
