import { AppNS } from 'app';

export const ifHeroCompletedFirstMilestone = (
  userData: AppNS.User
): boolean => {
  if (
    userData.onboardingStep === 'first_milestone_completed' ||
    userData.onboardingStep === 'profile_completed'
  ) {
    return true;
  }
  return false;
};

export const ifHeroDuringOnboarding = (userData: AppNS.User): boolean => {
  if (
    userData.onboardingStep === 'background_check' ||
    userData.onboardingStep === 'screening_interview'
  ) {
    return true;
  }
  return false;
};

export const ifHeroBackgroundChecked = (userData: AppNS.User): boolean => {
  if (
    userData.fullCheckApproval &&
    userData.fullCheckApproval.backgroundCheck
  ) {
    return true;
  }
  return false;
};
