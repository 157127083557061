import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../core/config';

const URL = 'users/hero_care_types.json';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetCareTypes(params: AppNS.Params): Promise<any> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params,
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(data.results);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiCreateCareType(
  reqData: AppNS.Params
): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${URL}`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiUpdateCareType(
  reqData: AppNS.Params
): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${URL}?return_properties=true`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiUpdateCareTypesRates(
  reqData: Array<AppNS.Params>
): Promise<AppNS.Params> {
  const promises = [] as Array<Promise<AppNS.Params>>;
  reqData.forEach((itemData: AppNS.Params) => {
    promises.push(apiUpdateCareType(itemData));
  });
  return axios.all(promises);
}

export function apiDeleteCareType(reqData: AppNS.Params): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/${URL}`, { params: reqData })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
