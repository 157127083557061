import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function JobOpportunitiesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 846.66 846.66" fill="none">
      <path d="M672.58 383.98l0 -297.62 -485.19 0c-75.5,0 -137.36,61.86 -137.36,137.37l0 536.57 622.55 0 0 -89.71c-71.09,-10.2 -124.04,-71.35 -124.04,-143.31 0,-71.96 52.96,-133.11 124.04,-143.3zm-537.48 288.61c-27.27,0 -27.27,-41.47 0,-41.47l293.49 0c27.27,0 27.27,41.47 0,41.47l-293.49 0zm0 -88.68c-27.27,0 -27.27,-41.47 0,-41.47l293.49 0c27.27,0 27.27,41.47 0,41.47l-293.49 0zm0 -88.68c-27.27,0 -27.27,-41.47 0,-41.47l293.49 0c27.27,0 27.27,41.47 0,41.47l-293.49 0zm257.35 -220.65c-27.27,0 -27.27,-41.46 0,-41.46l175.25 0c27.27,0 27.27,41.46 0,41.46l-175.25 0zm0 73.9c-27.27,0 -27.27,-41.46 0,-41.46l175.25 0c27.27,0 27.27,41.46 0,41.46l-175.25 0zm-237.54 -159.71l162.59 0c11.44,0 20.73,9.29 20.73,20.74l0 162.58c0,11.45 -9.29,20.73 -20.73,20.73l-162.59 0c-11.45,0 -20.73,-9.28 -20.73,-20.73l0 -162.58c0,-11.45 9.28,-20.74 20.73,-20.74zm141.85 41.47l-121.11 0 0 121.12 121.11 0 0 -121.12zm499.87 297.04c0,-57.06 -46.25,-103.31 -103.31,-103.31 -57.06,0 -103.31,46.25 -103.31,103.31 0,57.06 46.24,103.31 103.31,103.31 57.05,0 103.31,-46.26 103.31,-103.31zm-18.82 117.58l57.26 110.09c12.57,24.18 -24.2,43.3 -36.77,19.12l-57.24 -110.07c-8.66,3.02 -17.69,5.25 -27.01,6.59l0 110.44c0,11.45 -9.28,20.74 -20.73,20.74l-664.02 0c-11.45,0 -20.74,-9.29 -20.74,-20.74l0 -557.3c0,-98.4 80.43,-178.84 178.83,-178.84l505.93 0c11.45,0 20.73,9.29 20.73,20.74l0 318.35c71.09,10.2 124.05,71.34 124.05,143.3 0,46.8 -22.44,90.34 -60.29,117.58z"/>
    </SvgIcon>
  );
}
