import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../../core/config';
import { ApiNS } from '../api';
import { parseReport } from './client-reports-parsers';

const URL = 'reports';
const CHECKLIST_URL = 'checklist_items';
const REPORTS_URL = 'reports';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetReport(id: string): Promise<AppNS.JobEventReport> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}/${id}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(parseReport(data.model));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiApproveAndPayReport(
  reportId: string,
  creditCardId: string,
  giftCardIds: string[],
  careCreditIds: string[],
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${REPORTS_URL}/approve-and-pay.json`, {
        id: reportId,
        properties: {
          creditCardId,
          giftCardIds,
          careCreditIds
        },
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject(data.errors);
        }
      })
      .catch(reject);
  });
}

export function apiAddChecklistItem(
  reqData: ApiNS.AddReportChecklistItemRequest
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${CHECKLIST_URL}.json`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiDeleteChecklistItem(id: string): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/${CHECKLIST_URL}.json`, { params: { id } })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
