import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../../core/config';

const URL = 'references.json';

let cancel: Canceler;

export function apiGetReferences(): Promise<any> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}`)
      .then(({ data }) => {
        if (data.results) {
          resolve(data.results);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiCreateReference(reqData: AppNS.Params): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${URL}`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiDeleteReference(reqData: AppNS.Params): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/${URL}`, { params: reqData })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
