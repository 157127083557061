import { AppNS } from '..';
import axios from 'axios';
import moment from 'moment';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  apiSendNewJob,
  apiGetNewJobResults,
  apiGetLastJobByDependants,
  apiGetPreviousJobAddresses,
  apiGetJobById,
  apiUpdateNewJobDependants,
  apiGetJobLeadById,
  apiDeleteJobEvent,
  apiBookLead,
  apiRepostJob,
} from '../api/client-jobs';
import {
  saveJob,
  deleteJob,
} from 'app/ui/modules/job-create/helpers/storageHelpers';
import { getTimeNow } from 'app/ui/components/availability-picker/helpers/parseData';

export interface ClientJobsState {
  clientJobs: Array<AppNS.ClientJob>;
  currentLead: AppNS.JobLead | null;
  isCurrentLeadValid: boolean;
  currentLeadValidErrors: Array<string>;
  isCurrentLeadValidChecked: boolean;
  isCurrentLeadSending: boolean;
  newJob: AppNS.ClientJob;
  lastJobByDependants: AppNS.ClientJob;
  previousJobAddresses: Array<AppNS.Address>;
  isNewJobValid: boolean;
  isNewJobValidChecked: boolean;
  newJobValidErrors: Array<string>;
  isNewJobUserLogged: boolean;
  isNewJobLoading: boolean;
  isNewJobResultLoaded: boolean;
  isNewJobResultsLoading: boolean;
  newJobResults: AppNS.JobMatchingResults;
  jobSubmitted: string;
  isNewJobLoadingError: boolean;
  isDependantsLoading: boolean;
  isReposting: boolean;
  isDeleteEventLoading: boolean;
  isDeleteEventError: boolean;
  isEventsReady: boolean;
}

const emptyJob = {
  address: null,
  careType: '',
  credentials: [],
  startDate: '',
  endDate: '',
  dependantIds: [],
  dependantsCount: 1,
  excludeDatesForRecurring: [],
  experience: [],
  favoriteIds: [],
  id: '',
  infoForHero: '',
  interests: [],
  jobSchedule: [],
  languages: [],
  nonSmoker: false,
  petFriendly: false,
  covidVaccinated: false,
  pickedHeroId: '',
  preferredHourlyRate: 0,
  scheduleType: 'recurring',
  services: [],
  yearsOfExperience: 0,
  state: 'new',
} as AppNS.ClientJob;

const initialState: ClientJobsState = {
  clientJobs: [],
  newJob: emptyJob,
  lastJobByDependants: emptyJob,
  previousJobAddresses: [],
  currentLead: null,
  isCurrentLeadValid: false,
  currentLeadValidErrors: [],
  isCurrentLeadValidChecked: false,
  isCurrentLeadSending: false,
  isNewJobValid: false,
  isNewJobValidChecked: false,
  newJobValidErrors: [],
  isNewJobUserLogged: false,
  isNewJobLoading: false,
  isNewJobResultLoaded: false,
  isNewJobResultsLoading: false,
  newJobResults: {} as AppNS.JobMatchingResults,
  jobSubmitted: '',
  isNewJobLoadingError: false,
  isDependantsLoading: false,
  isReposting: false,
  isDeleteEventLoading: false,
  isDeleteEventError: false,
  isEventsReady: false,
};

function jobScheduleHasOverlap(
  jobSchedule: Array<AppNS.JobAdhocSchedule | AppNS.JobRecurringSchedule>
) {
  let hasOverlap = false;
  if (!jobSchedule.length) {
    return false;
  }

  const hasMultipleTimes = !!jobSchedule.filter(
    (schedule) => schedule.properties.timesForTheDay.length > 2
  ).length;
  if (!hasMultipleTimes) {
    return false;
  }

  jobSchedule.forEach((schedule) => {
    // split into start-end pairs
    const timeslots = schedule.properties.timesForTheDay
      .reduce(function (result: string[][], value, index, array) {
        if (index % 2 === 0)
          result.push(
            schedule.properties.timesForTheDay.slice(index, index + 2)
          );
        return result;
      }, [])
      // normalize midnight values
      .map((timeslot) => {
        if (timeslot[1] === '0:00') {
          timeslot[1] = '24:00';
        }
        return timeslot;
      });
    // sort by start time
    timeslots.sort((timeslot1, timeslot2) => {
      if (
        moment(timeslot1[0], 'HH:mm').isBefore(moment(timeslot2[0], 'HH:mm'))
      ) {
        return -1;
      }
      if (
        moment(timeslot1[0], 'HH:mm').isAfter(moment(timeslot2[0], 'HH:mm'))
      ) {
        return 1;
      }
      return 0;
    });
    // check for time overlaps
    for (let i = 0, l = timeslots.length; i < l - 1; i++) {
      const currentEndTime = timeslots[i][1];
      const nextStartTime = timeslots[i + 1][0];

      if (
        moment(nextStartTime, 'HH:mm').isBefore(moment(currentEndTime, 'HH:mm'))
      ) {
        hasOverlap = true;
      }
    }
  });

  return hasOverlap;
}

function checkNewJobValid(state: ClientJobsState) {
  const job = state.newJob;
  const validationErrors = [];

  if (!job.address) {
    validationErrors.push('Make sure you selected or entered an address.');
  }

  if (!job.careType) {
    validationErrors.push('Make sure you selected care type.');
  }

  if (!state.isNewJobUserLogged && job.dependantsCount < 1) {
    validationErrors.push(
      'Make sure you selected how many loved ones need care.'
    );
  }

  if (state.isNewJobUserLogged && !job.dependantIds.length) {
    validationErrors.push('Make sure you selected who needs care.');
  }

  if (!job.jobSchedule.length) {
    validationErrors.push('Make sure you have selected some dates and times.');
  }

  if (job.scheduleType === 'recurring' && (!job.startDate || !job.endDate)) {
    validationErrors.push(
      'Make sure you set a start and end date between which you need care.'
    );
  }

  if (job.scheduleType === 'recurring' && job.startDate === job.endDate) {
    validationErrors.push(
      'Recurring jobs cannot start and end on the same day.'
    );
  }

  if (job.startDate || job.jobSchedule.length > 0) {
    const jobScheduleProperties = job?.jobSchedule[0]?.properties as any;
    const jobStartDate = job.startDate || jobScheduleProperties.date;
    if (jobStartDate === moment().format('YYYY-MM-DD')) {
      const timeNow = getTimeNow();
      if (
        parseInt(timeNow.replace(':', '')) >=
        parseInt(
          job.jobSchedule[0]?.properties.timesForTheDay[0]?.replace(':', '')
        )
      ) {
        validationErrors.push('Start time cannot be before current time.');
      }
    }
  }

  if (job.jobSchedule.length > 0) {
    job.jobSchedule.forEach((schedule) => {
      let isDefault: boolean = false;
      isDefault = schedule.properties.timesForTheDay
        ?.toString()
        ?.includes('8:00,0:00');
      if (isDefault) {
        validationErrors.push(
          'You have left the default time span (8am to midnight) selected for some days. Please review and edit these times to best represent the hours of care you need.'
        );
      }
    });
  }

  if (!job.preferredHourlyRate || job.preferredHourlyRate === 0) {
    validationErrors.push(
      'Make sure you have entered a preferred hourly rate.'
    );
  }

  if (job.preferredHourlyRate > 0 && job.preferredHourlyRate < 18.0) {
    validationErrors.push(
      'Please enter a preferred hourly rate above $18.00/hr.'
    );
  }

  if (jobScheduleHasOverlap(job.jobSchedule)) {
    validationErrors.push(
      'Make sure there is no time overlap between the selected time slots.'
    );
  }

  if (!job.infoForHero || job.infoForHero.length < 2) {
    validationErrors.push(
      'You must enter the job requirements. Heroes use this information to decide if its job is a good fit for them.'
    );
  }

  state.newJobValidErrors = validationErrors;
  state.isNewJobValid = validationErrors.length ? false : true;
}

function checkLeadValid(state: ClientJobsState) {
  const lead = state.currentLead;
  if (!lead) {
    state.isCurrentLeadValid = false;
    return;
  }

  const validationErrors = [];

  if (!lead.cardId) {
    validationErrors.push('Please pick a valid credit card');
  }

  state.currentLeadValidErrors = validationErrors;
  state.isCurrentLeadValid = validationErrors.length ? false : true;
}

const clientJobs = createSlice({
  name: 'clientJobs',
  initialState,
  reducers: {
    // job create
    setNewJobType(state: ClientJobsState, action: PayloadAction<string>) {
      state.newJob.careType = action.payload;
      checkNewJobValid(state);
    },
    setNewJobState(
      state: ClientJobsState,
      action: PayloadAction<AppNS.JobState>
    ) {
      state.newJob.state = action.payload;
      checkNewJobValid(state);
    },
    setNewJobAddress(
      state: ClientJobsState,
      action: PayloadAction<AppNS.Address | null>
    ) {
      state.newJob.address = action.payload;
      checkNewJobValid(state);
    },
    setDependantsCount(state: ClientJobsState, action: PayloadAction<number>) {
      state.newJob.dependantsCount = action.payload;
      checkNewJobValid(state);
    },
    setIsNewJobUserLogged(
      state: ClientJobsState,
      action: PayloadAction<boolean>
    ) {
      state.isNewJobUserLogged = action.payload;
    },
    setNewJobScheduleType(
      state: ClientJobsState,
      action: PayloadAction<string>
    ) {
      state.newJob.scheduleType = action.payload;
      checkNewJobValid(state);
    },
    setNewRecurringDatesRange(
      state: ClientJobsState,
      action: PayloadAction<{
        startDate: string;
        endDate: string;
      }>
    ) {
      state.newJob.startDate = action.payload.startDate;
      state.newJob.endDate = action.payload.endDate;
      checkNewJobValid(state);
    },
    setNewRecurringExcludeDates(
      state: ClientJobsState,
      action: PayloadAction<Array<string>>
    ) {
      state.newJob.excludeDatesForRecurring = action.payload;
      checkNewJobValid(state);
    },
    setNewJobSchedule(
      state: ClientJobsState,
      action: PayloadAction<
        Array<AppNS.JobAdhocSchedule | AppNS.JobRecurringSchedule>
      >
    ) {
      state.newJob.jobSchedule = action.payload;
      checkNewJobValid(state);
    },
    setDependantsIds(
      state: ClientJobsState,
      action: PayloadAction<Array<string>>
    ) {
      state.newJob.dependantIds = action.payload;
      state.newJob.dependantsCount = action.payload.length;
      checkNewJobValid(state);
    },
    setFavoritesIds(
      state: ClientJobsState,
      action: PayloadAction<Array<string>>
    ) {
      state.newJob.favoriteIds = action.payload;
      checkNewJobValid(state);
    },
    setPreferredHourlyRate(
      state: ClientJobsState,
      action: PayloadAction<number>
    ) {
      state.newJob.preferredHourlyRate = action.payload || 0;
      checkNewJobValid(state);
    },
    setRequirements(
      state: ClientJobsState,
      action: PayloadAction<AppNS.Params>
    ) {
      state.newJob = { ...state.newJob, ...action.payload };
      checkNewJobValid(state);
    },
    setIsNewJobValidChecked(state: ClientJobsState) {
      state.isNewJobValidChecked = true;
    },
    sendNewJobStart(state: ClientJobsState) {
      state.isNewJobLoading = true;
    },
    sendNewJobSuccess(
      state: ClientJobsState,
      action: PayloadAction<AppNS.Job>
    ) {
      state.isNewJobLoading = false;
      state.newJob.id = action.payload.id;

      state.newJob.startDate = action.payload.startDate;
      state.newJob.endDate = action.payload.endDate;
      // save to localStorage with new id
      saveJob(state.newJob);
    },
    sendNewJobFailed(state: ClientJobsState) {
      state.isNewJobLoading = false;
    },
    setNewJobPickedHeroId(
      state: ClientJobsState,
      action: PayloadAction<string>
    ) {
      state.newJob.pickedHeroId = action.payload;
      // save to localStorage with new id
      saveJob(state.newJob);
    },
    setSubmitState(state: ClientJobsState, action: PayloadAction<string>) {
      state.jobSubmitted = action.payload;
    },

    // payment methods
    setCreditCardId(state: ClientJobsState, action: PayloadAction<string>) {
      if (state.currentLead) {
        state.currentLead.cardId = action.payload;
      }
      checkLeadValid(state);
    },
    setGiftCardIds(state: ClientJobsState, action: PayloadAction<string[]>) {
      if (state.currentLead) {
        state.currentLead.giftCardIds = action.payload;
      }
      checkLeadValid(state);
    },
    setCareCreditIds(state: ClientJobsState, action: PayloadAction<string[]>) {
      if (state.currentLead) {
        state.currentLead.careCreditIds = action.payload;
      }
      checkLeadValid(state);
    },

    // match results
    updateNewJobResultsStart(state: ClientJobsState) {
      state.isNewJobResultsLoading = true;
    },
    getNewJobResultsSuccess(
      state: ClientJobsState,
      action: PayloadAction<AppNS.JobMatchingResults>
    ) {
      state.newJobResults = action.payload;
      if (action.payload.job.matchingState === 'completed') {
        state.isNewJobResultLoaded = true;
        state.isNewJobResultsLoading = false;
      }
    },
    getNewJobResultsFailed(state: ClientJobsState) {
      // stop polling
      state.isNewJobResultLoaded = true;
    },
    resetResults(state: ClientJobsState) {
      state.isNewJobResultLoaded = false;
      state.newJobResults = {} as AppNS.JobMatchingResults;
    },

    // job
    resetJob(state: ClientJobsState) {
      state.newJob = emptyJob;
      checkNewJobValid(state);
    },
    getJobStart(state: ClientJobsState) {
      state.isNewJobLoadingError = false;
      state.isNewJobLoading = true;
    },
    getJobFailed(state: ClientJobsState) {
      state.isNewJobLoading = false;
      state.isNewJobLoadingError = true;
    },
    getJobSuccess(
      state: ClientJobsState,
      action: PayloadAction<AppNS.ClientJob>
    ) {
      state.isNewJobLoadingError = false;
      state.isNewJobLoading = false;
      state.newJob = action.payload;
    },
    getLastJobByDependantsStart(state: ClientJobsState) {
      state.isNewJobLoadingError = false;
      state.isNewJobLoading = true;
    },
    getLastJobByDependantsFailed(state: ClientJobsState) {
      state.isNewJobLoading = false;
      state.isNewJobLoadingError = true;
    },
    getLastJobByDependantsSuccess(
      state: ClientJobsState,
      action: PayloadAction<AppNS.ClientJob>
    ) {
      state.isNewJobLoadingError = false;
      state.isNewJobLoading = false;
      state.lastJobByDependants = action.payload;
    },
    getPreviousJobAddressesSuccess(
      state: ClientJobsState,
      action: PayloadAction<Array<AppNS.Address>>
    ) {
      state.previousJobAddresses = action.payload;
    },
    getPreviousJobAddressesFailed(state: ClientJobsState) {
      state.previousJobAddresses = [];
    },
    getJobPollStart(state: ClientJobsState) {
      state.isEventsReady = false;
    },
    getCurrentLeadPollSuccess(
      state: ClientJobsState,
      action: PayloadAction<AppNS.JobLead>
    ) {
      if (
        state.currentLead &&
        action.payload.job.eventsCreationState === 'completed'
      ) {
        state.currentLead.job.jobEvents = action.payload.job.jobEvents;
        state.isEventsReady = true;
      }
    },
    getCurrentLeadSuccess(
      state: ClientJobsState,
      action: PayloadAction<AppNS.JobLead>
    ) {
      state.isNewJobLoadingError = false;
      state.isNewJobLoading = false;
      state.isCurrentLeadValid = false;

      if (action.payload.job.eventsCreationState === 'completed') {
        state.isEventsReady = true;
      }

      state.currentLead = action.payload;
    },
    setLeadUserNotes(state: ClientJobsState, action: PayloadAction<string>) {
      if (state.currentLead) {
        state.currentLead.personalizedMessage = action.payload;
      }
    },
    setCurrentLeadValidChecked(
      state: ClientJobsState,
      action: PayloadAction<boolean>
    ) {
      checkLeadValid(state);
      state.isCurrentLeadValidChecked = true;
    },
    updateNewJobDependantsStart(state: ClientJobsState) {
      state.isNewJobResultLoaded = false;
      state.isDependantsLoading = true;
    },
    updateNewJobDependantsFailed(state: ClientJobsState) {
      state.isDependantsLoading = false;
    },
    updateNewJobDependantsSuccess(
      state: ClientJobsState,
      action: PayloadAction<Array<string>>
    ) {
      state.isDependantsLoading = false;
      state.newJob.dependantIds = action.payload;
      state.newJob.dependantsCount = action.payload.length;
      state.newJob.state = 'new';
      if (state?.currentLead && state.currentLead.job.id === state.newJob.id) {
        // clear cache
        state.currentLead = null;
      }
      // Remove stored job from localStorage
      deleteJob();
    },
    repostStart(state: ClientJobsState) {
      state.isNewJobResultLoaded = false;
      state.isReposting = true;
    },
    repostFailed(state: ClientJobsState) {
      state.isReposting = false;
    },
    repostSuccess(state: ClientJobsState) {
      state.isReposting = false;
      state.newJob.state = 'new';
      if (state.currentLead && state.currentLead.job.id === state.newJob.id) {
        // clear cache
        state.currentLead = null;
      }
    },
    deleteJobEventStart(state: ClientJobsState) {
      state.isDeleteEventError = false;
      state.isDeleteEventLoading = true;
    },
    deleteJobEventFailed(state: ClientJobsState) {
      state.isDeleteEventError = true;
      state.isDeleteEventLoading = false;
    },
    deleteJobEventSuccess(
      state: ClientJobsState,
      action: PayloadAction<string>
    ) {
      state.isDeleteEventError = false;
      state.isDeleteEventLoading = false;

      if (state.currentLead) {
        const events = state.currentLead.job.jobEvents;
        if (events) {
          state.currentLead.job.jobEvents = events.map((event) => {
            if (event.id !== action.payload) {
              return event;
            } else {
              return { ...event, state: 'deleted_by_client' };
            }
          });
        }
      }
    },
    bookLeadStart(state: ClientJobsState) {
      state.isCurrentLeadSending = true;
    },
    bookLeadFailed(state: ClientJobsState) {
      state.isCurrentLeadSending = false;
    },
    bookLeadSuccess(state: ClientJobsState, action: PayloadAction<string>) {
      state.isCurrentLeadSending = false;

      if (state.currentLead) {
        state.currentLead.job.state = 'confirmed_booking';
      }
    },
  },
});

export const {
  bookLeadStart,
  bookLeadFailed,
  bookLeadSuccess,
  getJobFailed,
  getNewJobResultsFailed,
  getJobStart,
  getJobSuccess,
  getLastJobByDependantsFailed,
  getLastJobByDependantsStart,
  getLastJobByDependantsSuccess,
  getPreviousJobAddressesSuccess,
  getPreviousJobAddressesFailed,
  getJobPollStart,
  getCurrentLeadPollSuccess,
  getCurrentLeadSuccess,
  updateNewJobResultsStart,
  getNewJobResultsSuccess,
  resetJob,
  resetResults,
  sendNewJobStart,
  sendNewJobSuccess,
  sendNewJobFailed,
  setDependantsCount,
  setDependantsIds,
  setFavoritesIds,
  setIsNewJobUserLogged,
  setIsNewJobValidChecked,
  setNewJobAddress,
  setNewJobPickedHeroId,
  setNewJobSchedule,
  setNewJobScheduleType,
  setNewJobType,
  setNewJobState,
  setNewRecurringDatesRange,
  setNewRecurringExcludeDates,
  setRequirements,
  setPreferredHourlyRate,
  setSubmitState,
  setCreditCardId,
  setGiftCardIds,
  setCareCreditIds,
  updateNewJobDependantsFailed,
  updateNewJobDependantsStart,
  updateNewJobDependantsSuccess,
  repostStart,
  repostSuccess,
  repostFailed,
  deleteJobEventStart,
  deleteJobEventFailed,
  deleteJobEventSuccess,
  setCurrentLeadValidChecked,
  setLeadUserNotes,
} = clientJobs.actions;

export default clientJobs.reducer;

export const sendJob =
  (job: AppNS.Job): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(sendNewJobStart());
      const response = await apiSendNewJob(job);
      dispatch(sendNewJobSuccess(response));
      dispatch(setSubmitState(response.id));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(sendNewJobFailed());
    }
  };

export const getJob =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      let job: AppNS.ClientJob | null;
      dispatch(getJobStart());
      job = await apiGetJobById(id);
      dispatch(getJobSuccess(job));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getJobFailed());
    }
  };

export const fetchJobResults =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateNewJobResultsStart());
      const response = await apiGetNewJobResults(id);
      dispatch(getNewJobResultsSuccess(response));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getNewJobResultsFailed());
    }
  };

export const getLastJobByDependants =
  (ids: Array<string>): AppThunk =>
  async (dispatch) => {
    try {
      let job: AppNS.ClientJob;
      dispatch(getLastJobByDependantsStart());
      job = await apiGetLastJobByDependants(ids);
      dispatch(getLastJobByDependantsSuccess(job));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getLastJobByDependantsFailed());
    }
  };

export const getPreviousJobAddresses = (): AppThunk => async (dispatch) => {
  try {
    const result = await apiGetPreviousJobAddresses();
    dispatch(getPreviousJobAddressesSuccess(result));
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getPreviousJobAddressesFailed());
  }
};

export const getCurrentLead =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getJobStart());
      const result = await apiGetJobLeadById(id);
      dispatch(getCurrentLeadSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getJobFailed());
    }
  };

export const pollCurrentLead =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getJobPollStart());
      const result = await apiGetJobLeadById(id);
      dispatch(getCurrentLeadPollSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getJobFailed());
    }
  };

export const updateDependants =
  (id: string, dependants: Array<string>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateNewJobDependantsStart());
      await apiUpdateNewJobDependants(id, dependants);
      dispatch(updateNewJobDependantsSuccess(dependants));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err);
      dispatch(updateNewJobDependantsFailed());
    }
  };

export const repostJob =
  (id: string, favoriteIds: Array<string>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(repostStart());
      await apiRepostJob(id, favoriteIds);
      dispatch(repostSuccess());
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err);
      dispatch(repostFailed());
    }
  };

export const bookLead =
  (lead: AppNS.JobLead): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(bookLeadStart());
      const response = await apiBookLead({
        properties: {
          jobLeadId: lead.id,
          creditCardId: lead.cardId,
          giftCardIds: lead.giftCardIds,
          careCreditIds: lead.careCreditIds,
          clientNotes: lead.personalizedMessage,
        },
      });
      dispatch(bookLeadSuccess(response));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(bookLeadFailed());
    }
  };

export const deleteJobEvent =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteJobEventStart());
      await apiDeleteJobEvent({ id });
      dispatch(deleteJobEventSuccess(id));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err);
      dispatch(deleteJobEventFailed());
    }
  };
