import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const ABOUT_REDUCERS = {
  setAboutStart(state: AuthState) {
    state.isChangingAbout = true;
    state.isChangingAboutError = false;
  },
  setAboutFailed(state: AuthState) {
    state.isChangingAboutError = true;
  },
  setAboutSuccess(state: AuthState, action: PayloadAction<string>) {
    state.isChangingAbout = false;
    state.isChangingAboutError = false;
    if (state.user) {
      state.user.about = action.payload;
    }
  },
};
