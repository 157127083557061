import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function PaymentsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 24" fill="none">
      <g fill="none">
        <path
          d="M5.42976 9.6838L4.59018 7.2568C4.31752 6.46872 4.78277 5.62416 5.62971 5.3698L20.5787 0.8908C21.4262 0.637244 22.3342 1.07056 22.6072 1.8588L24.8432 8.3148C24.9744 8.69353 24.9385 9.10524 24.7433 9.45933C24.5482 9.81342 24.2098 10.0809 23.8026 10.2028L18.6157 11.7568"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6157 18.6836V21.2546C18.614 21.7678 18.3932 22.2593 18.0019 22.6211C17.6106 22.9829 17.0808 23.1852 16.5291 23.1836H2.96475C2.41306 23.1852 1.8833 22.9829 1.49199 22.6211C1.10068 22.2593 0.879882 21.7678 0.878174 21.2546V11.6116C0.879881 11.0985 1.10071 10.6071 1.49205 10.2455C1.88339 9.88386 2.41316 9.68175 2.96475 9.68361H16.5291C17.0807 9.68175 17.6105 9.88386 18.0018 10.2455C18.3931 10.6071 18.614 11.0985 18.6157 11.6116V14.1836"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.90942 9.69922V23.1842"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2047 14.1836C13.9213 14.2334 12.9229 15.2395 12.972 16.4336C12.9229 17.6277 13.9213 18.6338 15.2047 18.6836H19.1124C19.7537 18.6582 20.2525 18.1554 20.2282 17.5586V15.3086C20.253 14.7116 19.754 14.2085 19.1124 14.1836H15.2047Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.4417 15.9609C17.6643 15.9609 17.8448 16.1288 17.8448 16.3359C17.8448 16.543 17.6643 16.7109 17.4417 16.7109C17.2191 16.7109 17.0386 16.543 17.0386 16.3359C17.0386 16.1288 17.2191 15.9609 17.4417 15.9609"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.146 7.50112C13.4464 6.95029 14.079 6.62705 14.7399 6.68674C15.4007 6.74643 15.9547 7.17683 16.1356 7.77112"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
