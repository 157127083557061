import { AppNS } from '..';
import axios from 'axios';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { groupBy, flatten } from 'lodash-es';
import {
  apiGetJobLeads,
  apiGetJobLead,
  apiSetLeadState,
  apiCreateProposal,
  apiCancelJob,
  apiDeleteJobEvent,
} from '../api/hero-jobs/hero-jobs';
import {
  timeToHoursInt,
  getTimeSearch,
} from 'app/main/hero/job-opportunities/proposal/helpers/eventHoursHelpers';

export interface HeroJobsState {
  page: number;
  perPage: number;
  search: string;
  totalEntries: number;
  items: Array<AppNS.JobLead>;
  currentLead: AppNS.JobLead | null;
  isLeadLoading: boolean;
  isLoaded: boolean;
  isLoading: boolean;
  isLeadLoadingError: boolean;
  isDeleteEventLoading: boolean;
  isDeleteEventError: number;
  isLeadStateLoadingError: boolean;
  isLeadStateLoading: boolean;

  proposal: AppNS.LeadProposal;
  isProposalRatePicked: boolean;
  isProposalValid: boolean;
  isProposalValidChecked: boolean;
  proposalValidErrors: Array<string>;
  isProposalLoading: boolean;
  isProposalLoadingError: boolean;
  isProposalDirty: boolean;

  isCancellingJob: boolean;
  isCancellingJobError: boolean;

  detailsActionDone: boolean;
  proposalActionTouched: boolean;
  proposalWithBudget: boolean;
}

const emptyProposal = {
  personalizedMessage: '',
  fixedHourlyRate: null,
  id: '',
  events: [],
  initialHourlyRate: null,
  isSend: false,
} as AppNS.LeadProposal;

const initialState: HeroJobsState = {
  page: 1,
  perPage: 10,
  search: '',
  currentLead: null,
  totalEntries: 0,
  items: [],
  isLeadLoading: false,
  isLeadLoadingError: false,
  isDeleteEventLoading: false,
  isDeleteEventError: 0,
  isLeadStateLoadingError: false,
  isLeadStateLoading: false,
  isLoaded: false,
  isLoading: false,
  isProposalRatePicked: false,
  proposal: emptyProposal,
  isProposalValid: false,
  isProposalValidChecked: false,
  proposalValidErrors: [],
  isProposalDirty: false,
  isProposalLoading: false,
  isProposalLoadingError: false,
  isCancellingJob: false,
  isCancellingJobError: false,
  detailsActionDone: false,
  proposalActionTouched: false,
  proposalWithBudget: false,
};

function checkProposalDirty(state: HeroJobsState) {
  const proposal = state.proposal;
  const currentLead = state.currentLead;

  const checkAvailabilityProposed =
    proposal.events.filter((event: AppNS.LeadProposalEvent) => {
      if (event.notAvailable === true) {
        return true;
      }

      if (event.timeFrom && currentLead?.job?.jobEvents) {
        return currentLead?.job?.jobEvents?.find((jobEvent: AppNS.JobEvent) => {
          if (event.jobEventId === jobEvent.id) {
            return (
              jobEvent.timeFrom !== event.timeFrom ||
              jobEvent.timeTo !== event.timeTo
            );
          }
        });
      }
    }).length > 0;

  state.isProposalDirty = checkAvailabilityProposed;
}

function checkProposalValid(state: HeroJobsState) {
  const proposal = state.proposal;
  const currentLead = state.currentLead;
  const validationErrors = [];

  if (proposal.fixedHourlyRate === 0) {
    validationErrors.push('Please propose a valid new rate');
  }

  if (proposal.fixedHourlyRate && proposal.fixedHourlyRate < 10.4) {
    validationErrors.push('The minimum rate you can propose is $10.40/hour');
  }

  const checkHasAvailableEvents = proposal.events.filter(
    (item: AppNS.LeadProposalEvent) => {
      if (!item.notAvailable) {
        return true;
      }
    }
  );
  if (
    currentLead?.job?.jobEvents?.length === proposal.events.length &&
    checkHasAvailableEvents.length === 0
  ) {
    validationErrors.push(
      'You must check some availability before bidding on this job.'
    );
    validationErrors.push(
      'If you are not interested in working any of the hours in this job, click Go Back and then Remove Opportunity.'
    );
  }

  const checkInValidTimes = proposal.events.filter(
    (item: AppNS.LeadProposalEvent) => {
      if (item.timeFrom && item.timeTo) {
        return (
          timeToHoursInt(item.timeFrom) >=
          timeToHoursInt(item.timeTo === '0:00' ? '24:00' : item.timeTo)
        );
      }
    }
  );
  if (checkInValidTimes.length > 0) {
    validationErrors.push(
      'Proposal contains invalid times. Resolve all invalid times to continue.'
    );
  }

  const checkBookingConflicts = () => {
    return proposal.events.filter((proposalEvent: AppNS.LeadProposalEvent) => {
      if (!proposalEvent.notAvailable) {
        const event = currentLead?.job.jobEvents?.find(
          (jobEvent: AppNS.JobEvent) => {
            return proposalEvent.jobEventId === jobEvent.id;
          }
        );

        if (event && event.bookedHours && event.bookedHours.length > 0) {
          const timeFromInt = timeToHoursInt(
            proposalEvent.timeFrom || event.timeFrom
          );
          const timeTo = proposalEvent.timeTo || event.timeTo;
          const timeToInt = timeToHoursInt(
            timeTo === '0:00' ? '24:00' : timeTo
          );

          return event.bookedHours.find((hour: string) => {
            const hourInt = parseInt(hour);
            return (
              (hourInt > timeFromInt && hourInt < timeToInt) ||
              hourInt === timeFromInt ||
              hourInt === timeToInt
            );
          });
        }
      }
    });
  };
  if (checkBookingConflicts().length > 0) {
    validationErrors.push(
      'Proposed hours conflict with a confirmed booking. Resolve all conflicts to continue.'
    );
  }

  const overlappingHours = (): boolean => {
    const groupedByDate = groupBy(currentLead?.job.jobEvents, 'date');

    const res = Object.values(groupedByDate).find(
      (events: Array<AppNS.JobEvent>) => {
        if (events.length > 1) {
          const arrays = events.map((event: AppNS.JobEvent): Array<string> => {
            const proposalEvent = proposal.events.find(
              (proposalEvent: AppNS.LeadProposalEvent) => {
                return proposalEvent.jobEventId === event.id;
              }
            );
            if (proposalEvent) {
              if (
                proposalEvent.notAvailable !== true &&
                proposalEvent.timeFrom &&
                proposalEvent.timeTo
              ) {
                return getTimeSearch(proposalEvent);
              }
              return [];
            }
            return event.timeSearch;
          });
          const flatArrays = flatten(arrays);
          return new Set(flatArrays).size !== flatArrays.length;
        }
        return false;
      }
    );
    if (res) {
      return true;
    }
    return false;
  };
  if (overlappingHours()) {
    validationErrors.push(
      'Two events on the same day have overlapping hours. All events must be separated by a minumum of 15 minutes.'
    );
  }

  state.proposalValidErrors = validationErrors;
  state.isProposalValid = validationErrors.length ? false : true;
}

const heroLeads = createSlice({
  name: 'heroLeads',
  initialState,
  reducers: {
    resetCollection(state) {
      state.items = [];
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCurrentParams(state, action: PayloadAction<AppNS.Params>) {
      state.page = action.payload.page;
      state.search = action.payload.search;
    },
    getLeadsStart(state: HeroJobsState) {
      state.isLoading = true;
    },
    getLeadsSuccess(
      state: HeroJobsState,
      action: PayloadAction<AppNS.GetJobLeadsApiResponse>
    ) {
      // state.error = null;
      state.isLoading = false;
      state.items = action.payload.results;
      state.totalEntries = action.payload.totalEntries;
    },
    getLeadsFailed(state: HeroJobsState, action: PayloadAction<string>) {
      state.items = [];
      // state.error = action.payload;
      state.isLoading = false;
    },
    getLeadStart(state: HeroJobsState) {
      state.currentLead = null;
      state.isLoaded = false;
      state.isLeadLoading = true;
      state.isLeadLoadingError = false;
    },
    getCurrentLeadSuccess(
      state: HeroJobsState,
      action: PayloadAction<AppNS.JobLead>
    ) {
      state.isLeadLoadingError = false;
      state.isLeadLoading = false;
      state.currentLead = action.payload;
    },
    getLeadFailed(state: HeroJobsState) {
      state.isLeadLoadingError = true;
      state.isLeadLoading = false;
    },
    setLeadStateStart(state: HeroJobsState) {
      state.isLeadStateLoading = true;
    },
    setLeadStateSuccess(
      state: HeroJobsState,
      action: PayloadAction<AppNS.JobLeadState>
    ) {
      state.isLeadStateLoading = false;
      if (state.currentLead) {
        state.currentLead.state = action.payload;
      }
    },
    setLeadStateFailed(state: HeroJobsState) {
      state.isLeadStateLoadingError = true;
      state.isLeadStateLoading = false;
    },
    deleteJobEventStart(state: HeroJobsState) {
      state.isDeleteEventError = 0;
      state.isDeleteEventLoading = true;
    },
    deleteJobEventFailed(state: HeroJobsState) {
      state.isDeleteEventError = new Date().getTime();
      state.isDeleteEventLoading = false;
    },
    deleteJobEventSuccess(state: HeroJobsState, action: PayloadAction<string>) {
      state.isDeleteEventError = 0;
      state.isDeleteEventLoading = false;

      if (state.currentLead) {
        const events = state.currentLead.job.jobEvents;
        if (events) {
          state.currentLead.job.jobEvents = events.map((event) => {
            if (event.id !== action.payload) {
              return event;
            } else {
              return { ...event, state: 'deleted_by_hero' };
            }
          });
        }
      }
    },
    setIsProposalValidChecked(state: HeroJobsState) {
      checkProposalValid(state);
      state.isProposalValidChecked = true;
    },
    resetProposal(
      state: HeroJobsState,
      action: PayloadAction<{
        id: string;
        withBudget: boolean;
        initialHourlyRate: number;
      }>
    ) {
      state.proposal = {
        ...emptyProposal,
        id: action.payload.id,
        initialHourlyRate: action.payload.initialHourlyRate,
        events: [],
      };
      state.proposalWithBudget = action.payload.withBudget;

      state.currentLead?.job?.jobEvents?.forEach((event: AppNS.JobEvent) => {
        if (event.bookedConflictHours && event.bookedConflictHours.length > 0) {
          state.proposal.events.push({
            jobEventId: event.id,
            notAvailable: true,
            hasConflict: true,
          } as AppNS.LeadProposalEvent);
        }
      });
      checkProposalDirty(state);
      checkProposalValid(state);
    },
    setProposalRate(
      state: HeroJobsState,
      action: PayloadAction<number | null>
    ) {
      state.proposal.fixedHourlyRate = action.payload;
      if (action.payload !== null) {
        state.isProposalRatePicked = true;
      }
      checkProposalDirty(state);
      checkProposalValid(state);
    },
    setProposalMessage(state: HeroJobsState, action: PayloadAction<string>) {
      state.proposal.personalizedMessage = action.payload;
    },
    setProposalEvent(
      state: HeroJobsState,
      action: PayloadAction<{ id: string; from: string; to: string }>
    ) {
      // if event exists
      const found = state.proposal.events.find(
        (event) => event.jobEventId === action.payload.id
      );
      const timeFrom = action.payload.from as string
      const timeTo = action.payload.to as string 
      if (found) {
        state.proposal.events = state.proposal.events.map(
          (event: AppNS.LeadProposalEvent) => {
            if (event.jobEventId === action.payload.id) {
              return {
                ...event,
                timeFrom: timeFrom,
                timeTo: timeTo,
                timeSearch: getTimeSearch( { timeFrom, timeTo} )
              };
            }
            return event;
          }
        );
      } else {
        state.proposal.events.push({
          jobEventId: action.payload.id,
          timeFrom: timeFrom,
          timeTo: timeTo,
          timeSearch: getTimeSearch( { timeFrom, timeTo} ),
          notAvailable: false,
        } as AppNS.LeadProposalEvent);
      }

      checkProposalDirty(state);
      checkProposalValid(state);
    },
    setProposalEventAvailability(
      state: HeroJobsState,
      action: PayloadAction<{
        id: string;
        state: boolean;
        hasConflict: boolean;
      }>
    ) {
      // if event exists
      const found = state.proposal.events.find(
        (event) => event.jobEventId === action.payload.id
      );
      if (found) {
        state.proposal.events = state.proposal.events.map(
          (event: AppNS.LeadProposalEvent) => {
            if (event.jobEventId === action.payload.id) {
              return {
                ...event,
                notAvailable: action.payload.state,
              };
            }
            return event;
          }
        );
      } else {
        state.proposal.events.push({
          jobEventId: action.payload.id,
          notAvailable: action.payload.state,
          hasConflict: action.payload.hasConflict,
        } as AppNS.LeadProposalEvent);
      }
      checkProposalDirty(state);
      checkProposalValid(state);
    },
    setCreateProposalStart(state: HeroJobsState) {
      state.isProposalLoading = true;
    },
    setCreateProposalSuccess(state: HeroJobsState) {
      state.isProposalLoading = false;
      state.proposal.isSend = true;
      if (state.currentLead) {
        state.currentLead.state = 'proposal_sent';
      }
    },
    setCreateProposalFailed(state: HeroJobsState) {
      state.isProposalLoadingError = true;
      state.isProposalLoading = false;
    },
    cancelJobStart(state: HeroJobsState) {
      state.isCancellingJob = true;
    },
    cancelJobSuccess(state: HeroJobsState) {
      state.isCancellingJob = false;
      if (state.currentLead) {
        state.currentLead.state = 'cancelled_by_hero';
      }
    },
    cancelJobFailed(state: HeroJobsState) {
      state.isCancellingJobError = true;
      state.isCancellingJob = false;
    },
    setDetailsActionDone(state: HeroJobsState, action: PayloadAction<boolean>) {
      state.detailsActionDone = action.payload;
    },
    setProposalActionTouched(
      state: HeroJobsState,
      action: PayloadAction<boolean>
    ) {
      state.proposalActionTouched = action.payload;
    },
  },
});

export const {
  cancelJobFailed,
  cancelJobStart,
  cancelJobSuccess,
  deleteJobEventFailed,
  deleteJobEventStart,
  deleteJobEventSuccess,
  getCurrentLeadSuccess,
  getLeadFailed,
  getLeadsStart,
  getLeadsSuccess,
  getLeadStart,
  resetProposal,
  setCreateProposalFailed,
  setCreateProposalStart,
  setCreateProposalSuccess,
  setCurrentParams,
  setDetailsActionDone,
  setIsProposalValidChecked,
  setLeadStateFailed,
  setLeadStateStart,
  setLeadStateSuccess,
  setProposalEvent,
  setProposalEventAvailability,
  setProposalMessage,
  setProposalRate,
  setProposalActionTouched,
} = heroLeads.actions;

export default heroLeads.reducer;

export const getLeads =
  (type: string, searchQuery: string, page: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getLeadsStart());
      const result = await apiGetJobLeads(type, searchQuery, page);
      dispatch(getLeadsSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getLeadFailed());
    }
  };

export const getCurrentLead =
  (type: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getLeadStart());
      const result = await apiGetJobLead(type, id);
      dispatch(getCurrentLeadSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getLeadFailed());
    }
  };

export const setLeadState =
  (id: string, state: string, hourlyRate?: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLeadStateStart());
      const result = await apiSetLeadState({
        id,
        properties: {
          state: state,
          fixedHourlyRate: hourlyRate,
        },
      });
      dispatch(setLeadStateSuccess(result));
      dispatch(setDetailsActionDone(true));
      if (hourlyRate) {
        dispatch(setProposalActionTouched(true));
      }
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(setLeadStateFailed());
    }
  };

export const createProposal =
  (proposal: AppNS.LeadProposal): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCreateProposalStart());
      await apiCreateProposal(proposal);
      dispatch(setCreateProposalSuccess());
      dispatch(setProposalActionTouched(true));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(setCreateProposalFailed());
    }
  };

export const setProposalEventsAvailability =
  (ids: Array<string>, state: boolean): AppThunk =>
  async (dispatch) => {
    const hasConflict = false;
    try {
      ids.forEach((id: string) => {
        dispatch(setProposalEventAvailability({ id, state, hasConflict }));
      });
    } catch (err) {
      console.error(err.toString());
    }
  };

export const setProposalEventsHours =
  (ids: Array<string>, from: string, to: string): AppThunk =>
  async (dispatch) => {
    try {
      ids.forEach((id: string) => {
        dispatch(setProposalEvent({ id, from, to }));
      });
    } catch (err) {
      console.error(err.toString());
    }
  };

export const deleteJobEvent =
  (id: string, msg: string, backup?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteJobEventStart());
      await apiDeleteJobEvent({ id, msg, backup });
      dispatch(deleteJobEventSuccess(id));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err);
      dispatch(deleteJobEventFailed());
    }
  };

export const cancelJob =
  (id: string, message: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(cancelJobStart());
      await apiCancelJob({ id, message });
      dispatch(cancelJobSuccess());
      dispatch(setDetailsActionDone(true));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err);
      dispatch(cancelJobFailed());
    }
  };
