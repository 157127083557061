import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { AppNS } from '..';
import { AppThunk } from './index';
import { DOCUMENT_TYPES } from './../ui/modules/hero-qualifications/components/consts/document-types';

import {
  createDocument,
  deleteDocument,
  updateDocument,
  getDocuments,
} from '../api/documents';

export interface HeroQualificationsConfig {
  documentsDeleteDisabled: boolean;
}

export interface StateDocumentForm {
  id: string;
  documentId?: string;
  isLoading: boolean;
  isError: string;
  type: AppNS.DocumentType;
  mode: AppNS.DocumentFormMode;
  defaultValues?: AppNS.Params;
}

export interface HeroQualificationsState {
  config: HeroQualificationsConfig;
  documents: Array<AppNS.Document>;
  documentsInLoadingState: Array<string>;
  forms: Array<StateDocumentForm>;
  getDocumentsError: boolean;
  insuranceVisibility: boolean;
  isCollectionLoaded: boolean;
  isValid: boolean;
}

const initialState: HeroQualificationsState = {
  config: {
    documentsDeleteDisabled: false,
  },
  documents: [],
  documentsInLoadingState: [],
  forms: [],
  getDocumentsError: false,
  insuranceVisibility: false,
  isCollectionLoaded: false,
  isValid: false,
};

function checkStateForms(state: HeroQualificationsState) {
  // if there's no certifications show empty form
  const documents = state.documents;
  DOCUMENT_TYPES.forEach((type: AppNS.DocumentType) => {
    const docs = documents.filter((document: AppNS.Document) => {
      return document.type === type;
    });

    // check if there's driver's licence document
    if (type === 'id' && docs.length > 0) {
      if (docs[0].idType === 'license') {
        state.insuranceVisibility = true;
      }
    }

    // check if there is already some empty form of this type
    const emptyForms = state.forms.filter((form: StateDocumentForm) => {
      return form.type === type && form.mode === 'add';
    });
    if (docs.length < 1 && emptyForms.length < 1) {
      const certificationForm = createEmptyForm(type);
      state.forms = [...state.forms, certificationForm];
    }
  });
}

function checkStateValid(state: HeroQualificationsState) {
  const documents = state.documents;
  const result = !DOCUMENT_TYPES.some((type: AppNS.DocumentType) => {
    if (type === 'automobile_insurance') {
      return false;
    }
    const docs = documents.filter((document: AppNS.Document) => {
      return document.type === type;
    });

    if (docs.length === 0) {
      return true;
    } else {
      if (type === 'id' && docs[0].idType === 'license') {
        //  there mus be at least one 'automobile_insurance' document
        const aIDocs = documents.filter((document: AppNS.Document) => {
          return document.type === 'automobile_insurance';
        });
        if (aIDocs.length > 0) {
          return false;
        }
        return true;
      }
    }
    return false;
  });

  state.isValid = result;
}

const heroQualificationsSlice = createSlice({
  name: 'heroQualifications',
  initialState,
  reducers: {
    setModuleConfig(
      state: HeroQualificationsState,
      action: PayloadAction<HeroQualificationsConfig>
    ) {
      state.config = action.payload;
    },
    checkState(state: HeroQualificationsState) {
      checkStateForms(state);
      checkStateValid(state);
    },
    getDocumentsStart(state: HeroQualificationsState) {
      state.isCollectionLoaded = false;
    },
    getDocumentsSuccess(
      state: HeroQualificationsState,
      action: PayloadAction<Array<AppNS.Document>>
    ) {
      state.isCollectionLoaded = true;
      state.getDocumentsError = false;
      state.documents = action.payload;
    },
    getDocumentsFailed(state: HeroQualificationsState) {
      state.getDocumentsError = true;
    },
    addFormStart(
      state: HeroQualificationsState,
      action: PayloadAction<StateDocumentForm>
    ) {
      state.forms = [...state.forms, action.payload];
    },
    addDocumentStart(
      state: HeroQualificationsState,
      action: PayloadAction<string>
    ) {
      state.forms = state.forms.map((form: StateDocumentForm) => {
        if (form.id === action.payload) {
          return { ...form, isLoading: true };
        }
        return form;
      });
    },
    addDocumentSuccess(
      state: HeroQualificationsState,
      action: PayloadAction<{ formId: string; data: AppNS.Document }>
    ) {
      // remove form
      state.forms = state.forms.filter((form: StateDocumentForm) => {
        return form.id !== action.payload.formId;
      });

      // and add document
      state.documents = [...state.documents, action.payload.data];
    },
    addDocumentFailed(
      state: HeroQualificationsState,
      action: PayloadAction<string>
    ) {
      state.forms = state.forms.map((form: StateDocumentForm) => {
        if (form.id === action.payload) {
          return { ...form, isError: action.payload };
        }
        return form;
      });
    },

    removeDocumentStart(
      state: HeroQualificationsState,
      action: PayloadAction<string>
    ) {
      if (!state.documentsInLoadingState.includes(action.payload)) {
        state.documentsInLoadingState = [
          ...state.documentsInLoadingState,
          action.payload,
        ];
      }
    },

    removeDocumentSuccess(
      state: HeroQualificationsState,
      action: PayloadAction<string>
    ) {
      state.documentsInLoadingState = state.documentsInLoadingState.filter(
        (id: string) => {
          return id !== action.payload;
        }
      );

      // remove document
      state.documents = state.documents.filter((item: AppNS.Document) => {
        return item.id !== action.payload;
      });
    },

    removeDocumentFailed(
      state: HeroQualificationsState,
      action: PayloadAction<string>
    ) {
      state.documentsInLoadingState = state.documentsInLoadingState.filter(
        (id: string) => {
          return id !== action.payload;
        }
      );
    },

    editDocument(
      state: HeroQualificationsState,
      action: PayloadAction<string>
    ) {
      // get document data
      const document = state.documents.filter((item: AppNS.Document) => {
        return item.id === action.payload;
      })[0];

      const form = createEmptyForm(document.type, action.payload);

      // add form with edit mode
      form.mode = 'edit';
      form.documentId = action.payload;
      form.defaultValues = document;
      state.forms = [...state.forms, form];
    },

    updateDocumentStart(
      state: HeroQualificationsState,
      action: PayloadAction<string>
    ) {
      state.forms = state.forms.map((form: StateDocumentForm) => {
        if (form.id === action.payload) {
          return { ...form, isLoading: true };
        }
        return form;
      });
    },

    updateDocumentSuccess(
      state: HeroQualificationsState,
      action: PayloadAction<{ formId: string; data: AppNS.Document }>
    ) {
      // remove form
      state.forms = state.forms.filter((form: StateDocumentForm) => {
        return form.id !== action.payload.formId;
      });

      // update document
      state.documents = state.documents.map((document: AppNS.Document) => {
        if (document.id === action.payload.data.id) {
          return action.payload.data;
        }
        return document;
      });
    },

    updateDocumentFailed(
      state: HeroQualificationsState,
      action: PayloadAction<string>
    ) {
      state.documentsInLoadingState = state.documentsInLoadingState.filter(
        (id: string) => {
          return id !== action.payload;
        }
      );
    },

    removeForm(state: HeroQualificationsState, action: PayloadAction<string>) {
      // remove form
      state.forms = state.forms.filter((form: StateDocumentForm) => {
        return form.id !== action.payload;
      });
    },

    setInsuranceVisibility(
      state: HeroQualificationsState,
      action: PayloadAction<boolean>
    ) {
      // remove form
      state.insuranceVisibility = action.payload;
    },
  },
});

export const {
  addDocumentFailed,
  addDocumentStart,
  addDocumentSuccess,
  addFormStart,
  checkState,
  editDocument,
  getDocumentsFailed,
  getDocumentsStart,
  getDocumentsSuccess,
  removeDocumentFailed,
  removeDocumentStart,
  removeDocumentSuccess,
  removeForm,
  setInsuranceVisibility,
  setModuleConfig,
  updateDocumentFailed,
  updateDocumentStart,
  updateDocumentSuccess,
} = heroQualificationsSlice.actions;

export default heroQualificationsSlice.reducer;

export const fetchDocuments = (params: AppNS.Params): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(getDocumentsStart());
    const documentsResponse = await getDocuments(params);
    dispatch(getDocumentsSuccess(documentsResponse));
    dispatch(checkState());
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getDocumentsFailed());
  }
};

export const addDocument = (
  params: AppNS.Params,
  formId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(addDocumentStart(formId));
    const documentData = await createDocument(params);

    const file = {
      url: documentData.file.url,
      uploadUrl: documentData.file.url,
    };

    dispatch(
      addDocumentSuccess({
        formId,
        data: createDocumentData(documentData.id, file, params),
      })
    );

    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(addDocumentFailed(err.toString()));
  }
};

export const updateDocumentAction = (
  params: AppNS.Params,
  formId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateDocumentStart(formId));

    const documentData = await updateDocument(params);

    const file = {
      uploadUrl: documentData.file.url,
    };

    dispatch(
      updateDocumentSuccess({
        formId,
        data: createDocumentData(documentData.id, file, params),
      })
    );

    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(updateDocumentFailed(err.toString()));
  }
};

export const removeDocument = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(removeDocumentStart(id));
    await deleteDocument({ id });
    dispatch(removeDocumentSuccess(id));
    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(removeDocumentFailed(err.toString()));
  }
};

export const addForm = (formType: AppNS.DocumentType): AppThunk => (
  dispatch
) => {
  const formData = createEmptyForm(formType);
  dispatch(addFormStart(formData));
};

export const editDocumentAction = (id: string): AppThunk => (dispatch) => {
  dispatch(editDocument(id));
};

export const removeFormAction = (id: string): AppThunk => (dispatch) => {
  dispatch(removeForm(id));
};

export const setInsuranceVisibilityState = (state: boolean): AppThunk => (
  dispatch
) => {
  dispatch(setInsuranceVisibility(state));
};

export const createEmptyForm = (
  formType: AppNS.DocumentType,
  id: string = String(new Date().getTime() + Math.round(Math.random() * 1000))
): StateDocumentForm => {
  return {
    id,
    type: formType,
    isLoading: false,
    isError: '',
    mode: 'add',
  } as StateDocumentForm;
};

export const createDocumentData = (
  id: string,
  file: AppNS.Params,
  params: AppNS.Params
): AppNS.Document => {
  return { ...params.properties, id, file } as AppNS.Document;
};

export const setConfig = (config: HeroQualificationsConfig): AppThunk => (
  dispatch
) => {
  dispatch(setModuleConfig(config));
};
