import axios, { Canceler } from 'axios';
import { API_URL } from '../../core/config';
import { ApiNS } from '../api';

const REVIEWS_URL = 'reviews';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetReviews(
  query: string,
  page: number
): Promise<ApiNS.ReviewsListResponse> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${REVIEWS_URL}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          query,
          page,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetAllReviews(
  page: number,
): Promise<ApiNS.ReviewsListResponse> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${REVIEWS_URL}/visitor/get.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          page,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
