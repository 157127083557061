import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { assetUrl } from '../../../helpers/assetUrl';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  imageLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
  },
  withShadow: {
    filter: 'drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15))',
  },
}));

interface ToolbarLogoProps {
  logged: boolean;
}

const ToolbarLogo: React.FunctionComponent<ToolbarLogoProps> = (props) => {
  const { logged } = props;
  const homePageLink = window.location.origin;

  const classes = useStyles();
  return (
    <>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <a href={homePageLink} className={classes.imageLink}>
          <img
            src={assetUrl('/images/logos/logo-text-tm.svg')}
            alt="OndeCare"
            width="142"
          />
        </a>
      </Box>

      <Box display={{ xs: 'block', sm: 'none' }}>
        {logged ? (
          <a href={homePageLink} className={classes.imageLink}>
            <img
              className={classes.withShadow}
              src={assetUrl('/images/logos/logo-ico.svg')}
              alt="OndeCare"
              width="36"
              height="32"
            />
          </a>
        ) : (
          <a href={homePageLink} className={classes.imageLink}>
            <img
              className={classes.withShadow}
              src={assetUrl('/images/logos/logo-text-tm.svg')}
              alt="OndeCare"
              width="110"
              height="16.5"
            />
          </a>
        )}

      </Box>
    </>
  );
};

export default ToolbarLogo;
