import React from 'react';
import clsx from 'clsx';
import { Drawer, Hidden, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'app/store';
import { closeMobileNav } from 'app/store/ui';
import NavbarLayout from './NavbarLayout';
import { DRAWER_WIDTH } from 'app/core/config';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 4,
    background: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      minWidth: DRAWER_WIDTH,
    },
  },
  wrapperFolded: {
    [theme.breakpoints.up('md')]: {
      width: 64,
      minWidth: 64,
    },
  },
  navbar: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: DRAWER_WIDTH,
    minWidth: DRAWER_WIDTH,
    height: '100%',
    zIndex: 4,
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    boxShadow: theme.shadows[3],
  },
  left: {
    left: 0,
  },
  folded: {
    position: 'absolute',
    width: 64,
    minWidth: 64,
    top: 0,
    bottom: 0,
  },
  foldedAndOpened: {
    width: DRAWER_WIDTH,
    minWidth: DRAWER_WIDTH,
  },
  navbarContent: {
    flex: '1 1 auto',
  },
}));

function NavbarWrapperLayout() {
  const dispatch = useDispatch();
  const mobileNavOpen = useSelector(
    (state: AppState) => state.ui.mobileNavOpen
  );

  const handleDrawerClose = () => {
    dispatch(closeMobileNav());
  };

  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper)}>
      <Hidden smDown>
        <div className={clsx(classes.navbar, classes.left)}>
          <NavbarLayout className={classes.navbarContent} />
        </div>
      </Hidden>

      <Hidden mdUp>
        <Drawer
          anchor="left"
          variant="temporary"
          open={mobileNavOpen}
          classes={{
            paper: classes.navbar,
          }}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavbarLayout className={classes.navbarContent} />
        </Drawer>
      </Hidden>
    </div>
  );
}

export default NavbarWrapperLayout;
