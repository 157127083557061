import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function PhoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0398 22.3896L15.0518 22.3966C17.2398 23.7896 20.1018 23.4757 21.9358 21.6416L22.7098 20.8676C23.4296 20.1469 23.4296 18.9793 22.7098 18.2586L19.4488 14.9996C18.7281 14.2798 17.5605 14.2798 16.8398 14.9996V14.9996C16.4941 15.3457 16.025 15.5402 15.5358 15.5402C15.0466 15.5402 14.5775 15.3457 14.2318 14.9996L9.01381 9.78063C8.294 9.05991 8.294 7.89235 9.01381 7.17163V7.17163C9.35993 6.82593 9.55442 6.35682 9.55442 5.86763C9.55442 5.37844 9.35993 4.90932 9.01381 4.56363L5.75381 1.29963C5.03309 0.579812 3.86553 0.579812 3.14481 1.29963L2.37081 2.07363C0.537098 3.90777 0.222852 6.76924 1.61481 8.95763L1.62281 8.96963C5.19623 14.2595 9.75079 18.815 15.0398 22.3896V22.3896Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
