import React from 'react';
import { useDispatch } from 'react-redux';
import { Icon, IconButton } from '@material-ui/core';
import { toggleMobileNav } from 'app/store/ui';

interface NavbarMobileToggleButtonProps {
  className?: string;
}
const NavbarMobileToggleButton: React.FunctionComponent<NavbarMobileToggleButtonProps> = (
  props
) => {
  const dispatch = useDispatch();

  const children = props.children || <Icon>menu</Icon>;

  return (
    <IconButton
      className={props.className}
      onClick={() => dispatch(toggleMobileNav())}
      color="inherit"
      disableRipple
    >
      {children}
    </IconButton>
  );
};

export default NavbarMobileToggleButton;
