import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { getCurrentEnv } from 'app/helpers/getCurrentEnv';
import history from '../core/history/@history';

const trackingId = "UA-100385407-1";

const tagManagerArgs = {
  gtmId: 'GTM-P824D6H'
}

declare global {
  interface Window {dataLayer: any}
}

if(getCurrentEnv() === 'production') {
  ReactGA.initialize(trackingId);
  TagManager.initialize(tagManagerArgs);
}

export const doTrack = (pathname: string) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname);
}

history.listen(location => {
  if(getCurrentEnv() === 'production') {
    doTrack(location.pathname);
  }
});
