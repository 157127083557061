import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../core/config';

const URL = 'users/dependants.json';
const DETAILS_URL = 'users/dependants/details.json';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetDependants(params: AppNS.Params): Promise<any> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params,
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(data.results);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiCreateDependant(
  reqData: AppNS.Params
): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${URL}`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiUpdateDependant(
  reqData: AppNS.Params
): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${URL}?return_properties=true`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiUpdateDependantDetails(
  reqData: AppNS.Params
): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${DETAILS_URL}`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiDeleteDependant(reqData: AppNS.Params): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/${URL}`, { params: reqData })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
