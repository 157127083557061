// Set cookie with 31 day default expiry date
export function setCookie(
  key: string,
  value: string,
  expiryDays: number = 31,
  path: string = '/'
) {
  if (navigator.cookieEnabled) {
    const d = new Date();
    d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = key + '=' + value + ';' + expires + ';path=' + path;
  }
}

export function getCookie(key: string): string {
  if (navigator.cookieEnabled) {
    let name = key + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  } 
  return "404";
}

export function getCookieKey(base: string, suffix: string): string {
  if (base && base.length > 2 && suffix) {
    return '_' + base.slice(0, 2) + '_' + suffix;
  }
  return '';
}
