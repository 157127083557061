import * as Yup from 'yup';
import moment from 'moment';
import { getNameValidation } from 'app/ui/form-validations/nameValidation';

export interface FormValues {
  about: string;
  extraAllergies: string;
  allergies: Array<string>;
  conditions: string;
  medications: string;
  physicianName: string;
  physicianPhoneNumber: string;
  hospitalName: string;
  hospitalAddress: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  mobileNumber: string;
  customAddressId: string;
  hasCustomAddress: boolean;
  city: string;
  lat: string;
  lng: string;
  state: string;
  street1: string;
  street2: string;
  zip: string;
  isAddressEditEnabled: boolean;
  createdFromDashboard: boolean;
}

export const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  birthDate: '',
  mobileNumber: '',
  customAddressId: '',
  city: '',
  lat: '',
  lng: '',
  state: '',
  street1: '',
  street2: '',
  zip: '',
  hasCustomAddress: false,
  isAddressEditEnabled: false,
  about: '',
  allergies: [],
  extraAllergies: '',
  conditions: '',
  medications: '',
  physicianName: '',
  physicianPhoneNumber: '',
  hospitalName: '',
  hospitalAddress: '',
  createdFromDashboard: false,
};

export const validationSchema = Yup.object({
  firstName: getNameValidation('First Name'),
  lastName: getNameValidation('Last Name'),
  birthDate: Yup.date()
    .transform((value, originalValue) => {
      value = moment(originalValue, 'MM/DD/YYYY', true);
      return value.isValid() ? value.toDate() : new Date(1899, 0, 1);
    })
    .nullable()
    .required('Birth date is required')
    .min(new Date(1900, 0, 1), 'Birth date is incorrect')
    .max(new Date(2099, 0, 1), 'Birth date is incorrect'),
  mobileNumber: Yup.string()
    .trim()
    .test('mobileNumber', 'Mobile number is incorrect', (value) => {
      if (value !== '') {
        const schema = Yup.string().matches(
          /^\+1[2-9]{1}\d{2}[2-9]{1}\d{2}\d{4}$/,
          'Mobile number is incorrect'
        );
        return schema.isValidSync(value);
      }
      return true;
    }),
  city: Yup.string()
    .trim()
    .when(['hasCustomAddress', 'isAddressEditEnabled'], {
      is: true,
      then: Yup.string().required('City is required'),
    }),
  street1: Yup.string()
    .trim()
    .when(['hasCustomAddress', 'isAddressEditEnabled'], {
      is: true,
      then: Yup.string().required('Street and number is required'),
    }),
  state: Yup.string()
    .trim()
    .when(['hasCustomAddress', 'isAddressEditEnabled'], {
      is: true,
      then: Yup.string().required('State is required'),
    }),
  lat: Yup.string()
    .trim()
    .when(['hasCustomAddress', 'isAddressEditEnabled'], {
      is: true,
      then: Yup.string().required('Address is not verified'),
    }),
  lng: Yup.string()
    .trim()
    .when(['hasCustomAddress', 'isAddressEditEnabled'], {
      is: true,
      then: Yup.string().required('Address is not verified'),
    }),
  zip: Yup.string()
    .trim()
    .when(['hasCustomAddress', 'isAddressEditEnabled'], {
      is: true,
      then: Yup.string()
        .required('ZIP is required')
        .matches(/^\d{5}$/, 'ZIP is incorrect'),
    }),
  about: Yup.string()
    .trim()
    .max(1000, `Text can have max. \${max} characters`),
  allergies: Yup.array().of(Yup.string())
    .when('extraAllergies', {
    is: '', 
    then: Yup.array().of(Yup.string())
    .min(1, `Cannot be blank. Select 'No known allergies' if your loved one has no allergies`)
  }),
  conditions: Yup.string()
    .trim()
    .max(1000, `Text can have max. \${max} characters`),
  medications: Yup.string()
    .trim()
    .max(1000, `Text can have max. \${max} characters`),
});
