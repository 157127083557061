import { chunk, uniqWith } from 'lodash-es';

import { AppNS } from 'app';
import { AVAILABILITY_DAYS } from 'app/consts/availabilityData';
import {
  FormValues,
  defaultValues,
  HoursFormValues,
} from '../availability-form/validation-schema';

export const getHourTimestamp = (hour: string): number => {
  return new Date(`2000/01/01 ${hour}`).getTime();
};

export const addHoursToTimestamp = (timestamp: number, hours: number): number => {
  return new Date(timestamp + (hours * 60 * 60 * 1000)).getTime();
}

export const getTimeNow = () => {
  const d = new Date();
  const h = (d.getHours() < 10 ? '0' : '') + d.getHours();
  const m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
  return h + ':' + m;
};

const OVERNIGHT_FROM = getHourTimestamp('2:00');
const OVERNIGHT_TO = getHourTimestamp('5:00');

export const parseFormData = (data: FormValues, formId: number) => {
  //Remove hours duplicates
  const hours = uniqWith(
    data.hours,
    (arrVal: HoursFormValues, othVal: HoursFormValues): boolean => {
      return `${arrVal.from}${arrVal.to}` === `${othVal.from}${othVal.to}`;
    }
  );

  return {
    [`day_${String(formId)}`]: data.isOn
      ? hours.reduce(
          (acc, val): Array<string> => acc.concat([`${val.from}`, `${val.to}`]),
          [] as Array<string>
        )
      : [],
    [`day_${String(formId)}_overnight`]: checkIsOvernight(hours),
    [`day_${String(formId)}_autobid`]: data.isOn
      ? hours.reduce(
          (acc, val): Array<string> =>
            acc.concat([val.autoBid ? 'true' : 'false']),
          [] as Array<string>
        )
      : [],
  };
};

export const parseFormDataHours = (hours: Array<HoursFormValues>) => {
  //Remove hours duplicates
  const output = uniqWith(
    hours,
    (arrVal: HoursFormValues, othVal: HoursFormValues): boolean => {
      return `${arrVal.from}${arrVal.to}` === `${othVal.from}${othVal.to}`;
    }
  );

  return output.reduce(
    (acc, val): Array<string> => acc.concat([`${val.from}`, `${val.to}`]),
    [] as Array<string>
  );
};

export const parseDBData = (data: AppNS.User): Array<FormValues> => {
  const output = [] as Array<FormValues>;

  AVAILABILITY_DAYS.forEach((item: { name: string; id: number }) => {
    const day = `day${String(item.id)}` as keyof AppNS.User;
    const dayOvernight = `day${String(item.id)}Overnight` as keyof AppNS.User;
    const dayAutobid = `day${String(item.id)}Autobid` as keyof AppNS.User;

    const hoursArray = data[day] as Array<string>;
    const autoBidArray = data[dayAutobid] as Array<string>;

    if (hoursArray && hoursArray.length > 0) {
      const chunkedHoursArray = chunk(hoursArray, 2);
      output[item.id] = {
        isOn: true,
        hours: parseDBHours(chunkedHoursArray, autoBidArray),
        overnight: data[dayOvernight] ? true : false,
        disabled: false,
      };
    } else {
      output[item.id] = defaultValues;
    }
  });
  return output;
};

export const createEmptyData = (): Array<FormValues> => {
  const output = [] as Array<FormValues>;

  AVAILABILITY_DAYS.forEach((item: { name: string; id: number }) => {
    const hoursArray = [] as Array<string>;
    const autoBidArray = [] as Array<string>;

    if (hoursArray && hoursArray.length > 0) {
      const chunkedHoursArray = chunk(hoursArray, 2);
      output[item.id] = {
        isOn: true,
        hours: parseDBHours(chunkedHoursArray, autoBidArray),
        overnight: false,
        disabled: false,
      };
    } else {
      output[item.id] = defaultValues;
    }
  });
  return output;
};

export const checkIsDataEmpty = (data: Array<FormValues>): boolean => {
  return !data.some((item: FormValues) => {
    return item.isOn;
  });
};

export const getItemById = (id: number): { name: string; id: number } => {
  return AVAILABILITY_DAYS.filter((item: { name: string; id: number }) => {
    return id === item.id;
  })[0];
};

const parseDBHours = (
  hoursData: Array<Array<string>>,
  dayAutobid: Array<string>
): Array<HoursFormValues> => {
  return hoursData.map((item: Array<string>, index: number) => {
    return {
      from: convertLegacyHour(item[0]),
      to: convertLegacyHour(item[1]),
      autoBid: dayAutobid && dayAutobid[index] === 'true' ? true : false,
    };
  });
};

export const checkIsOvernight = (
  hoursData: Array<HoursFormValues>
): boolean => {
  return hoursData.some((data: HoursFormValues) => {
    const from = getHourTimestamp(data.from);
    const to = getHourTimestamp(data.to === "0:00" ? "23:59" : data.to);

    // range inside or intersecting
    const iFrom = from >= OVERNIGHT_FROM && from <= OVERNIGHT_TO;
    const iTo = to >= OVERNIGHT_FROM && to <= OVERNIGHT_TO;
    const intersecting = iFrom || iTo;

    //  range contains
    const contains = from < OVERNIGHT_FROM && to > OVERNIGHT_TO;

    return intersecting || contains;
  });
};

export const parseAvailabilityResponseData = (
  data: AppNS.Params
): AppNS.Params => {
  const output: AppNS.Params = {};
  for (let index = 0; index < 7; index++) {
    const key1 = `day${String(index)}Overnight`;
    output[key1] = data[key1] ? true : false;
    const key2 = `day${String(index)}Autobid`;
    output[key2] = data[key2] || [];
    const key3 = `day${String(index)}`;
    output[key3] = data[key3] || [];
  }
  return output;
};

export const convertLegacyHour = (hour: string): string => {
  if (hour === '24:00') {
    return '0:00';
  }
  return hour;
};
