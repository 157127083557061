import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { omit } from 'lodash-es';

import { AppNS } from '..';
import { AppThunk } from './index';
import {
  FormValues,
  defaultValues,
} from 'app/ui/modules/dependants/components/dependant-form/validation-schema';
import {
  apiCreateDependant,
  apiUpdateDependant,
  apiDeleteDependant,
  apiGetDependants,
  apiUpdateDependantDetails,
} from '../api/dependants';

export interface DependantsConfig {
  dependantsDeleteDisabled: boolean;
}

export interface StateDependantForm {
  id: string;
  dependantId?: string;
  isError: string;
  mode: AppNS.DependantFormMode;
  defaultValues?: FormValues;
}

export interface DependantsState {
  config: DependantsConfig;
  clientDependants: Array<AppNS.Dependant>;
  dependantInLoadingState: string;
  dependantAdded: string;
  forms: Array<StateDependantForm>;
  getDependantsError: boolean;
  isCollectionLoaded: boolean;
  isCollectionLoading: boolean;
  isValid: boolean;
}

const initialState: DependantsState = {
  config: {
    dependantsDeleteDisabled: false,
  },
  clientDependants: [],
  dependantInLoadingState: '',
  dependantAdded: '',
  forms: [],
  getDependantsError: false,
  isCollectionLoaded: false,
  isCollectionLoading: false,
  isValid: false,
};

function filterDependants(
  dependants: Array<AppNS.Dependant>
): Array<AppNS.Dependant> {
  return dependants.filter(
    (item: AppNS.Dependant) => item.type === 'dependant'
  );
}

function checkStateForms(state: DependantsState) {
  // if there's no certifications show empty form
  const dependants = filterDependants(state.clientDependants);

  // check if there is already some empty form
  const emptyForms = state.forms.filter((form: StateDependantForm) => {
    return form.mode === 'add';
  });
  if (dependants.length < 1 && emptyForms.length < 1) {
    const dependantForm = createEmptyForm();
    state.forms = [...state.forms, dependantForm];
  }
}

function checkStateValid(state: DependantsState) {
  const dependants = filterDependants(state.clientDependants);
  state.isValid = dependants.length > 0;
}

const clientDependantsSlice = createSlice({
  name: 'clientDependants',
  initialState,
  reducers: {
    setModuleConfig(
      state: DependantsState,
      action: PayloadAction<DependantsConfig>
    ) {
      state.config = action.payload;
    },
    checkState(state: DependantsState) {
      checkStateForms(state);
      checkStateValid(state);
    },
    checkStateForJob(state: DependantsState) {
      checkStateValid(state);
    },
    getDependantsStart(state: DependantsState) {
      state.isCollectionLoaded = false;
      state.isCollectionLoading = true;
    },
    getDependantsSuccess(
      state: DependantsState,
      action: PayloadAction<Array<AppNS.Dependant>>
    ) {
      state.isCollectionLoading = false;
      state.isCollectionLoaded = true;
      state.getDependantsError = false;
      state.clientDependants = action.payload.map((item) => {
        return item;
      });
    },
    getDependantsFailed(state: DependantsState) {
      state.getDependantsError = true;
      state.isCollectionLoading = false;
    },
    addFormStart(
      state: DependantsState,
      action: PayloadAction<StateDependantForm>
    ) {
      state.forms = [...state.forms, action.payload];
    },
    addDependantStart(state: DependantsState, action: PayloadAction<string>) {
      state.dependantInLoadingState = action.payload;
      state.dependantAdded = '';
    },
    addDependantSuccess(
      state: DependantsState,
      action: PayloadAction<{ formId: string; data: AppNS.Dependant }>
    ) {
      // remove form
      state.forms = state.forms.filter((form: StateDependantForm) => {
        return form.id !== action.payload.formId;
      });

      // and add dependant
      state.clientDependants = [...state.clientDependants, action.payload.data];
      state.dependantInLoadingState = '';
      state.dependantAdded = action.payload.data.id;
    },
    addDependantFailed(state: DependantsState, action: PayloadAction<string>) {
      state.dependantInLoadingState = '';
      state.dependantAdded = '';
      state.forms = state.forms.map((form: StateDependantForm) => {
        if (form.id === action.payload) {
          return { ...form, isError: action.payload };
        }
        return form;
      });
    },

    removeDependantStart(
      state: DependantsState,
      action: PayloadAction<string>
    ) {
      state.dependantInLoadingState = action.payload;
    },

    removeDependantSuccess(
      state: DependantsState,
      action: PayloadAction<string>
    ) {
      state.dependantInLoadingState = '';

      // remove dependant
      state.clientDependants = state.clientDependants.filter(
        (item: AppNS.Dependant) => {
          return item.id !== action.payload;
        }
      );
    },

    removeDependantFailed(state: DependantsState) {
      state.dependantInLoadingState = '';
    },

    editDependant(state: DependantsState, action: PayloadAction<string>) {
      // get dependant data
      let dependant = state.clientDependants.filter((item: AppNS.Dependant) => {
        return item.id === action.payload;
      })[0];

      const form = createEmptyForm(action.payload);

      // add form with edit mode
      form.mode = 'edit';
      form.dependantId = action.payload;
      form.defaultValues = {
        ...defaultValues,
        ...dependant,
        ...omit(dependant.customAddress, ['id']),
      };
      state.forms = [...state.forms, form];
    },

    updateDependantStart(
      state: DependantsState,
      action: PayloadAction<string>
    ) {
      state.dependantInLoadingState = action.payload;
    },

    updateDependantSuccess(
      state: DependantsState,
      action: PayloadAction<{ formId: string; data: AppNS.Dependant }>
    ) {
      state.dependantInLoadingState = '';

      // remove form
      state.forms = state.forms.filter((form: StateDependantForm) => {
        return form.id !== action.payload.formId;
      });

      // update dependant
      state.clientDependants = state.clientDependants.map(
        (dependant: AppNS.Dependant) => {
          if (dependant.id === action.payload.data.id) {
            return action.payload.data;
          }
          return dependant;
        }
      );
    },

    updateDependantDetailsSuccess(
      state: DependantsState,
      action: PayloadAction<{ formId: string; data: AppNS.Params }>
    ) {
      state.dependantInLoadingState = '';

      // remove form
      state.forms = state.forms.filter((form: StateDependantForm) => {
        return form.id !== action.payload.formId;
      });

      // update dependant
      state.clientDependants = state.clientDependants.map(
        (dependant: AppNS.Dependant) => {
          if (dependant.id === action.payload.formId) {
            return { ...dependant, ...action.payload.data };
          }
          return dependant;
        }
      );
    },

    updateDependantFailed(state: DependantsState) {
      state.dependantInLoadingState = '';
    },

    updateDependantAdded(state: DependantsState, action: PayloadAction<string>) {
      state.dependantAdded = action.payload;
    },

    removeForm(state: DependantsState, action: PayloadAction<string>) {
      // remove form
      state.forms = state.forms.filter((form: StateDependantForm) => {
        return form.id !== action.payload;
      });
    },
  },
});

export const {
  addDependantFailed,
  addDependantStart,
  addDependantSuccess,
  addFormStart,
  checkState,
  checkStateForJob,
  editDependant,
  getDependantsFailed,
  getDependantsStart,
  getDependantsSuccess,
  removeDependantFailed,
  removeDependantStart,
  removeDependantSuccess,
  removeForm,
  setModuleConfig,
  updateDependantFailed,
  updateDependantStart,
  updateDependantSuccess,
  updateDependantDetailsSuccess,
  updateDependantAdded,
} = clientDependantsSlice.actions;

export default clientDependantsSlice.reducer;

export const fetchDependants = (params: AppNS.Params): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(getDependantsStart());
    // const dependantsResponse = [] as Array<AppNS.Dependant>;
    const dependantsResponse = await apiGetDependants(params);
    dispatch(getDependantsSuccess(dependantsResponse));
    dispatch(checkState());
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getDependantsFailed());
  }
};

export const fetchDependantsForJob = (params: AppNS.Params): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(getDependantsStart());
    // const dependantsResponse = [] as Array<AppNS.Dependant>;
    const dependantsResponse = await apiGetDependants(params);
    dispatch(getDependantsSuccess(dependantsResponse));
    dispatch(checkStateForJob());
  } catch (err) {
    if (err instanceof axios.Cancel) {
      // when request is cancelled there is no need to throw error.
      return;
    }
    console.error(err.toString());
    dispatch(getDependantsFailed());
  }
};

export const addDependant = (
  params: AppNS.Params,
  formId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(addDependantStart(formId));
    const dependantData = await apiCreateDependant(params);

    dispatch(
      addDependantSuccess({
        formId,
        data: createDocumentData(
          dependantData.id,
          params,
          dependantData
        ),
      })
    );

    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(addDependantFailed(err.toString()));
  }
};

export const updateDependant = (
  params: AppNS.Params,
  formId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateDependantStart(formId));

    const properties = { ...params };
    delete properties.id;
    const dependantData = await apiUpdateDependant({
      id: params.id,
      ...properties,
    });

    dispatch(
      updateDependantSuccess({
        formId,
        data: createDocumentData(dependantData.id, params, dependantData),
      })
    );

    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(updateDependantFailed());
  }
};

export const updateDependantDetails = (
  params: AppNS.Params,
  formId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateDependantStart(formId));

    const properties = { ...params };
    delete properties.id;
    await apiUpdateDependantDetails({
      id: params.id,
      ...properties,
    });

    dispatch(
      updateDependantDetailsSuccess({
        formId,
        data: params.properties,
      })
    );

    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(updateDependantFailed());
  }
};

export const removeDependant = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(removeDependantStart(id));
    await apiDeleteDependant({ id });
    dispatch(removeDependantSuccess(id));
    dispatch(checkState());
  } catch (err) {
    console.error(err.toString());
    dispatch(removeDependantFailed());
  }
};

export const addForm = (): AppThunk => (dispatch) => {
  const formData = createEmptyForm();
  dispatch(addFormStart(formData));
  dispatch(checkState());
};

export const editDependantAction = (id: string): AppThunk => (dispatch) => {
  dispatch(editDependant(id));
};

export const removeFormAction = (id: string): AppThunk => (dispatch) => {
  dispatch(removeForm(id));
  dispatch(checkStateForJob());
};

export const createEmptyForm = (
  id: string = String(new Date().getTime() + Math.round(Math.random() * 1000))
): StateDependantForm => {
  return {
    id,
    isLoading: false,
    isError: '',
    mode: 'add',
  } as StateDependantForm;
};

export const createDocumentData = (
  id: string,
  params: AppNS.Params,
  apiParams: AppNS.Params
): AppNS.Dependant => {
  let address = null;

  if (params.models) {
    address = params.models.address[0].properties;
    address.id = params.models.address[0].id;
  } else if (apiParams && apiParams.customAddress) {
    address = apiParams.customAddress;
  }

  return {
    ...params.properties,
    customAddress: address,
    type: 'dependant',
    ...apiParams.properties,
    id,
  } as AppNS.Dependant;
};

export const setConfig = (config: DependantsConfig): AppThunk => (dispatch) => {
  dispatch(setModuleConfig(config));
};
