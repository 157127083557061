import { AppNS } from 'app';
import { ApiNS } from '../api';
import { parseReportStateByDate } from './../common-parsers/common-parsers';
import { ImageInfo } from 'app/ui/components/multi-image-uploader/MultiImageUploader';

export const getTimeFromData = (
  id: string,
  time: string
): ApiNS.UpdateTimeRequestFrom => {
  return {
    id,
    properties: {
      proposedTimeFrom: time,
    },
  };
};

export const getTimeToData = (
  id: string,
  time: string
): ApiNS.UpdateTimeRequestTo => {
  return {
    id,
    properties: {
      proposedTimeTo: time,
    },
  };
};

export const parseReport = (reportData: AppNS.Params): AppNS.JobEventReport => {
  const output = ({
    ...reportData,
    photos: parsePhotos(reportData.photos),
    state: parseReportStateByDate(reportData.jobEvent, reportData.state),
  } as unknown) as AppNS.JobEventReport;
  return output;
};

const parsePhotos = (photos: Array<AppNS.Params>): Array<ImageInfo> => {
  return photos.map((item) => {
    return {
      id: item.id,
      thumbnail: item.file.versions.thumb,
      url: item.file.versions.big,
      uppyId: '',
      isLoaded: true,
      isRemoved: false,
    };
  });
};
