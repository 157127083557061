import { AppNS } from 'app';

export const hoursIntToTime = (int: number) => {
  const hrs = Math.floor(int / 60).toString();
  let mins = (int % 60).toString();
  if (mins.length < 2) {
    mins += '0';
  }
  return `${hrs}:${mins}`;
};

export const timeToHoursInt = (time: string) => {
  const split = time.split(':');
  return parseInt(split[0]) * 60 + parseInt(split[1]);
};

export const parseHoursToTimes = (items: Array<string> | undefined) => {
  return (
    items?.map((item: string) => {
      return hoursIntToTime(parseInt(item));
    }) || []
  );
};

export const parseTimesToHoursInt = (items: Array<string> | undefined) => {
  return items?.map((hour: string) => parseInt(hour)) || [];
};

export const getTimeSearch = (
  item:
    | AppNS.JobEvent
    | AppNS.LeadProposalEvent
    | { timeFrom: string; timeTo: string }
) => {
  if (item.timeTo && item.timeFrom) {
    const timeFromInt = timeToHoursInt(item.timeFrom);
    let timeToInt = timeToHoursInt(item.timeTo);

    if (timeToInt === 0) {
      timeToInt = 1440;
    }

    const timeSearch = [];
    for (let i = timeFromInt; i <= timeToInt; i = i + 15) {
      timeSearch.push(i.toString());
    }
    return timeSearch;
  }
  return [];
};

export const getTimeSearchesByDate = (
  data: Array<AppNS.JobEvent>,
  date: string,
  proposalEvents: Array<AppNS.LeadProposalEvent>
) => {
  const eventsInDate =
    data.filter((event: AppNS.JobEvent) => {
      return event.date === date;
    }) || [];

  if (eventsInDate.length > 1) {
    const arrays = eventsInDate.map((event: AppNS.JobEvent): Array<string> => {
      const proposalEvent = proposalEvents.find(
        (proposalEvent: AppNS.LeadProposalEvent) => {
          return proposalEvent.jobEventId === event.id;
        }
      );
      if (proposalEvent) {
        if (
          proposalEvent.notAvailable !== true &&
          proposalEvent.timeFrom &&
          proposalEvent.timeTo
        ) {
          return getTimeSearch(proposalEvent);
        }
        return [];
      }
      return event.timeSearch;
    });
    return arrays;
  }
  return [];
};
