import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function ReviewsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 25" fill="none">
      <g fill="none">
        <path
          d="M0.806152 15.25V24.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.806152 22.75H16.9312C16.9312 21.0931 15.4873 19.75 13.7062 19.75H9.6749C9.6749 18.0931 8.23102 16.75 6.4499 16.75H0.806152"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.44971 19.75H9.67471"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2019 2.23282L20.3573 6.17882H24.0424C24.4276 6.17928 24.7744 6.39603 24.9205 6.72762C25.0666 7.05921 24.983 7.44003 24.7089 7.69182L21.3581 10.7598L23.2297 14.7598C23.3927 15.1078 23.2984 15.5137 22.9955 15.768C22.6927 16.0224 22.2489 16.0682 21.8935 15.8818L17.3548 13.5078L12.8162 15.8818C12.4607 16.0682 12.017 16.0224 11.7141 15.768C11.4112 15.5137 11.317 15.1078 11.48 14.7598L13.3526 10.7598L9.99753 7.69182C9.72231 7.44039 9.63803 7.05904 9.78429 6.727C9.93056 6.39497 10.2783 6.17829 10.664 6.17882H14.3491L16.4991 2.23282C16.659 1.93122 16.9891 1.74023 17.3505 1.74023C17.712 1.74023 18.0421 1.93122 18.2019 2.23282V2.23282Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
