import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { Redirect } from 'react-router';

interface HasToBeClientOnboardingStep1GuardProps {
  children: React.ReactNode;
}

export const HasToBeClientOnboardingStep1Guard = (
  props: HasToBeClientOnboardingStep1GuardProps
) => {
  const { children } = props;

  const user = useSelector(({ auth }: AppState) => auth.user);

  if (!user) {
    return <Redirect to={'/auth/login'}></Redirect>;
  }

  if (user.onboardingStep === "background_check") {
    return <Redirect to={'/job-create'}></Redirect>;
  }

  return <>{children}</>;
};

export default HasToBeClientOnboardingStep1Guard;
