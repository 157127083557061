import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { IconButton, Box, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { useStyles } from './ToolbarMenuStyles';
import { AppNS } from '../../../..';

interface ToolbarMenuProps {
  menuItems: Array<AppNS.ToolbarMenuItem>;
  noFloatingMenu?: boolean;
}

const ToolbarMenu: React.FunctionComponent<ToolbarMenuProps> = (props) => {
  const { menuItems, noFloatingMenu } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (redirectHref: string) => {
    setAnchorEl(null);
    if (redirectHref) {
      history.push(redirectHref);
    }
  };
  const classes = useStyles();

  return (
    <>
      {noFloatingMenu && (
        <ul className={classes.menu}>
          {menuItems.map((item: AppNS.ToolbarMenuItem) => (
            <li key={item.id}>
              <NavLink exact to={item.href}>
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      )}

      {!noFloatingMenu && (
        <>
          <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
            <ul className={classes.menu}>
              {menuItems.map((item: AppNS.ToolbarMenuItem) => (
                <li key={item.id}>
                  <NavLink to={item.href}>{item.name}</NavLink>
                </li>
              ))}
            </ul>
          </Box>

          <Box display={{ xs: 'block', sm: 'block', md: 'none' }} ml={1}>
            <IconButton aria-label="User Menu" onClick={handleClick}>
              <MenuIcon fontSize="inherit" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => {
                handleClose('');
              }}
            >
              {menuItems.map((item: AppNS.ToolbarMenuItem) => (
                <MenuItem
                  key={item.id}
                  onClick={() => {
                    handleClose(item.href);
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </>
      )}
    </>
  );
};

export default ToolbarMenu;
