import { AppNS } from 'app';

export const saveJob = (job: AppNS.Job): void => {
  localStorage.setItem('newJob', JSON.stringify(job));
};

export const updateJobState = (state: AppNS.JobState): void => {
  const job = getJobFromStorage();
  if (job) {
    job.state = state;
    localStorage.setItem('newJob', JSON.stringify(job));
  };
};

export const deleteJob = (): void => {
  localStorage.removeItem('newJob');
};

export const getJobById = (id: string): AppNS.ClientJob | null => {
  const job = localStorage.getItem('newJob');
  if (job) {
    const jobData = JSON.parse(job) as AppNS.ClientJob;
    if (id === jobData.id) {
      return jobData;
    }
  }

  return null;
};

export const getJobFromStorage = (): AppNS.ClientJob | null => {
  const job = localStorage.getItem('newJob');
  if (job) {
    return JSON.parse(job) as AppNS.ClientJob;
  }

  return null;
};

export const getStoredJobParams = (): AppNS.Params => {
  const job = getJobFromStorage();
  if (job) {
    return {
      job_id: job.id,
      picked_hero_id: job.pickedHeroId,
    };
  }
  return {};
};
