import axios from 'axios';

import { API_URL } from 'app/core/config';

export function apiLogout(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/auth`)
      .then(() => {
        resolve(true);
      })
      .catch(reject);
  });
}
