import { AppNS } from '..';
import axios from 'axios';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  apiGetJobLeads,
  apiGetJobLead,
  apiSetLeadState,
  apiCancelJob,
  apiDeleteJobEvent,
  apiAddBookingReview,
  apiGetBookingReviews,
  apiUpdatePaymentMethod,
} from '../api/client-bookings/client-bookings';

export interface ClientBookingsState {
  page: number;
  perPage: number;
  search: string;
  totalEntries: number;
  items: Array<AppNS.JobLead>;
  currentLead: AppNS.JobLead | null;
  isLeadLoading: boolean;
  isLoaded: boolean;
  isLoading: boolean;
  isLeadLoadingError: boolean;
  isDeleteEventLoading: boolean;
  isDeleteEventError: number;
  isLeadStateLoadingError: boolean;
  isLeadStateLoading: boolean;

  isCancellingJob: boolean;
  isCancellingJobError: boolean;
  isJobCancelled: string;

  detailsActionDone: boolean;

  isAddingReviewError: boolean;
  isAddingReview: boolean;

  reviews: Array<AppNS.BookingReview>;
  isLoadingReviews: boolean;
  isLoadingReviewsError: boolean;

  paymentMethod: AppNS.Params;
  isUpdatingPaymentMethod: boolean;
  paymentMethodIsUpdated: boolean;
  isUpdatingPaymentMethodError: boolean;
}

const initialState: ClientBookingsState = {
  page: 1,
  perPage: 10,
  search: '',
  currentLead: null,
  totalEntries: 0,
  items: [],
  isLeadLoading: false,
  isLeadLoadingError: false,
  isDeleteEventLoading: false,
  isDeleteEventError: 0,
  isLeadStateLoadingError: false,
  isLeadStateLoading: false,
  isLoaded: false,
  isLoading: false,
  isCancellingJob: false,
  isCancellingJobError: false,
  isJobCancelled: '',
  detailsActionDone: false,
  isAddingReviewError: false,
  isAddingReview: false,
  reviews: [],
  isLoadingReviews: false,
  isLoadingReviewsError: false,
  paymentMethod: {},
  isUpdatingPaymentMethod: false,
  paymentMethodIsUpdated: false,
  isUpdatingPaymentMethodError: false,
};

const clientBookings = createSlice({
  name: 'clientBookings',
  initialState,
  reducers: {
    resetCollection(state) {
      state.items = [];
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCurrentParams(state, action: PayloadAction<AppNS.Params>) {
      state.page = action.payload.page;
      state.search = action.payload.search;
    },
    getLeadsStart(state: ClientBookingsState) {
      state.isLoading = true;
    },
    getLeadsSuccess(
      state: ClientBookingsState,
      action: PayloadAction<AppNS.GetJobLeadsApiResponse>
    ) {
      // state.error = null;
      state.isLoading = false;
      state.items = action.payload.results;
      state.totalEntries = action.payload.totalEntries;
    },
    getLeadsFailed(state: ClientBookingsState, action: PayloadAction<string>) {
      state.items = [];
      // state.error = action.payload;
      state.isLoading = false;
    },
    getLeadStart(state: ClientBookingsState) {
      state.currentLead = null;
      state.isLoaded = false;
      state.isLeadLoading = true;
      state.isLeadLoadingError = false;
    },
    getCurrentLeadSuccess(
      state: ClientBookingsState,
      action: PayloadAction<AppNS.JobLead>
    ) {
      state.isLeadLoadingError = false;
      state.isLeadLoading = false;
      state.currentLead = action.payload;
    },
    getLeadFailed(state: ClientBookingsState) {
      state.isLeadLoadingError = true;
      state.isLeadLoading = false;
    },
    setLeadStateStart(state: ClientBookingsState) {
      state.isLeadStateLoading = true;
    },
    setLeadStateSuccess(
      state: ClientBookingsState,
      action: PayloadAction<AppNS.JobLeadState>
    ) {
      state.isLeadStateLoading = false;
      if (state.currentLead) {
        state.currentLead.state = action.payload;
      }
    },
    setLeadStateFailed(state: ClientBookingsState) {
      state.isLeadStateLoadingError = true;
      state.isLeadStateLoading = false;
    },
    deleteJobEventStart(state: ClientBookingsState) {
      state.isDeleteEventError = 0;
      state.isDeleteEventLoading = true;
    },
    deleteJobEventFailed(state: ClientBookingsState) {
      state.isDeleteEventError = new Date().getTime();
      state.isDeleteEventLoading = false;
    },
    deleteJobEventSuccess(
      state: ClientBookingsState,
      action: PayloadAction<string>
    ) {
      state.isDeleteEventError = 0;
      state.isDeleteEventLoading = false;

      if (state.currentLead) {
        const events = state.currentLead.job.jobEvents;
        if (events) {
          state.currentLead.job.jobEvents = events.map((event) => {
            if (event.id !== action.payload) {
              return event;
            } else {
              return { ...event, state: 'deleted_by_client' };
            }
          });
        }
      }
    },

    cancelJobStart(state: ClientBookingsState) {
      state.isCancellingJob = true;
    },
    cancelJobSuccess(
      state: ClientBookingsState,
      action: PayloadAction<string>
    ) {
      state.isCancellingJob = false;
      state.isJobCancelled = action.payload;
      if (state.currentLead) {
        state.currentLead.state = 'cancelled_by_client';
      }
      if (state.items.length) {
        state.items = state.items.map((item) => {
          if (item.id === action.payload) {
            return { ...item, isDeleted: true };
          }
          return item;
        });
      }
    },
    cancelJobFailed(state: ClientBookingsState) {
      state.isCancellingJobError = true;
      state.isCancellingJob = false;
    },
    setDetailsActionDone(
      state: ClientBookingsState,
      action: PayloadAction<boolean>
    ) {
      state.detailsActionDone = action.payload;
    },
    setJobCancelled(state: ClientBookingsState, action: PayloadAction<string>) {
      state.isJobCancelled = action.payload;
    },
    resetCurrentLead(state: ClientBookingsState) {
      state.currentLead = null;
    },

    addReviewStart(state: ClientBookingsState) {
      state.isAddingReview = true;
    },
    addReviewSuccess(
      state: ClientBookingsState,
      action: PayloadAction<AppNS.BookingReview>
    ) {
      state.isAddingReview = false;
      if (state.currentLead) {
        state.currentLead.review = action.payload;
      }
    },
    addReviewFailed(state: ClientBookingsState) {
      state.isAddingReviewError = true;
      state.isAddingReview = false;
    },
    getReviewsStart(state: ClientBookingsState) {
      state.isLoadingReviews = true;
    },
    getReviewsSuccess(
      state: ClientBookingsState,
      action: PayloadAction<Array<AppNS.BookingReview>>
    ) {
      state.isLoadingReviews = false;
      state.reviews = action.payload;
    },
    getReviewsFailed(state: ClientBookingsState) {
      state.isLoadingReviewsError = true;
      state.isLoadingReviews = false;
      state.reviews = [];
    },
    updatePaymentMethodStart(state: ClientBookingsState) {
      state.isUpdatingPaymentMethod = true;
      state.paymentMethodIsUpdated = false
    },
    updatePaymentMethodSuccess(
      state: ClientBookingsState,
      action: PayloadAction<AppNS.Params>
    ) {
      state.isUpdatingPaymentMethod = false;
      state.paymentMethod = action.payload;
      state.paymentMethodIsUpdated = true;
      state.isUpdatingPaymentMethodError = false;
    },
    updatePaymentMethodFailed(state: ClientBookingsState) {
      state.isUpdatingPaymentMethodError = true;
      state.isUpdatingPaymentMethod = false;
      state.paymentMethod = {};
    },
    setPaymentMethodUpdated(
      state: ClientBookingsState,
      action: PayloadAction<boolean>
    ) {
      state.paymentMethodIsUpdated = action.payload;
    }
  },
});

export const {
  cancelJobFailed,
  cancelJobStart,
  cancelJobSuccess,
  deleteJobEventFailed,
  deleteJobEventStart,
  deleteJobEventSuccess,
  getCurrentLeadSuccess,
  getLeadFailed,
  getLeadsStart,
  getLeadsSuccess,
  getLeadStart,
  setCurrentParams,
  setDetailsActionDone,
  setLeadStateFailed,
  setLeadStateStart,
  setLeadStateSuccess,
  setJobCancelled,
  resetCurrentLead,
  addReviewStart,
  addReviewSuccess,
  addReviewFailed,
  getReviewsStart,
  getReviewsSuccess,
  getReviewsFailed,
  updatePaymentMethodStart,
  updatePaymentMethodSuccess,
  updatePaymentMethodFailed,
  setPaymentMethodUpdated
} = clientBookings.actions;

export default clientBookings.reducer;

export const getLeads =
  (type: string, searchQuery: string, page: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getLeadsStart());
      dispatch(resetCurrentLead());
      const result = await apiGetJobLeads(type, searchQuery, page);
      dispatch(getLeadsSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getLeadFailed());
    }
  };

export const getCurrentLead =
  (type: string, id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getLeadStart());
      const result = await apiGetJobLead(type, id);
      dispatch(getCurrentLeadSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getLeadFailed());
    }
  };

export const setLeadState =
  (id: string, state: AppNS.JobLeadState): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLeadStateStart());
      const result = await apiSetLeadState({
        id,
        properties: {
          state: state,
        },
      });
      dispatch(setLeadStateSuccess(result));
      dispatch(setDetailsActionDone(true));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(setLeadStateFailed());
    }
  };

export const deleteJobEvent =
  (id: string, msg: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteJobEventStart());
      await apiDeleteJobEvent({ id, msg });
      dispatch(deleteJobEventSuccess(id));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err);
      dispatch(deleteJobEventFailed());
    }
  };

export const cancelJob =
  (id: string, message: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(cancelJobStart());
      await apiCancelJob({ id, message });
      dispatch(cancelJobSuccess(id));
      dispatch(setDetailsActionDone(true));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err);
      dispatch(cancelJobFailed());
    }
  };

export const addReview =
  (bookingId: string, body: string, rating: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addReviewStart());
      const id = await apiAddBookingReview({
        properties: { bookingId, body, rating },
      });

      dispatch(addReviewSuccess({ id, body, rating }));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err);
      dispatch(addReviewFailed());
    }
  };

export const getReviews =
  (jobId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getReviewsStart());
      const result = await apiGetBookingReviews(jobId);
      dispatch(getReviewsSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err);
      dispatch(getReviewsFailed());
    }
  };

export const updatePaymentMethod =
  (id: string, bookingId: string, jobId: string ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updatePaymentMethodStart());
      const result = await apiUpdatePaymentMethod({ id, bookingId, jobId });
      dispatch(updatePaymentMethodSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(updatePaymentMethodFailed());
    }
  };
