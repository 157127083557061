import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../../core/config';
import { ApiNS } from '../api';
import { ImageInfo } from 'app/ui/components/multi-image-uploader/MultiImageUploader';
import { parseReport } from './hero-reports-parsers';

const URL = 'reports';
const TIME_URL = 'update_time';
const NOTES_URL = 'update_notes';
const CHECKLIST_URL = 'checklist_items';
const PHOTOS_URL = 'photos';
const REPORTS_URL = 'reports';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetReport(id: string): Promise<AppNS.JobEventReport> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}/${id}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {role: 'hero'}
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(parseReport(data.model));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiUpdateTime<T>(data: T): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${URL}/${TIME_URL}.json`, data)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiUpdateReportHeroNotes(
  reqData: ApiNS.UpdateReportMessageRequest
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${URL}/${NOTES_URL}.json`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiUpdateReportChecklistItem(
  reqData: ApiNS.UpdateReportChecklisItemRequest
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${CHECKLIST_URL}.json`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiFinalizeReport(reportId: string): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${REPORTS_URL}/finalize.json`, { id: reportId })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiPausePaymentRequest(reportId: string): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${REPORTS_URL}/cancel_payment_request.json`, { id: reportId })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject(data.errors);
        }
      })
      .catch(reject);
  });
}

export function apiAddReportImage(
  reqData: ApiNS.AddReportImageRequest
): Promise<ImageInfo> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${PHOTOS_URL}.json`, reqData)
      .then(({ data }) => {
        if (data.model) {
          // Added timeout because of s3 404 error when private url is invoked immediately
          setTimeout(() => {
            resolve({
              id: data.model.id,
              thumbnail: data.model.file.versions.thumb,
              url: data.model.file.versions.big,
              uppyId: '',
              isLoaded: true,
              isRemoved: false,
            });
          }, 500);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiDeleteReportImage(id: string): Promise<ImageInfo> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/${PHOTOS_URL}.json`, { params: { id } })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetReports(
  query: string,
  dateFrom: string,
  dateTo: string,
  page: number
): Promise<ApiNS.ReportsListResponse> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${REPORTS_URL}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          query,
          date_from: dateFrom,
          date_to: dateTo,
          states: ['paid', 'finalized_report'],
          page,
        },
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetReportsCsv(
  query: string,
  dateFrom: string,
  dateTo: string
): Promise<Blob> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${REPORTS_URL}.csv`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          query,
          date_from: dateFrom,
          date_to: dateTo,
          states: ['paid', 'finalized_report'],
        },
        headers: {
          Accept: 'text/csv',
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(new Blob([data], { type: 'text/csv' }));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
