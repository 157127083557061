import { AppNS } from 'app';
import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const SAVINGS_REDUCERS = {
  getSavingsStart(state: AuthState) {
    state.isLoadingSavings = true;
    state.isLoadingSavingsError = false;
  },
  getSavingsFailed(state: AuthState) {
    state.isLoadingSavings = false;
    state.isLoadingSavingsError = true;
  },
  getSavingsSuccess(
    state: AuthState,
    action: PayloadAction<AppNS.Params>
  ) {
    state.isLoadingSavings = false;
    state.isLoadingSavingsError = false;
    state.userSavings = action.payload;
  },
}
