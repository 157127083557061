import React from 'react';
import { AppBar, Hidden, Icon, Theme, Avatar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/rootReducer';
import NavbarMobileToggleButton from './NavbarMobileToggleButton';
import Navigation from './Navigation/Navigation';
import { getNavigationConfig } from './Navigation/config';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: theme.palette.background.paper,
  },
  bar: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(8),
    background: theme.palette.secondary.main,
    borderRadius: `0 0 0 30px`,
  },
  barClient: {
    background: theme.palette.primary.main,
  },
  avatarContainer: {
    position: 'absolute',
    left: 28,
    bottom: -24,
  },

  backButtonContainer: {
    position: 'absolute',
    left: 0,
    top: 8,
    color: theme.palette.background.paper,
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.primary.dark,
  },
  navigationContainer: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    overflow: 'auto',
  },
  userNameHeader: {
    textTransform: 'capitalize',
  },
}));

interface NavbarLayoutProps {
  className?: string;
}

const NavbarLayout: React.FunctionComponent<NavbarLayoutProps> = (props) => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);

  if (!user) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} color="transparent" position="static" className="">
        <div
          className={clsx(
            classes.bar,
            user.roles.includes('client') && classes.barClient
          )}
        >
          <Hidden mdUp>
            <div className={classes.backButtonContainer}>
              <NavbarMobileToggleButton>
                <Icon>arrow_back</Icon>
              </NavbarMobileToggleButton>
            </div>
          </Hidden>
          <Box
            component="h5"
            fontSize="2rem"
            color="#fff"
            m={0}
            p={0}
            className={classes.userNameHeader}
          >
            {user.roles.includes('client') && <>Client</>}
            {user.roles.includes('hero') && <>Hero</>}, {user.firstName}
          </Box>
          <div className={classes.avatarContainer}>
            <Avatar
              alt={user.firstName}
              src={user.avatar.thumb}
              className={classes.avatar}
            />
          </div>
        </div>
      </AppBar>

      <div className={classes.navigationContainer}>
        <Navigation navigation={getNavigationConfig(user)} />
      </div>
    </div>
  );
};

export default NavbarLayout;
