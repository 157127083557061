import { PayloadAction } from '@reduxjs/toolkit';
import { AppNS } from 'app';
import { AuthState } from '../../auth';

export const LOCATIONS_REDUCERS = {
  setAddressStart(state: AuthState) {
    state.isChangingAddress = true;
    state.isChangingAddressError = false;
  },
  setAddressFailed(state: AuthState) {
    state.isChangingAddressError = true;
  },
  setAddressSuccess(state: AuthState, action: PayloadAction<AppNS.Address>) {
    state.isChangingAddress = false;
    state.isChangingAddressError = false;

    if (state.user) {
      if (state.user.addresses.length === 1) {
        state.user.addresses = [...state.user.addresses, action.payload];
      } else {
        state.user.addresses = state.user.addresses.map((item) => {
          if (item.type === 'service') {
            return action.payload;
          }
          return item;
        });
      }
    }
  },
  setCurrentDistanceStart(state: AuthState) {
    state.isChangingCurrentDistance = true;
    state.isChangingCurrentDistanceError = false;
  },
  setCurrentDistanceFailed(state: AuthState) {
    state.isChangingCurrentDistanceError = true;
  },
  setCurrentDistanceSuccess(state: AuthState, action: PayloadAction<string>) {
    state.isChangingCurrentDistance = false;
    state.isChangingCurrentDistanceError = false;

    if (state.user) {
      state.user.serviceDistanceMi = String(action.payload);
    }
  },
  setCurrentServiceAddressIdStart(state: AuthState) {
    state.isChangingCurrentServiceAddressId = true;
    state.isChangingCurrentServiceAddressIdError = false;
  },
  setCurrentServiceAddressIdFailed(state: AuthState) {
    state.isChangingCurrentServiceAddressIdError = true;
  },
  setCurrentServiceAddressIdSuccess(
    state: AuthState,
    action: PayloadAction<string>
  ) {
    state.isChangingCurrentServiceAddressId = false;
    state.isChangingCurrentServiceAddressIdError = false;

    if (state.user) {
      state.user.serviceAddressId = action.payload;
    }
  },
};
