import moment from 'moment';

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const checkIsDateNewer = (
  currentDate: string | null,
  incomingDate: string | null
): boolean => {
  if (!currentDate) {
    return true;
  }

  const current = moment(currentDate, DATETIME_FORMAT).valueOf();
  const incoming = moment(incomingDate, DATETIME_FORMAT).valueOf();
  if (incoming > current) {
    return true;
  }

  return false;
};
