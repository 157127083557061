import { ApiNS } from 'app/api/api';
import { AppNS } from '..';
import axios from 'axios';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiGetSchedule } from 'app/api/schedule';

export interface ScheduleListState {
  search: string;
  dateFrom: string;
  dateTo: string;
  totalEntries: number;
  items: Array<AppNS.ScheduleItem>;
  isLoading: boolean;
}

const initialState: ScheduleListState = {
  search: '',
  dateFrom: '',
  dateTo: '',
  totalEntries: 0,
  items: [],
  isLoading: false,
};

const scheduleList = createSlice({
  name: 'scheduleList',
  initialState,
  reducers: {
    resetCollection(state) {
      state.items = [];
    },
    setCurrentParams(state, action: PayloadAction<AppNS.Params>) {
      state.search = action.payload.search;
      state.dateTo = action.payload.dateTo;
      state.dateFrom = action.payload.dateFrom;
    },
    getScheduleStart(state: ScheduleListState) {
      state.isLoading = true;
    },
    getScheduleSuccess(
      state: ScheduleListState,
      action: PayloadAction<ApiNS.ScheduleListResponse>
    ) {
      // state.error = null;
      state.isLoading = false;
      state.items = action.payload.results;
    },
    getScheduleFailed(state: ScheduleListState) {
      state.items = [];
      // state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  resetCollection,
  setCurrentParams,
  getScheduleStart,
  getScheduleSuccess,
  getScheduleFailed,
} = scheduleList.actions;

export default scheduleList.reducer;

export const getSchedule =
  (
    userRole: string,
    sectionId: string,
    searchQuery: string,
    dateFrom: string,
    dateTo: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getScheduleStart());
      const result = await apiGetSchedule(
        userRole,
        sectionId,
        searchQuery,
        dateFrom,
        dateTo
      );
      dispatch(getScheduleSuccess(result));
    } catch (err) {
      if (err instanceof axios.Cancel) {
        // when request is cancelled there is no need to throw error.
        return;
      }
      console.error(err.toString());
      dispatch(getScheduleFailed());
    }
  };
