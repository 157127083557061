import axios, { Canceler } from 'axios';

import { API_URL } from '../../core/config';
import { AppNS } from 'app';

const CLIENT_CORPORATE_INFO_URL = 'user_corporate.json';
const CLIENT_CORPORATE_STATS_URL = 'user_corporate/stats.json';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function apiGetClientCorporate(): Promise<AppNS.Params> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${CLIENT_CORPORATE_INFO_URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetClientCorporateStats(): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${CLIENT_CORPORATE_STATS_URL}`, {})
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetClientCorporate(
  formData: AppNS.Params
): Promise<AppNS.Params> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/users/corporate-details.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        properties: formData,
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch((error) => {
        if (error.response) reject(error.response.data);
        else {
          reject();
        }
      });
  });
}
