import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function HowItWorksIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 13.5C0.75 12.6716 1.42157 12 2.25 12H15.75C16.5784 12 17.25 12.6716 17.25 13.5V21C17.25 21.8284 16.5784 22.5 15.75 22.5H2.25C1.42157 22.5 0.75 21.8284 0.75 21V13.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.25 19.832L22.237 21.702C22.4672 21.7882 22.7251 21.7561 22.9272 21.6161C23.1293 21.476 23.2499 21.2459 23.25 21V14.164C23.2497 13.9183 23.1289 13.6884 22.9268 13.5487C22.7247 13.4091 22.4669 13.3774 22.237 13.464L17.25 15.334L17.25 19.832Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 15H12.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 9C6.57107 9 8.25 7.32107 8.25 5.25C8.25 3.17893 6.57107 1.5 4.5 1.5C2.42893 1.5 0.75 3.17893 0.75 5.25C0.75 7.32107 2.42893 9 4.5 9Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.25 9C15.9069 9 17.25 7.65685 17.25 6C17.25 4.34315 15.9069 3 14.25 3C12.5931 3 11.25 4.34315 11.25 6C11.25 7.65685 12.5931 9 14.25 9Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
