import { AppNS } from 'app';
import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const CREDIT_CARDS_REDUCERS = {
  getCreditCardsStart(state: AuthState) {
    state.isLoadingCreditCard = true;
    state.isLoadingCreditCardError = false;
  },
  getCreditCardsFailed(state: AuthState) {
    state.isLoadingCreditCard = false;
    state.isLoadingCreditCardError = true;
  },
  getCreditCardsSuccess(
    state: AuthState,
    action: PayloadAction<Array<AppNS.CreditCard>>
  ) {
    state.isLoadingCreditCard = false;
    state.isLoadingCreditCardError = false;
    state.userCreditCards = action.payload;
  },
  addCreditCardStart(state: AuthState) {
    state.isAddingCreditCard = true;
    state.isAddingCreditCardError = false;
  },
  addCreditCardFailed(state: AuthState, action: PayloadAction<String>) {
    state.isAddingCreditCard = false;
    state.isAddingCreditCardError = true;
    state.addingCreditCardError = action.payload;
  },
  addCreditCardSuccess(
    state: AuthState,
    action: PayloadAction<AppNS.CreditCard>
  ) {
    state.isAddingCreditCard = false;
    state.isAddingCreditCardError = false;
    state.addingCreditCardError = '';
    state.userCreditCards = [action.payload, ...state.userCreditCards];
  },

  removeCreditCardStart(state: AuthState) {
    state.isRemovingCreditCard = true;
    state.isRemovingCreditCardError = false;
  },
  removeCreditCardFailed(state: AuthState) {
    state.isRemovingCreditCard = false;
    state.isRemovingCreditCardError = true;
  },
  removeCreditCardSuccess(state: AuthState, action: PayloadAction<string>) {
    state.isRemovingCreditCard = false;
    state.isRemovingCreditCardError = false;
    state.userCreditCards = state.userCreditCards.filter(
      (item: AppNS.CreditCard) => item.id !== action.payload
    );
  },
};
