import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      position: 'relative',
      padding: `0 ${theme.spacing(2)}px`,
      fontSize: '1.6rem',
      '&:before': {
        position: 'absolute',
        left: 0,
        top: '50%',
        marginTop: '-9px',
        display: 'block',
        content: '""',
        height: '17px',
        width: '2px',
        background: '#e1e5e9',
      },
      '&:first-child:before': {
        display: 'none',
      },
      '& a': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '64px',
        color: theme.palette.text.primary,
        textDecoration: 'none',
        '&.active, &:hover': {
          cursor: 'pointer',
          color: theme.palette.primary.dark,
        },
        '&.active': {
          pointerEvents: 'none',
        },
        '&.active:before, &:hover:before': {
          position: 'absolute',
          left: 0,
          bottom: 0,
          display: 'block',
          content: '""',
          height: '4px',
          width: '100%',
          borderRadius: '20px',
          background: theme.palette.secondary.main,
        },
      },
    },
  },
  registerButton: {
    minWidth: 0,
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '6px'
  },
}));
