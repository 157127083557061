import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function BuyGiftCardIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9316 23.2383C16.9316 21.5814 15.4878 20.2383 13.7066 20.2383H9.67539C9.67539 18.5814 8.23151 17.2383 6.45039 17.2383H0.806641V23.2383H16.9316Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.4502 20.2383H9.67519"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0938 17.2383H22.575C23.0203 17.2383 23.3812 16.9025 23.3812 16.4883V9.73828"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.86914 9.73828V14.2383"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.25684 6.73828C7.25684 6.32407 7.59262 5.98828 8.00684 5.98828H24.2443C24.6585 5.98828 24.9943 6.32407 24.9943 6.73828V8.98828C24.9943 9.40249 24.6585 9.73828 24.2443 9.73828H8.00684C7.59262 9.73828 7.25684 9.40249 7.25684 8.98828V6.73828Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.125 17.2383V5.98828"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7105 3.86711C19.3596 4.85262 17.8085 5.57406 16.1504 5.98811C16.5955 4.44568 17.371 3.00281 18.4305 1.74611C19.8484 0.42811 20.6514 0.63011 21.2857 1.21611C21.9199 1.80211 22.1285 2.54911 20.7105 3.86711Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.539 3.86711C12.89 4.85244 14.4411 5.57385 16.0991 5.98811C15.6545 4.4455 14.8789 3.00254 13.819 1.74611C12.4022 0.42811 11.5992 0.63011 10.9649 1.21611C10.3307 1.80211 10.1221 2.54911 11.539 3.86711Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}

// <svg
//   width="26"
//   height="24"
//   viewBox="0 0 26 24"
//   fill="none"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <path
//     fill-rule="evenodd"
//     clip-rule="evenodd"
//     d="M16.9316 23.2383C16.9316 21.5814 15.4878 20.2383 13.7066 20.2383H9.67539C9.67539 18.5814 8.23151 17.2383 6.45039 17.2383H0.806641V23.2383H16.9316Z"
//     stroke="currentColor"
//     stroke-width="1.5"
//     stroke-linecap="round"
//     stroke-linejoin="round"
//   />
//   <path
//     d="M6.4502 20.2383H9.67519"
//     stroke="currentColor"
//     stroke-width="1.5"
//     stroke-linecap="round"
//     stroke-linejoin="round"
//   />
//   <path
//     d="M12.0938 17.2383H22.575C23.0203 17.2383 23.3812 16.9025 23.3812 16.4883V9.73828"
//     stroke="currentColor"
//     stroke-width="1.5"
//     stroke-linecap="round"
//     stroke-linejoin="round"
//   />
//   <path
//     d="M8.86914 9.73828V14.2383"
//     stroke="currentColor"
//     stroke-width="1.5"
//     stroke-linecap="round"
//     stroke-linejoin="round"
//   />
//   <path
//     fill-rule="evenodd"
//     clip-rule="evenodd"
//     d="M7.25684 6.73828C7.25684 6.32407 7.59262 5.98828 8.00684 5.98828H24.2443C24.6585 5.98828 24.9943 6.32407 24.9943 6.73828V8.98828C24.9943 9.40249 24.6585 9.73828 24.2443 9.73828H8.00684C7.59262 9.73828 7.25684 9.40249 7.25684 8.98828V6.73828Z"
//     stroke="currentColor"
//     stroke-width="1.5"
//     stroke-linecap="round"
//     stroke-linejoin="round"
//   />
//   <path
//     d="M16.125 17.2383V5.98828"
//     stroke="currentColor"
//     stroke-width="1.5"
//     stroke-linecap="round"
//     stroke-linejoin="round"
//   />
//   <path
//     fill-rule="evenodd"
//     clip-rule="evenodd"
//     d="M20.7105 3.86711C19.3596 4.85262 17.8085 5.57406 16.1504 5.98811C16.5955 4.44568 17.371 3.00281 18.4305 1.74611C19.8484 0.42811 20.6514 0.63011 21.2857 1.21611C21.9199 1.80211 22.1285 2.54911 20.7105 3.86711Z"
//     stroke="currentColor"
//     stroke-width="1.5"
//     stroke-linecap="round"
//     stroke-linejoin="round"
//   />
//   <path
//     fill-rule="evenodd"
//     clip-rule="evenodd"
//     d="M11.539 3.86711C12.89 4.85244 14.4411 5.57385 16.0991 5.98811C15.6545 4.4455 14.8789 3.00254 13.819 1.74611C12.4022 0.42811 11.5992 0.63011 10.9649 1.21611C10.3307 1.80211 10.1221 2.54911 11.539 3.86711Z"
//     stroke="currentColor"
//     stroke-width="1.5"
//     stroke-linecap="round"
//     stroke-linejoin="round"
//   />
// </svg>;
