import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';

import auth from './auth';
import clientBookings from './client-bookings';
import clientDependants from './client-dependants';
import clientJobs from './client-jobs';
import clientOnboarding from './client-onboarding';
import clientPaymentsList from './client-payments-list';
import scheduleList from './schedules';
import clientReports from './client-reports';
import clientFavorites from './client-favorites';
import clientCorporate from './client-corporate';
import constants from './constants';
import emailVerification from './email-verification';
import heroCareTypes from './hero-care-types';
import heroLeads from './hero-leads';
import heroOnboarding from './hero-onboarding';
import heroPaymentsList from './hero-payments-list';
import heroQualifications from './hero-qualifications';
import heroReferences from './hero-references';
import heroReports from './hero-reports';
import heroReviews from './hero-reviews';
import visitor from './visitor';
import merchantAccounts from './merchant-accounts';
import messaging from './messaging';
import tours from './tours';
import ui from './ui';

const combinedReducer = combineReducers({
  auth,
  clientBookings,
  clientDependants,
  clientFavorites,
  clientJobs,
  clientOnboarding,
  clientPaymentsList,
  scheduleList,
  clientReports,
  clientCorporate,
  constants,
  emailVerification,
  heroCareTypes,
  heroLeads,
  heroOnboarding,
  heroPaymentsList,
  heroReviews,
  visitor,
  heroQualifications,
  heroReferences,
  heroReports,
  merchantAccounts,
  messaging,
  tours,
  ui,
});

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
