import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UIState {
  mobileNavOpen: boolean;
  isWithoutSidebar: boolean;
  isGalleryOpened: boolean;
}

const initialState: UIState = {
  mobileNavOpen: false,
  isWithoutSidebar: true,
  isGalleryOpened: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    closeMobileNav(state: UIState) {
      state.mobileNavOpen = false;
    },
    openMobileNav(state: UIState) {
      state.mobileNavOpen = true;
    },
    toggleMobileNav(state: UIState) {
      state.mobileNavOpen = !state.mobileNavOpen;
    },
    setNoSidebarLayoutStyle(state: UIState) {
      state.isWithoutSidebar = true;
    },
    setSidebarLayoutStyle(state: UIState) {
      state.isWithoutSidebar = false;
    },
    setGalleryState(state: UIState, action: PayloadAction<boolean>) {
      state.isGalleryOpened = action.payload;
    },
  },
});

export const {
  closeMobileNav,
  openMobileNav,
  toggleMobileNav,
  setNoSidebarLayoutStyle,
  setSidebarLayoutStyle,
  setGalleryState,
} = uiSlice.actions;

export default uiSlice.reducer;
