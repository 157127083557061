import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from '../core/config';
import { omit } from 'lodash-es';
import { parseAvatar } from './common-parsers/common-parsers';

const URL = 'jobs';
const MATCHED = 'jobs/matched-heroes';
const BY_DEPENDANTS = 'jobs/by-dependants';
const PREVIOUS_JOB_ADDRESSES = 'jobs/previous-job-addresses'
const LEADS_URL = 'job_leads/booking';
const EVENTS_URL = 'job_events.json';
const BOOKINGS_URL = 'bookings.json';

const CancelToken = axios.CancelToken;
let cancel: Canceler;
let cancel1: Canceler;
let cancel2: Canceler;

export function apiGetJobById(jobId: string): Promise<AppNS.ClientJob> {
  if (cancel1 !== undefined) {
    cancel1();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${URL}/${jobId}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel1 = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(parseJob(data.model));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetLastJobByDependants(ids: Array<string>): Promise<AppNS.ClientJob> {
  if (cancel1 !== undefined) {
    cancel1();
  }
  return new Promise((resolve, reject) => {
    const params = {ids: ids.join()};
    axios
      .get(`${API_URL}/${BY_DEPENDANTS}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel1 = c;
        }),
        params
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetPreviousJobAddresses(): Promise<Array<AppNS.Address>> {
  if (cancel2 !== undefined) {
    cancel2();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${PREVIOUS_JOB_ADDRESSES}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel2 = c;
        }),
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetJobLeadById(leadId: string): Promise<AppNS.JobLead> {
  if (cancel1 !== undefined) {
    cancel1();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${LEADS_URL}/${leadId}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel1 = c;
        }),
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(parseJobLead(data.model));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetNewJobResults(
  jobId: string
): Promise<AppNS.JobMatchingResults> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${MATCHED}/${jobId}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data) {
          resolve({
            ...data,
            results: parseResults(
              data.results,
              data.job.jobRateModifierPercent,
              data.job.mpoFeePercent
            ),
          });
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiDeleteJobEvent(reqData: AppNS.Params): Promise<string> {
  const req = {
    id: reqData.id,
    properties: {
      state: 'deleted_by_client',
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${EVENTS_URL}`, req)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSendNewJob(reqData: AppNS.Job): Promise<AppNS.Job> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${URL}.json`, prepareJobData(reqData))
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiUpdateNewJobDependants(
  id: string,
  reqData: Array<string>
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${URL}/${id}.json`, {
        id,
        properties: {
          dependant_ids: reqData,
        },
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiRepostJob(
  id: string,
  favoriteIds: Array<string>,
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${URL}/${id}.json`, {
        id,
        properties: { favoriteIds },
        repost: true
      })
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetCareEvents(
  jobId: string
): Promise<Array<AppNS.JobEvent>> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/job_events/${jobId}.json`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.results) {
          resolve(data.results);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiBookLead(reqData: AppNS.Params): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${BOOKINGS_URL}`, reqData)
      .then(({ data }) => {
        if (data.model) {
          resolve(data.model.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

const prepareJobData = (data: AppNS.Job): AppNS.Params => {
  const addressProperties = data.address
    ? omit(data.address, ['id'])
    : {};
  return {
    properties:
      data.scheduleType === 'ad_hoc'
        ? getCommonProperties(data)
        : getRecurringProperties(data),
    models: {
      address: [{ properties: addressProperties }],
      jobSchedule: data.jobSchedule,
    },
  };
};

const getRecurringProperties = (data: AppNS.Job): AppNS.Params => {
  return {
    ...getCommonProperties(data),
    startDate: data.startDate,
    endDate: data.endDate,
    excludeDatesForRecurring: data.excludeDatesForRecurring,
  };
};

const getCommonProperties = (data: AppNS.Job): AppNS.Params => {
  return {
    dependantIds: data.dependantIds,
    favoriteIds: data.favoriteIds,
    nonSmoker: data.nonSmoker,
    petFriendly: data.petFriendly,
    covidVaccinated: data.covidVaccinated,
    dependantsCount: data.dependantsCount,
    careType: data.careType,
    scheduleType: data.scheduleType,
    services: data.services,
    experience: data.experience,
    credentials: data.credentials,
    yearsOfExperience: data.yearsOfExperience,
    languages: data.languages,
    interests: data.interests,
    preferredHourlyRate: data.preferredHourlyRate,
    infoForHero: data.infoForHero,
    state: data.state,
  };
};

const parseResults = (
  data: Array<AppNS.MatchingResult>,
  jobRateModifierPercent: number,
  mpoFeePercent: number
): Array<AppNS.MatchingResult> => {
  return data.map((item: AppNS.MatchingResult) => {
    const newItem = { ...item };
    const rankingScore = Math.round((item.rankingScore / 1000) * 100);
    const hourlyRate = item.fixedHourlyRate
      ? Math.round(item.fixedHourlyRate * mpoFeePercent * 100) / 100
      : (Math.round(
          item.hero.careType.hourlyRate * jobRateModifierPercent * 100
        ) *
          mpoFeePercent) /
        100;
    const careTypeRate = (Math.round(
          item.hero.careType.careTypeRate * jobRateModifierPercent * 100
        ) *
          mpoFeePercent) /
        100;
    newItem.rankingScore = rankingScore;
    item.hero.careType.hourlyRate = hourlyRate;
    item.hero.careType.careTypeRate = careTypeRate;
    item.hero.avatar = parseAvatar(
      (item.hero.avatar as unknown) as AppNS.Params
    );
    return newItem;
  });
};

const parseJob = (data: AppNS.Params): AppNS.ClientJob => {
  data.pickedHeroId = data.userId;
  data.jobSchedule = data.jobSchedules.map((item: AppNS.Params) => {
    if (!item.date) {
      return {
        properties: {
          dayOfWeek: item.dayOfWeek,
          timesForTheDay: item.timesForTheDay,
        },
      };
    } else {
      return {
        properties: {
          date: item.date,
          timesForTheDay: item.timesForTheDay,
        },
      };
    }
  });
  return data as AppNS.ClientJob;
};

const parseJobLead = (data: AppNS.Params): AppNS.JobLead => {
  data.job = parseJob(data.job);
  data.hero.avatar = parseAvatar(data.hero.avatar);

  return data as AppNS.JobLead;
};
