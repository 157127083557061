import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const VIDEO_REDUCERS = {
  setVideoStart(state: AuthState) {
    state.isChangingVideo = true;
    state.isChangingVideoError = false;
  },
  setVideoFailed(state: AuthState) {
    state.isChangingVideoError = true;
  },
  setVideoSuccess(state: AuthState, action: PayloadAction<string>) {
    state.isChangingVideo = false;
    state.isChangingVideoError = false;
    if (state.user) {
      state.user.vimeoIntroVideoId = action.payload;
    }
  },
};
