import React, { useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { Theme, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import routes from '../config/routes';
import NavbarWrapperLayout from './components/NavbarWrapperLayout';

import AppSuspense from '../../ui/components/AppSuspense';
import ToolbarLayout from './components/ToolbarLayout';
import { setSidebarLayoutStyle } from 'app/store/ui';
import { RootState } from 'app/store/rootReducer';
import FetchThreads from 'app/ui/modules/messaging/components/fetch-threads/FetchThreads';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  content: {
    position: 'relative',
    display: 'flex',
    overflow: 'auto',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    '-webkit-overflow-scrolling': 'touch',
    zIndex: 2,
    paddingTop: 64,
    '& > div': {
      maxWidth: theme.breakpoints.width('lg'),
      flexGrow: 1,
    },
    '&.forced-no-sidebar > div': { margin: '0 auto', width: '100%' },
    '&.messaging-opened > div': { height: '100%' },
    '&.gallery-opened ': { zIndex: 10000 },
  },
  layoutWrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
}));

interface DefaultLayoutProps {}

export default function DefaultLayout(props: DefaultLayoutProps) {
  const { user } = useSelector((state: RootState) => state.auth);
  const { isWithoutSidebar, isGalleryOpened } = useSelector(
    (state: RootState) => state.ui
  );

  const { isMessagingPageOn } = useSelector(
    (state: RootState) => state.messaging
  );
  const classes = useStyles(props);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebarLayoutStyle());
  }, []);

  return (
    <div className={classes.root}>
      {!isWithoutSidebar && user && <NavbarWrapperLayout />}
      <div className={classes.layoutWrapper}>
        <ToolbarLayout
          isUserLogged={user ? true : false}
          isWithoutSidebar={isWithoutSidebar || !user}
        />
        <main
          className={clsx(
            classes.content,
            (isWithoutSidebar || !user) && 'forced-no-sidebar',
            isMessagingPageOn && 'messaging-opened',
            isGalleryOpened && 'gallery-opened'
          )}
        >
          <AppSuspense>
            <div>{renderRoutes(routes)}</div>
          </AppSuspense>
        </main>
        <FetchThreads />
      </div>
    </div>
  );
}
