import { AppThunk } from '../../index';
import { apiLogout } from 'app/api/users/auth';
import { logout, logoutStart } from '../../auth';

export const logoutUser = (forceNoRedirect?: boolean): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(logoutStart());
    await apiLogout();
    dispatch(logout(forceNoRedirect));
  } catch (err) {
    console.error(err.toString());
  }
};
