import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './../../auth';

export const SETTINGS_REDUCERS = {
  changeEmailStart(state: AuthState) {
    state.isChangingEmail = true;
    state.isChangingEmailError = false;
    state.isChangingEmailSuccess = false;
  },
  changeEmailFailed(state: AuthState) {
    state.isChangingEmailError = true;
    state.isChangingEmailSuccess = false;
  },
  changeEmailSuccess(state: AuthState, action: PayloadAction<string>) {
    state.isChangingEmail = false;
    state.isChangingEmailError = false;
    state.isChangingEmailSuccess = true;
    if (state.user) {
      state.user.email = action.payload;
    }
  },
  changeMobileStart(state: AuthState) {
    state.isChangingMobile = true;
    state.isChangingMobileError = false;
    state.isChangingMobileSuccess = false;
  },
  changeMobileFailed(state: AuthState) {
    state.isChangingMobileError = true;
    state.isChangingMobileSuccess = false;
  },
  changeMobileSuccess(state: AuthState, action: PayloadAction<string>) {
    state.isChangingMobile = false;
    state.isChangingMobileError = false;
    state.isChangingMobileSuccess = true;
    if (state.user) {
      state.user.mobileNumber = action.payload;
    }
  },
};
