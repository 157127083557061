import axios, { Canceler } from 'axios';
import { AppNS } from 'app';
import { API_URL } from 'app/core/config';
import { parseAvailabilityResponseData } from 'app/ui/components/availability-picker/helpers/parseData';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

const URL = 'users.json';
const ONBOARDING_STEP_URL = 'users/onboarding_step.json';
const CLIENT_ONBOARDING_STEP_URL = 'users/client_onboarding_step.json';
const HERO_ONBOARDING_INFO_URL = 'users/hero_onboarding_info.json';
const HERO_REAPPROVAL_INFO_URL = 'users/hero_reapproval_info.json';
const HERO_MATCHED_JOBS_URL = 'users/hero_matched_jobs.json';
const AVATAR_URL = 'users/avatar.json';
const INTERESTS_URL = 'users/interests.json';
const OTHER_NEEDS_URL = 'users/other_needs.json';
const LANGUAGES_URL = 'users/languages.json';
const CLIENT_DETAILS_URL = 'users/details.json';
const AVAILABILITY_URL = 'users/availability.json';
const UNAVAILABLE_PERIODS_URL = 'users/unavailable_periods.json';
const SERVICE_ADDRESS_URL = 'users/service_address.json';
const DISTANCE_URL = 'users/service_distance.json';
const CLAIM_ACCOUNT_DETAILS_URL = 'users/claim_account_details.json';
const LAST_SEARCH = 'users/last_search.json';
const SAVINGS = 'users/savings.json';

export function registerUser(reqData: AppNS.Params): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/${URL}`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.id);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiChangeOnboardingStep(
  reqData: AppNS.Params
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${ONBOARDING_STEP_URL}`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.properties.onboardingStep);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiChangeClientOnboardingStep(
  reqData: AppNS.Params
): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${CLIENT_ONBOARDING_STEP_URL}`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.properties.onboardingStep);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetHeroOnboardingInfo(
  state?: 'active'
): Promise<AppNS.Params> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${HERO_ONBOARDING_INFO_URL}?state=${state}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetHeroReapprovalInfo(
  state?: 'active'
): Promise<AppNS.Params> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${HERO_REAPPROVAL_INFO_URL}?state=${state}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetHeroMatchedJobs(
  searchQuery: string,
  page: number
): Promise<Array<AppNS.Params>> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${HERO_MATCHED_JOBS_URL}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        params: {
          query: searchQuery,
          page,
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetAvatar(reqData: AppNS.Params): Promise<AppNS.Avatar> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${AVATAR_URL}`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.avatar.versions);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetClaimAccountDetails(
  reqData: AppNS.Params
): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${CLAIM_ACCOUNT_DETAILS_URL}`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetInterests(
  reqData: AppNS.Params
): Promise<{ interests: Array<string>; extraInterests: string }> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${INTERESTS_URL}`, reqData, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.user) {
          resolve({
            interests: data.user.properties.interests,
            extraInterests: data.user.properties.extraInterests,
          });
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetOtherNeeds(
  reqData: AppNS.Params
): Promise<Array<string>> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${OTHER_NEEDS_URL}`, reqData, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.properties.otherNeeds);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetLanguages(reqData: AppNS.Params): Promise<AppNS.Params> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${LANGUAGES_URL}`, reqData, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.properties.languages);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetClientDetails(
  reqData: AppNS.Params
): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${CLIENT_DETAILS_URL}`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetAvailability(
  reqData: AppNS.Params
): Promise<AppNS.Params> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${AVAILABILITY_URL}`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(parseAvailabilityResponseData(data.user.properties));
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetUnavailablePeriods(
  reqData: AppNS.Params
): Promise<Array<string>> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${UNAVAILABLE_PERIODS_URL}`, reqData)
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.properties.unavailablePeriods);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetServiceAddressId(reqData: AppNS.Params): Promise<string> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${SERVICE_ADDRESS_URL}`, reqData, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.properties.serviceAddressId);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiSetServiceDistance(reqData: AppNS.Params): Promise<string> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/${DISTANCE_URL}`, reqData, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user.properties.serviceDistanceMi);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetLastSearch(state?: 'active'): Promise<AppNS.Params> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${LAST_SEARCH}?state=${state}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}

export function apiGetSavings(state?: 'active'): Promise<AppNS.Params> {
  if (cancel !== undefined) {
    cancel();
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${SAVINGS}?state=${state}`, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(({ data }) => {
        if (data.user) {
          resolve(data.user);
        } else {
          reject('Invalid response data');
        }
      })
      .catch(reject);
  });
}
