import React from 'react';
import NoSidebar from '../../layout/NoSidebar';
import { HasToBeLoggedInGuard } from '../../guards/HasToBeLoggedInGuard';
import { CannotBeLoggedInGuard } from '../../guards/CannotBeLoggedInGuard';
import { ClientEnrollStep2Guard } from '../../guards/ClientEnrollStep2Guard';
import { ClientEnrollStep3Guard } from '../../guards/ClientEnrollStep3Guard';
import { ClientEnrollStep4Guard } from '../../guards/ClientEnrollStep4Guard';
import { ClientRegisterGuard } from '../../guards/ClientRegisterGuard';
import { HasToBeClientGuard } from '../../guards/HasToBeClientGuard';
import { HasToBeOnboardedClientGuard } from '../../guards/HasToBeOnboardedClientGuard';
import { HasToBeClientOnboardingStep1Guard } from '../../guards/HasToBeClientOnboardingStep1Guard';
import { Redirect } from 'react-router-dom';
import CheckIsLoggedInGuard from 'app/core/guards/CheckIsLoggedInGuard';
import HasToBeApprovedClientGuard from 'app/core/guards/HasToBeApprovedClientGuard';

const clientRoutes = [
  // NOT-LOGGED-IN SECTION
  {
    path: '/visitor/client-enroll',
    exact: true,
    component: React.lazy(
      () => import('../../../main/visitor/ClientEnroll')
    ),
    canActivate: [CannotBeLoggedInGuard],
  },
  {
    path: '/visitor/client-enroll/care-type',
    exact: true,
    component: React.lazy(
      () => import('../../../main/visitor/client-enroll/ClientEnrollStep1')
    ),
    canActivate: [CannotBeLoggedInGuard],
  },
  {
    path: '/visitor/client-enroll/location',
    exact: true,
    component: React.lazy(
      () => import('../../../main/visitor/client-enroll/ClientEnrollStep2')
    ),
    canActivate: [CannotBeLoggedInGuard, ClientEnrollStep2Guard],
  },
  {
    path: '/visitor/client-enroll/timing',
    exact: true,
    component: React.lazy(
      () => import('../../../main/visitor/client-enroll/ClientEnrollStep3')
    ),
    canActivate: [CannotBeLoggedInGuard, ClientEnrollStep3Guard],
  },
  {
    path: '/visitor/client-enroll/rate',
    exact: true,
    component: React.lazy(
      () => import('../../../main/visitor/client-enroll/ClientEnrollStep4')
    ),
    canActivate: [CannotBeLoggedInGuard, ClientEnrollStep4Guard],
  },
  {
    path: '/visitor/client-enroll/preview-caregivers',
    exact: true,
    component: React.lazy(
      () => import('../../../main/visitor/client-enroll/PreviewHeroes')
    ),
    canActivate: [CannotBeLoggedInGuard, ClientRegisterGuard],
  },

  // NOT-LOGGED-IN POST A JOB
  {
    path: '/job-create-not-logged',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/job-create/JobCreateNotLogged')
    ),
    canActivate: [CannotBeLoggedInGuard],
  },
  {
    path: '/job-create-not-logged/:type',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/job-create/JobCreateNotLogged')
    ),
    canActivate: [CannotBeLoggedInGuard],
  },

  // REGISTER
  {
    path: '/client/register',
    component: React.lazy(
      () => import('../../../main/client/client-register/ClientRegister')
    ),
    canActivate: [CannotBeLoggedInGuard, ClientRegisterGuard],
    layout: NoSidebar,
  },

  // ONBOARDING
  {
    path: '/client/onboarding/step1',
    component: React.lazy(
      () =>
        import('../../../main/client/onboarding/step1/ClientOnboardingStep1')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeClientOnboardingStep1Guard,
    ],
    layout: NoSidebar,
  },

  // JOB CREATE
  {
    path: '/job-create',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/job-create/JobCreatePage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },
  {
    path: '/job-create/:type',
    component: React.lazy(
      () => import('../../../main/client/job-create/JobCreatePage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },
  {
    path: '/find-a-hero/:id',
    component: React.lazy(
      () => import('../../../main/client/find-a-hero/FindAHeroPage')
    ),
    canActivate: [      
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },
  {
    path: '/find-a-hero',
    component: () => <Redirect to={'/job-create'} />,
    canActivate: [CheckIsLoggedInGuard],
  },

  // BROWSING 
  // {
  //   path: '/client/browse',
  //   component: React.lazy(
  //     () => import('../../../main/client/browse/Browse')
  //   ),
  //   canActivate: [
  //     HasToBeLoggedInGuard,
  //     HasToBeClientGuard,
  //   ],
  // },

  // DASHBOARD
  {
    path: '/client/dashboard',
    component: React.lazy(
      () => import('../../../main/client/dashboard/ClientDashboard')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },
  {
    path: '/client/schedule',
    exact: true,
    component: () => <Redirect to={'/client/schedule/week'} />,
  },
  {
    path: '/client/schedule/:id',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/schedule/ClientSchedulePage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },

  // BOOKINGS SECTION
  {
    path: '/client/bookings',
    exact: true,
    component: () => <Redirect to={'/client/bookings/confirmed-bookings'} />,
  },
  {
    path: '/client/bookings/book-a-hero/:leadId',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/bookings/hero-booking/HeroBookingPage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },
  {
    path: '/client/bookings/:sectionId/details/:leadId',
    component: React.lazy(
      () =>
        import('../../../main/client/bookings/details/ClientBookingDetailsPage')
    ),
    exact: true,
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },
  {
    path: '/client/bookings/:sectionId/proposal/:leadId',
    component: React.lazy(
      () => import('../../../main/client/bookings/proposal/ClientProposalPage')
    ),
    exact: true,
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },
  {
    path: '/client/bookings/:sectionId/report/:reportId',
    component: React.lazy(
      () =>
        import(
          '../../../main/client/bookings/report-details/ClientReportDetailsPage'
        )
    ),
    exact: true,
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },
  {
    path: '/client/bookings/:sectionId',
    component: React.lazy(
      () => import('../../../main/client/bookings/ClientBookingsPage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },

  // MESSAGES SECTION
  {
    path: '/cmi',
    exact: true,
    component: () => <Redirect to={'/client/messaging/inbox'} />,
  },
  {
    path: '/cmi/:id',
    exact: true,
    component: (props: any) => (
      <Redirect to={`/client/messaging/inbox/${props.match.params.id}`} />
    ),
  },
  {
    path: '/client/messaging',
    exact: true,
    component: () => <Redirect to={'/client/messaging/inbox'} />,
  },
  {
    path: [
      '/client/messaging/create-thread/:recipientId',
      '/client/messaging/create-thread/:recipientId/:jobId',
    ],
    component: React.lazy(
      () => import('../../../main/client/messaging/ClientCreateThreadPage')
    ),
    exact: true,
    canActivate: [HasToBeLoggedInGuard, HasToBeClientGuard],
  },
  {
    path: [
      '/client/messaging/:sectionId',
      '/client/messaging/:sectionId/:threadId',
    ],
    component: React.lazy(
      () => import('../../../main/client/messaging/ClientMessagingPage')
    ),
    exact: true,
    canActivate: [HasToBeLoggedInGuard, HasToBeClientGuard],
  },
  {
    path: '/client/notifications/:messageId',
    component: React.lazy(
      () =>
        import('../../../main/client/messaging/ClientNotificationLandingPage')
    ),
    exact: true,
    canActivate: [HasToBeLoggedInGuard, HasToBeClientGuard],
  },

  // SETTINGS SECTION
  {
    path: '/client/settings',
    exact: true,
    component: () => <Redirect to={'/client/settings/general'} />,
  },
  {
    path: '/client/settings/:sectionId',
    component: React.lazy(
      () => import('../../../main/client/settings/ClientSettingsPage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeApprovedClientGuard,
    ],
  },

  // PAYMENTS SECTION
  {
    path: '/client/payments',
    exact: true,
    component: () => <Redirect to={'/client/payments/payments-list'} />,
  },
  {
    path: '/client/payments/:id',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/payments/ClientPaymentsPage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },

  // CONTACT US SECTION
  {
    path: '/client/contact-us',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/contact-us/ContactUs')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
    ],
  },

  // PROFILE SECTION
  {
    path: '/client/profile',
    exact: true,
    component: () => <Redirect to={'/client/profile/main'} />,
  },
  {
    path: '/client/profile/:id',
    component: React.lazy(
      () => import('../../../main/client/profile/ClientProfilePage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },
  
  // FAVORITES SECTION
  {
    path: '/client/favorites',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/favorites/ClientFavoritesPage')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
      HasToBeOnboardedClientGuard,
    ],
  },

  // RESOURCES SECTION
  {
    path: '/resources-for-families',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/resources/ResourcesForFamilies')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard],
  },

  // WELCOME SECTION
  {
    path: '/client/welcome',
    exact: true,
    component: React.lazy(
      () => import('../../../main/client/welcome/Welcome')
    ),
    canActivate: [
      HasToBeLoggedInGuard,
      HasToBeClientGuard,
    ],
  },
];

export default clientRoutes;
