import { AppNS } from '..';
import { AppThunk } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  apiDeleteMerchantAccount,
  apiGetMerchantAccount,
} from '../api/merchant-accounts';

export interface MerchantAccountState {
  errorMessage: string;
  isObjectLoading: boolean;
  isObjectLoaded: boolean;
  isObjectDeleted: boolean;
  merchantAccount: AppNS.MerchantAccount | null;
}

const initialState: MerchantAccountState = {
  errorMessage: '',
  isObjectLoading: false,
  isObjectLoaded: false,
  isObjectDeleted: false,
  merchantAccount: null,
};

const merchantAccount = createSlice({
  name: 'merchantAccount',
  initialState,
  reducers: {
    deleteActionStart(state: MerchantAccountState) {
      state.isObjectLoading = true;
      state.isObjectDeleted = false;
    },
    deleteActionSuccess(state: MerchantAccountState) {
      state.isObjectLoading = false;
      state.isObjectDeleted = true;
      state.errorMessage = '';
      state.merchantAccount = null;
    },
    deleteActionFailed(
      state: MerchantAccountState,
      action: PayloadAction<string>
    ) {
      state.isObjectLoading = true;
      state.isObjectDeleted = false;
      state.errorMessage = action.payload;
    },
    getActionStart(state: MerchantAccountState) {
      state.isObjectLoading = true;
      state.isObjectLoaded = false;
    },
    getActionSuccess(
      state: MerchantAccountState,
      action: PayloadAction<AppNS.MerchantAccount>
    ) {
      state.isObjectLoading = false;
      state.isObjectLoaded = true;
      state.errorMessage = '';
      state.merchantAccount = action.payload;
    },
    getActionFailed(
      state: MerchantAccountState,
      action: PayloadAction<string>
    ) {
      state.isObjectLoading = true;
      state.isObjectLoaded = false;
      state.errorMessage = action.payload;
    },
  },
});

export const {
  getActionStart,
  getActionSuccess,
  getActionFailed,
  deleteActionStart,
  deleteActionSuccess,
  deleteActionFailed,
} = merchantAccount.actions;

export default merchantAccount.reducer;

export const getMerchantAccount = (isSilent: boolean): AppThunk => async (
  dispatch
) => {
  try {
    if (!isSilent) {
      dispatch(getActionStart());
    }
    const response = await apiGetMerchantAccount();
    dispatch(getActionSuccess(response));
  } catch (err) {
    dispatch(getActionFailed(err.toString()));
  }
};

export const deleteMerchantAccount = (params: AppNS.Params): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(deleteActionStart());
    await apiDeleteMerchantAccount(params);
    dispatch(deleteActionSuccess());
  } catch (err) {
    dispatch(deleteActionFailed(err.toString()));
  }
};
