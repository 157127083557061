import { PayloadAction } from '@reduxjs/toolkit';
import { AppNS } from 'app';
import { AuthState } from './../../auth';

export const LANGUAGES_REDUCERS = {
  setLanguagesStart(state: AuthState) {
    state.isChangingLanguages = true;
    state.isChangingLanguagesError = false;
  },
  setLanguagesFailed(state: AuthState) {
    state.isChangingLanguagesError = true;
  },
  setLanguagesSuccess(state: AuthState, action: PayloadAction<AppNS.Params>) {
    state.isChangingLanguages = false;
    state.isChangingLanguagesError = false;

    if (state.user) {
      state.user.languages = action.payload || {};
    }
  },
};
