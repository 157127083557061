import React from 'react';
import { AppNS } from 'app';
import { makeStyles } from '@material-ui/styles';
import NavigationElement from './NavigationElement';
import { Hidden, Box, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { toggleMobileNav } from 'app/store/ui';
import { useDispatch } from 'react-redux';
import PhoneIcon from 'app/ui/icons/PhoneIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(5),
    '& .mainPanel': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    '& .settingsPanel': {
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        content: '""',
        display: 'block',
        width: `calc(100% - ${theme.spacing(3)}px)`,
        height: 1,
        background: theme.palette.divider,
      },
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      fontSize: '16px',
    },
  },
  telIcon: {
    color: theme.palette.info.light,
  },
}));

interface NavigationProps {
  navigation: Array<AppNS.NavigationElement>;
}

const Navigation: React.FunctionComponent<NavigationProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { navigation } = props;

  const settings = navigation.filter((item: AppNS.NavigationElement) => {
    return item.id === 'settings';
  })[0];

  const nav = navigation.filter((item: AppNS.NavigationElement) => {
    return item.show;
  });

  return (
    <div
      className={classes.root}
      onClick={() => matches && dispatch(toggleMobileNav())}
    >
      <div className="mainPanel">
        {nav.map((item: AppNS.NavigationElement) => (
          <NavigationElement element={item} key={item.id} />
        ))}
        <Hidden mdUp>
          <Box display="flex" alignItems="center" mt={1.5} mb={4} ml={2.5}>
            <PhoneIcon className={classes.telIcon} />
            <a href="tel:2345678911">+1 (234) 567-8911</a>
          </Box>
        </Hidden>
      </div>
      <div className="settingsPanel">
        <NavigationElement element={settings} />
      </div>
    </div>
  );
};

export default Navigation;
